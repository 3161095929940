import template from './prepack.html';

class PrePackViewModel
{
	constructor (page)
	{
		this.page = page;
		this.stock_items = ko.observableArray([]);
		this.selected_item = ko.observable();
		this.prepack = ko.observable('');
		this.items = ko.observableArray([]);
		this.prepack_id = '';
	}
}

class PrepackPage
{
	constructor(bindings)
	{
		this.viewModel = new PrePackViewModel(this);
		this.bindings = bindings;
		this.bindings = {prepack_data: bindings}
		this.onClose = (response) => {
			return response;
		};

		if (bindings.prepack_id)
		{
			let temp = [];
			this.viewModel.prepack(bindings.prepack_name);
			this.viewModel.prepack_id = bindings.prepack_id;
			for (let i = 0; i < bindings.stock_items.length; i++) 
			{
				let obj = {};
				obj.stock_item = bindings.stock_items[i].toString();
				obj.qty = bindings.stock_items_qty[i].toString();
				obj.stock_item_id = bindings.stock_items_id[i];
				temp.push(obj);
			}
			this.viewModel.items(temp);
		}

		this.setup();
	}

	async setup ()
	{
		let res = await Grape.fetches.getJSON('/api/record', { schema: 'stock', table: 'v_stock_item_with_prepack', sortfield: 'description', sortorder: 'ASC' });
		this.viewModel.stock_items(res.records);
		$("#stock_items_select").select2();
	}

	addProduct ()
	{
		let item = null;
		if (this.viewModel.selected_item() == undefined)
		{
			item = this.viewModel.stock_items()[0];
		}
		else
		{
			item = $.grep(this.viewModel.stock_items(), (v,i) => {
				return v.description == this.viewModel.selected_item();
			})[0];
		}

		this.viewModel.items.unshift({stock_item: item.description, stock_item_id: item.stock_item_id, qty: 0});
	}

	removeProduct (data, event)
	{
		this.viewModel.items.remove(data);
	}

	async save ()
	{
		let stock_items = ko.toJS(this.viewModel.items);
		if (stock_items.length == 0)
		{
			Grape.alerts.alert({message: 'Need to add items to the prepack', title: 'Warning', type: 'warning'});
		}
		else 
		{
			let prepack_id = ko.toJS(this.viewModel.prepack_id);

			stock_items.forEach((item) => {
				if (typeof item.qty === 'string')
					item.qty = item.qty.replace(/ /g,''); 
			});
			
			let data = {
				prepack_id: ko.toJS(this.viewModel.prepack_id),
				description: ko.toJS(this.viewModel.prepack),
				items: stock_items
			};

			let url = '/prepack/' + ko.toJS(this.viewModel.prepack_id) + '/update';
			
			if (prepack_id == "")
				url = '/prepack/create';

			//SERVER
			let res = await Grape.fetches.postJSON(url, data);
			if (res.status!="ERROR")
			{
				Grape.alerts.alert({
					message: 'PrePack created: ' + res.prepack_id,
					title: 'Success',
					type: 'success',
					onClose: (result) => {
						this.close();
					}
				});
			}
			else
			{
				Grape.alert_api_error(res);
			}
		}
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

ko.bindingHandlers.select2 = {
	init: function(element, valueAccessor) {
		let options = ko.toJS(valueAccessor()) || {};
		setTimeout(() => { 
			$(element).select2(options);
		}, 0);
	},
};

export default {
	name: 'Prepack',
	dialog_class: PrepackPage,
	template: template
};
