import template from './suppliers_list.html';

class SuppliersViewModel
{
	constructor (page)
	{
		document.title = 'Suppliers';
		this.page = page;

		// pagination 
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(50);
		this.page_count = ko.observable(1);
		
		//search 
		this.search_string = ko.observable('');

		//sort 
		this.sort_field = ko.observable('name');
		this.sort_order = ko.observable('ASC');

		//filtering
		this.available_tags = ko.observableArray([]);
		this.selected_tags = ko.observableArray([]);

		this.selected_tags.subscribe(() => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.locations = ko.observableArray([]);
		this.locations_formatted = ko.observableArray([]);
		this.display_inactive = ko.observable(false);

		this.display_inactive.subscribe((value) => {
			this.page.updateData();
		});

		this.current_page_size.subscribe((value) => {
			this.page.onload = true;
		});
	}

	view_location_click (data)
	{
		Grape.navigate(`/admin/location/view/${data.location_id}`);
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	search_click ()
	{
		this.page.updateData();
	}

	row_sort_click (field)
	{
		this.sort_field(field);

		if (this.sort_order() == 'ASC' || this.sort_order() == 'asc')
			this.sort_order('DESC');
		else
			this.sort_order('ASC');

		this.page.updateData();
	}

	btn_create_order_click (supplier) 
	{
		Grape.navigate('/stock/order/create/', {type: 'create', order_type: 'Purchase Order', supplier_name: supplier.name});
	}
}

class SuppliersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SuppliersViewModel(this);
		this.onload = true;

		const searchInput = document.getElementById("searchInput");
		searchInput.addEventListener("keyup", (event) => {
			if (event.key === "Enter")
				this.updateData();
		});
	}

	async updateData ()
	{
		let options = {
			table: 'v_location_orders',
			schema: 'stock',
			offset: 0,
			limit: this.viewModel.current_page_size(),
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field(),
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		options.filter.push({
			field: 'location_type',
			operand: '=',
			value: 'Supplier'
		});

		if (this.viewModel.selected_tags().length > 0)
		{
			options.filter.push({
				field: 'tags',
				operand: '@>',
				value: this.viewModel.selected_tags()
			});
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter.push({ 
				field: 'name', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.search_string()}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}
 
		try
		{
			if (!this.viewModel.display_inactive())
			{
				options.filter.push({
					field: 'in_use', 
					operand: '=', 
					value: 'true'
				});
			}

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				this.viewModel.locations(result.records);
				this.viewModel.page_count(Math.ceil(result.total/result.limit));

				let locations_formatted = window.Grape.StockUtils.format_locations_list(result.records);
				this.viewModel.locations_formatted(locations_formatted);

				if (this.onload)
				{
					let tags = [];

					result.records.forEach((row) =>
					{

						if (row.tags)
						{
							for (let tag of row.tags)
							{
								let tags_found = tags.some((loc_tag) => {
									return loc_tag === tag;
								});

								if (!tags_found)
									tags.push(tag);
							}
						}
					});
					this.viewModel.available_tags(tags);
				}
				this.onload = false;
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async add_location ()
	{
		await Grape.dialog.open('EditSupplier', {location_id: null});
		this.updateData();
	}

	async edit_location (value)
	{
		let loc = ko.toJS(value);
		await Grape.dialog.open('EditSupplier', {location_id: loc.location_id});
		this.updateData();
	}
}

export default {
	route: '[/]locations/suppliers-list',
	page_class: SuppliersPage,
	template: template
}
