import template from './si_production_page.html';

class ProductionPageVM
{
	constructor (page)
	{
		this.page = page;
		this.editor = null;
		this.bom_id = ko.observable();
		this.loaded = ko.observable(false);
		this.edit_mode = ko.observable(false);
		this.delete_steps_visible = ko.observable(false);
		this.active_section = ko.observable('components');
		this.materials_view = ko.observable(false);
		this.stock_item_id = ko.observable();
		this.workstation_options = ko.observableArray([]);
		this.components = ko.observableArray([]);
		this.versions = ko.observableArray([]);
		this.selected_version = ko.observable();
		this.selected_item = ko.observableArray([]);
		this.workstation_flow_steps = ko.observableArray([]);
		this.removed_steps = ko.observableArray([]);
		this.upload_file = ko.observable();
		this.new_version = ko.observable({ version: 0, version_name: '', version_date: moment(new Date()) });

		this.work_orders = ko.observableArray([]);
		this.highest_work_order_nr = ko.observable(1);
		this.new_work_order = ko.observable('');

		this.lead_time = ko_helper.safe_observable({
			days: ko.observable(0),
			hours: ko.observable(0),
			minutes: ko.observable(0)
		});
		
		this.edit_mode.subscribe(edit_mode => {
			if (!edit_mode)
				this.editor.edit.off();
			else
				this.editor.edit.on();
		});	

		this.materials_view.subscribe(async (new_value) => {
			if (new_value)
			{
				this.collapse_all_boms();
				await this.switch_to_materials_view();
			}
			else
				this.switch_to_components_view();
		});

		this.init();
	}

	async init ()
	{
		await new Promise((resolve) => {
			this.editor = new FroalaEditor('#froala-editor', {
				key: Grape.froala.key,
				attribution: false, // remove "Powered by" watermark
				toolbarButtons: Grape.froala.toolbarButtons,
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		// TODO
		this.editor.html.set('Dit werk');
		// this.editor.html.get();

		if (Grape.currentSession.roles.includes('stock.super_user'))
			this.delete_steps_visible(true);
	}

	async select_version (version)
	{
		if (this.selected_version() === version)
			this.selected_version(undefined)
		else
		{
			this.selected_version(version);

			let lead_time = this.page.parseTimeString(version.lead_time);
			this.lead_time().days(lead_time.days || 0);
			this.lead_time().hours(lead_time.hours || 0);
			this.lead_time().minutes(lead_time.minutes || 0);
			
			await this.page.get_bom_stock_item();
		}
	}

	async add_version_click()
	{
		await this.page.save_bom_stock_item('INSERT');
	}

	update_version_click ()
	{		
		this.components().forEach(component => {
			let steps = this.workstation_flow_steps().map(step => {
				return {
					step_nr: step.step_nr,
					bom_step_id: step.bom_step_id,
					name: step.name
				};
			});
			component.available_steps = ko.observableArray(steps);
		});

		this.edit_mode(true);		
	}

	async save_version_click ()
	{
		this.edit_mode(false);

		await this.page.save_bom_stock_item('UPDATE');
	}

	cancel_version_click ()
	{
		this.edit_mode(false);
	}

	async add_component_click ()
	{
		const new_component = {
			stock_item_id: this.selected_item().stock_item_id,
			qty: 0
		};

		await this.page.save_bom_stock_item('UPDATE', new_component);

		this.selected_item('');
		this.edit_mode(false);
	}

	async remove_component_click (component)
	{
		this.components.splice(this.components().indexOf(component), 1);
		await this.page.save_bom_stock_item('UPDATE');

		this.edit_mode(false);
	}

	add_workstation_flow_step_click ()
	{
		let next_step = this.workstation_flow_steps().length + 1;
		let available_steps = this.workstation_flow_steps().map(step => ko.unwrap(step.step_nr)).filter(step_nr => step_nr < next_step);

		let new_step = {
			step_nr: ko.observable(next_step),
			name: ko.observable(''),
			workstation_id: ko.observable(''),
			available_steps: ko.observableArray(available_steps),
			predependencies: ko.observableArray([]),
			work_order_nr: ko.observable(''),
			selected_work_order: ko.observable({ label: '-', value: null }),
			duration_per_item: ko.observable({
				days: ko.observable(0),
				hours: ko.observable(0),
				minutes: ko.observable(0)
			}),
			instructions: ko.observable(''),
			instructions_filename: ko.observable('')
		};

		new_step.selected_work_order.subscribe(newValue => {
			new_step.work_order_nr(newValue ? newValue.value : null);
		});

		this.workstation_flow_steps.push(new_step);
	}

	async delete_workstation_flow_step_click (step)
	{
		if (!step.bom_step_id)
		{
			this.workstation_flow_steps.remove(step);
			return;
		}
		
		let response = await Grape.alerts.confirm({ 
			type: 'danger', 
			title: 'Delete Step?', 
			message: 'Are you sure you want to delete this step?' 
		});
		if (!response) return;

		this.removed_steps.push(step);
		this.workstation_flow_steps.remove(step);
	}

	async expand_bom_item_click (item)
	{
		item.expanded(!item.expanded());
	
		if (!item.expanded())
		{
			this.components.remove(comp => comp.parent_bom_id === item.bom_id);
			return;
		}
	
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom_stock_items',
				filter: [{ field: 'bom_id', operand: '=', value: item.component_bom_id }]
			});
	
			if (result.status !== 'OK') throw new Error(result.message || result.code);
	
			let bom_components = result.records.map(component => ({
				...component,
				qty: ko.observable(component.component_qty),
				is_secondary_bom_component: true,
				parent_bom_id: item.bom_id,
				expanded: ko.observable(false),
				is_combined_item: false
			}));
	
			let index = this.components.indexOf(item);
			this.components.splice(index + 1, 0, ...bom_components);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	collapse_all_boms ()
	{
		let collapse_and_remove = (component) => {
			let child_components = this.components().filter(c => c.parent_bom_id === component.bom_id);
			child_components.forEach(collapse_and_remove);

			if (component.parent_bom_id)
				this.components.remove(component);
			else
				component.expanded(false);
		};
	
		this.components().forEach(item => {
			if (item.is_bom) collapse_and_remove(item);
		});
	}

	async switch_to_materials_view ()
	{
		let all_components = [];
		
		let flatten_bom = async (component, is_secondary = false) => {
			if (component.is_bom)
			{
				let result = await Grape.fetches.getJSON('api/record', {
					schema: 'stock',
					table: 'v_bom_stock_items',
					filter: [
						{ field: 'bom_id', operand: '=', value: component.component_bom_id }
					]
				});
	
				if (result.status === 'OK')
				{
					for (let bom_component of result.records)
					{
						await flatten_bom({
							...bom_component,
							qty: ko.observable(bom_component.component_qty),
							is_secondary_bom_component: is_secondary || true,
							parent_bom_id: component.bom_id,
							is_combined_item: false,
							expanded: ko.observable(false),
						}, true);
					}
				}
			}
			else
				all_components.push({
					...component,
					is_secondary_bom_component: is_secondary,
				});
		};

		for (let component of this.components())
			await flatten_bom(component);
	
		let combined_components = await this.combine_components(all_components);
		this.components(combined_components);
	}

	switch_to_components_view ()
	{
		this.page.get_bom_stock_item();
	}

	combine_components (components)
	{
		let result = [];
		for (let component of components)
		{
			let existing_component = result.find(c => c.component_stock_item_id === component.component_stock_item_id);

			if (existing_component)
			{
				let existing_qty = ko.unwrap(existing_component.qty);
				let new_qty = ko.unwrap(component.qty);
				existing_component.qty(existing_qty + new_qty);
				existing_component.is_combined_item = true;
			}
			else
				result.push({
					...component,
					qty: ko.observable(ko.unwrap(component.qty)),
				});
		}
		return result;
	}

	async upload_click () 
	{
		const hiddenFileInput = document.querySelector('.attachment_upload');

		const changeListener = async () => {
			const selectedFile = hiddenFileInput.files[0];
	
			if (selectedFile)
			{
				await window.Grape.StockUtils.fileupload({
					form_id: document.getElementById('workflow_attachment_upload_form'),
					api: '/api/stock-management/workstation/flow/step/attachment/upload',
					header_info: {},
					success_message: 'Attachment successfully uploaded!'
				});

				this.page.updateData();

				hiddenFileInput.removeEventListener('change', changeListener);
			}
		};

		hiddenFileInput.addEventListener('change', changeListener);
		hiddenFileInput.click();
	}

	add_work_order ()
	{
		this.highest_work_order_nr(this.highest_work_order_nr() + 1);

		let new_work_order = {
			label: "Work Order #" + this.highest_work_order_nr(),
			value: this.highest_work_order_nr()
		};

		this.work_orders.push(new_work_order);
		this.new_work_order('');
	}
}

class ProductionPage
{
	constructor (bindings)
	{
		this.viewModel = new ProductionPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Production';
		this.load_workstation_options();
	}

	async updateData ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom',
				filter: [
					{
						field: 'stock_item_id',
						operand: '=',
						value: this.viewModel.stock_item_id()
					}
				]
			});

			if (result.status == 'OK')
			{
				this.viewModel.versions(result.records);
				this.viewModel.bom_id(result.records[0].bom_id);

				let version = 1;
				let latest_version = {};
				if (this.viewModel.versions().length > 0)
				{
					version = this.viewModel.versions().reduce((max, current) => { 
						if (current.version > max)
							latest_version = current;

						return current.version > max ? current.version : max 
					}, 0) + 1;

					this.viewModel.selected_version(latest_version);
				}

				this.viewModel.new_version({ version: version, version_name: '', version_date: new Date().toISOString().slice(0, 10) });

				await this.load_bom_workstation_flow_steps(this.viewModel.bom_id());
				await this.get_bom_stock_item();
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async get_bom_stock_item ()
	{
		try
		{
			if (!this.viewModel.stock_item_id())
				throw new Error('stock_item_id is required');
			
			let filter = [{
					field: 'stock_item_id',
					operand: '=',
					value: this.viewModel.stock_item_id()
				}]

			if (this.viewModel.selected_version() !== undefined)
				filter.push({
					field: 'version',
					operand: '=',
					value: this.viewModel.selected_version().version
				});

			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom_stock_items',
				filter: filter
			});
		
			if (result.status != 'ERROR')
			{
				let updated_obj = result.records.map(item => {
					let { component_qty, automatic_usage, bom_step_id, ...rest } = item;
					let usage_method = automatic_usage ? 'Auto' : 'Manual';
					let matching_step = this.viewModel.workstation_flow_steps().find(step => step.bom_step_id === bom_step_id);
					delete rest.stock_item_id;
					return { 
						stock_item_id: item.component_stock_item_id, 
						qty: ko.observable(component_qty),
						expanded: ko.observable(false),
						is_secondary_bom_component: false,
						is_combined_item: false,
						selected_usage_method: ko.observable(usage_method),
						selected_usage_step: ko.observable(matching_step ? matching_step.bom_step_id : null),
						...rest 
					};
				});

				this.viewModel.components(updated_obj);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async save_bom_stock_item (type = 'UPDATE', new_component)
	{
		try {
			let { days, hours, minutes } = ko.toJS(this.viewModel.lead_time());
			let obj = {
				...this.viewModel.selected_version(),
				lead_time: `${days} days ${hours} hours ${minutes} minutes`,
				components: ko.toJS(this.viewModel.components()).map(component => ({
					stock_item_id: component.stock_item_id,
					qty: component.qty,
					selected_usage_method: component.selected_usage_method,
					selected_usage_step: component.selected_usage_step
				})),
				steps: {
					add: this.viewModel.workstation_flow_steps().map(step => {
						let predependencies = ko.unwrap(step.predependencies);
						if (!Array.isArray(predependencies))
							predependencies = [predependencies];

						let { days: step_days, hours: step_hours, minutes: step_minutes } = ko.toJS(step.duration_per_item);
						let duration_string = `${step_days} days ${step_hours} hours ${step_minutes} minutes`;
	
						return {
							bom_step_id: ko.unwrap(step.bom_step_id),
							step_nr: ko.unwrap(step.step_nr),
							bom_id: this.viewModel.bom_id(),
							workstation_id: ko.unwrap(step.workstation_id),
							name: ko.unwrap(step.name),
							predependencies: predependencies.map(dep => ko.unwrap(dep)),
							instructions: ko.unwrap(step.instructions),
							instructions_filename: ko.unwrap(step.instructions_filename),
							work_order_nr: ko.unwrap(step.work_order_nr),
							duration: duration_string
						};
					}),
					remove: this.viewModel.removed_steps().map(step => ({
						bom_step_id: ko.unwrap(step.bom_step_id)
					}))
				}
			};

			if (type == 'INSERT')
			{
				delete obj.bom_id;
				obj.version_date = this.viewModel.new_version().version_date;
				obj.version_name = this.viewModel.new_version().version_name;
				obj.version = this.viewModel.new_version().version;
			}

			if (new_component)
				obj.components.push(new_component);

			let result = await Grape.fetches.postJSON("/api/stock-management/bom", obj);

			if (result.status == 'OK')
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Successfully saved Bill of materials' });

		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error });
			console.error(error);
		} finally {
			this.viewModel.removed_steps([]);
			await this.updateData();
		}
	}

	async load_bom_workstation_flow_steps (bom_id)
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom_step',
				filter: [{
					field: 'bom_id',
					operand: '=',
					value: bom_id
				}]
			});

			if (result.status === 'OK')
			{
				this.viewModel.work_orders([]);

				let x = result.records.map(step => {
					let existing_order_nr = this.viewModel.work_orders().find(order => order.value === step.work_order_nr);
					if (!existing_order_nr)
					{
						let new_work_order = {
							label: `Work Order #${step.work_order_nr}`,
							value: step.work_order_nr
						};
						this.viewModel.work_orders.push(new_work_order);
					}

					let parsed_duration = this.parseTimeString(step.duration);

					let step_data = {
						...step,
						available_steps: ko.observableArray(step.available_steps),
						predependencies: ko.observableArray(step.predependencies),
						work_order_nr: ko.observable(step.work_order_nr),
						selected_work_order: ko.observable(this.viewModel.work_orders().find(order => order.value === step.work_order_nr) || {}),
						duration_per_item: ko.observable({
							days: ko.observable(parsed_duration.days || 0),
							hours: ko.observable(parsed_duration.hours || 0),
							minutes: ko.observable(parsed_duration.minutes || 0)
						})
					};
	
					step_data.selected_work_order.subscribe(newValue => {
						step_data.work_order_nr(newValue.value);
					});
	
					return step_data;
				});
	
				this.viewModel.workstation_flow_steps(x);

				let highest_work_order_nr = Math.max(0, ...this.viewModel.workstation_flow_steps().map(step => step.work_order_nr() || 0));
				this.viewModel.highest_work_order_nr(highest_work_order_nr);				
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_workstation_options ()
	{
		try	{
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: []
			});
		
			if (result.status != 'ERROR')
				this.viewModel.workstation_options(result.records)
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	parseTimeString (timeString) 
	{
		let days = 0, hours = 0, minutes = 0;
	
		if (timeString.includes('days'))
		{
			const parts = timeString.split(' ');
			days = parseInt(parts[0]);
	
			if (parts.length > 2)
			{
				const [hoursPart, minutesPart, secondsPart] = parts[2].split(':').map(Number);
				hours = hoursPart || 0;
				minutes = minutesPart || 0;
			}
		}
		else if (timeString.includes(':'))
		{
			const [hoursPart, minutesPart, secondsPart] = timeString.split(':').map(Number);
			hours = hoursPart || 0;
			minutes = minutesPart || 0;
		}
	
		return {
			days: days,
			hours: hours,
			minutes: minutes
		};
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_production',
	page_id: 'si_production',
	page_class: ProductionPage,
	template: template
};