import template from './ko-production-order-items.html';

class ProductionOrderItemsComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.selected_assoc_tab = ko.observable('bom_items');
		this.available_items_object = ko.observableArray([]);
		this.order_items = ko_helper.safe_observableArray(params.order_items || []);
		this.selected_items = ko_helper.safe_observableArray(params.initial_selected_items);
		this.type = ko_helper.safe_observable(params.type);
		this.delivery_date = ko_helper.safe_observable(params.delivery_date);
		this.can_send_delivery = ko_helper.safe_observable(params.can_send_delivery || false);
		this.can_receive_delivery = ko_helper.safe_observable(params.can_receive_delivery || false);
		this.sent_outstanding_html = ko.observable('<span>Outstanding</span>');
		this.receive_outstanding_html = ko.observable('<span>Outstanding</span>');
		this.components_table_records = ko.observableArray([]);
		this.available_raw_components_locations = ko.observableArray([]);
		this.available_internal_location_ids = ko.observableArray([]);

		this.can_send_delivery.subscribe(() => this.update_outstanding_column_headings() );
		this.can_receive_delivery.subscribe(() => this.update_outstanding_column_headings() );

		this.selected_items.subscribe(async (new_selected_items) => {
			for (let selected_item of new_selected_items)
			{
				let has_product = this.order_items().some((item) => item.bom_id === selected_item.bom_id);
				let has_different_version = this.order_items().some((item) => item.stock_item_id === selected_item.stock_item_id && item.bom_id != selected_item.bom_id);
				let item = this.available_items_object().find((item) => item.bom_id == selected_item.bom_id);

				if (item && !has_product && !has_different_version)
				{
					this.order_items.push({
						stock_item_id: item.stock_item_id,
						code: item.code,
						description: item.description,
						bom_id: selected_item.bom_id,
						qty: ko.observable(0),
						expected_receive_dates: ko.observableArray([{ date: ko.observable(this.delivery_date()), qty: ko.observable(0) }])
					});
				}
				else if (item && !has_product && has_different_version)
				{
					let result = await Grape.alerts.confirm({
						type: 'warning',
						message: 'An order item for the same BOM with a different version already exists. Do you want to replace it? This will remove the qty for the BOM item!',
						title: 'Duplicate BOM Item'
					});
					if (result)
					{
						this.order_items.remove((existing_item) => existing_item.stock_item_id === selected_item.stock_item_id && existing_item.bom_id != selected_item.bom_id);

						this.order_items.push({
							stock_item_id: item.stock_item_id,
							code: item.code,
							description: item.description,
							bom_id: selected_item.bom_id,
							qty: ko.observable(0),
							expected_receive_dates: ko.observableArray([{ date: ko.observable(this.delivery_date()), qty: ko.observable(0) }])
						});
					}
					else
						this.selected_items.remove((selected_item) => item.bom_id === selected_item.bom_id)
				}
				else if (item && has_product)
				{
					this.order_items().map(order_item => {
						if (order_item.bom_id === item.bom_id)
							order_item.bom_id = item.bom_id;
						return order_item;
					});
				}
				else if (!item && has_product)
				{
					this.order_items().filter(order_item => {
						return new_selected_items.some(selected_item => selected_item.bom_id === order_item.bom_id);
					});
				}
			}
		});

		this.delivery_date.subscribe((new_date) => {
			this.order_items().forEach((item) => {
				item.expected_receive_dates().forEach((val) => val.date(new_date) );
			});
		});

		this.selected_assoc_tab.subscribe((newVal) => {
			if (newVal === 'raw_materials')
				this.update_components_data();
		});

		this.available_items_changed = this.available_items_changed.bind(this);
		this.get_all_internal_locations();
	}

	available_items_changed (newItems)
	{
		this.available_items_object(newItems);
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#prod-order-items-nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});
		
		event.currentTarget.classList.add('active');
		
		this.selected_assoc_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	column_visibility ()
	{
		if (this.type() == 'create')
			return false;
		else
			return true;
	}

	view_mode () 
	{
		return this.type() === 'view';
	}

	create_mode () 
	{
		return this.type() === 'create';
	}

	async btn_remove_item_click (item) 
	{
		let result = await Grape.alerts.confirm(
		{
			type: 'warning',
			message: 'Are you sure you want to remove this order item?',
			title: 'Remove Order Item', 
		});

		if (result)
		{
			this.order_items.remove(item);
			let index = -1;
			let count = 0;
			let items = this.selected_items();
			items.forEach((s_item) => {
				if (s_item.bom_id == item.bom_id)
					index = count;
				count++;
			});
			items.splice(index, 1);
			this.selected_items(items);
		}
	}

	async btn_remove_delivery_date_click (item, delivery)
	{
		// Check if this is the last delivery for the order item
		let single_delivery = item.expected_receive_dates().length === 1;
		let confirm_message = single_delivery ? 'Are you sure you want to remove this order item?' : 'Are you sure you want to remove this delivery?';
	
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: confirm_message,
			title: single_delivery ? 'Remove Order Item' : 'Remove Delivery',
		});
	
		if (result)
		{
			item.expected_receive_dates.remove(delivery);
			if (item.expected_receive_dates().length === 0) 
			{
				this.order_items.remove(item);

				let index = -1;
				let count = 0;
				let items = this.selected_items();
				items.forEach((s_item) => {
					if (s_item.bom_id == item.bom_id)
						index = count;
					count++;
				});
				items.splice(index, 1);
				this.selected_items(items);
			}
		}
	}

	btn_add_extra_delivery_click (item)
	{
		item.expected_receive_dates.push({date: ko.observable(this.delivery_date()), qty: ko.observable(0)});
	}

	update_outstanding_column_headings()
	{
		if (this.can_send_delivery() && this.can_receive_delivery())
		{
			this.sent_outstanding_html('<span>Outstanding</span><br /><span style="font-size: small;">(Sent)</span>');
			this.receive_outstanding_html('<span>Outstanding</span><br /><span style="font-size: small;">(Received)</span>');
		}
	}

	async get_all_internal_locations ()
	{
		let location_type_id = await Grape.fetches.getJSON('/api/record', {
			table: 'v_location_types',
			schema: 'stock',
			fields: ['location_type_id'],
			filter: [
				{ field: 'location_type_value', operand: '=', value: 'Internal' }
			],
			limit: 1
		});

		let internal_locations = await Grape.fetches.getJSON('/api/record', {
			table: 'location',
			schema: 'stock',
			fields: ['location_id'],
			filter: [
				{ field: 'location_type', operand: '=', value: location_type_id.records[0].location_type_id }
			]
		});

		let internal_location_ids = internal_locations.records.map((record) => record.location_id);

		if (internal_location_ids.length > 0)
			this.available_internal_location_ids(internal_location_ids);
	}

	async update_components_data ()
	{
		try {
			let bom_items = this.order_items();
			let all_components = [];

			for (let bom_item of bom_items)
			{
				let bom_components = await Grape.fetches.getJSON('/api/record', {
					table: 'v_bom_stock_items',
					schema: 'stock',
					fields: [
						'component_stock_item_id',
						'component_qty',
						'code',
						'stock_item',
						'stock_group',
						'uom_symbol'
					],
					filter: [
						{ field: 'bom_id', operand: '=', value: bom_item.bom_id }
					]
				});

				for (let component of bom_components.records)
				{
					let qty_required = component.component_qty * bom_item.qty();
					let qty_used = bom_item.qty_received * component.component_qty;
					let qty_outstanding = (bom_item.qty() - bom_item.qty_received) * component.component_qty;

					let component_item = {
						bom_description: bom_item.description,
						sku: component.code,
						description: component.stock_item,
						group: component.stock_group,
						qty_required: qty_required,
						uom: component.uom_symbol,
						stock_item_id: component.component_stock_item_id,
						bom_stock_item_id: bom_item.stock_item_id,
						qty_used: qty_used,
						qty_outstanding: qty_outstanding,
						selected_source: ko.observable(),
						selected_source_qty: ko.observable(),
						total_qty: ko.observable()
					};

					// Subscribe to changes in selected_source
					component_item.selected_source.subscribe(async (newValue) => {
						let options = {
							offset: 0,
							filter_join: 'AND',
							join: 'OR',
							sortorder: 'ASC',
							sortfield: 'stock_item_description',
							filter: [],
							limit: 30
						}

						let params = {
							options: options,
							location_ids: [newValue.location_id],
							stock_item_id: component_item.stock_item_id,
							date_effective: new moment().format('YYYY-MM-DD')
						}

						let all_levels_params = {
							options: options,
							location_ids: this.available_internal_location_ids(),
							stock_item_id: component_item.stock_item_id,
							date_effective: new moment().format('YYYY-MM-DD')
						}
 
						try
						{
							let levels = await Grape.fetches.getJSON('/api/stock-management/stock-level', params);

							if (levels.status != 'ERROR')
								component_item.selected_source_qty(levels.records[0].qty);
							else
								throw new Error(levels.message || levels.code);

							let all_levels = await Grape.fetches.getJSON('/api/stock-management/stock-level', all_levels_params);

							if (all_levels.status != 'ERROR')
							{
								let total_qty = all_levels.records.reduce((sum, record) => sum + record.qty, 0);
								component_item.total_qty(total_qty)
							}
							else
								throw new Error(levels.message || levels.code);
						} catch (error) {
							Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
							console.error(error)
						}
					});

					all_components.push(component_item);
				}
			}

			this.components_table_records(all_components);

			let location_type_id = await Grape.fetches.getJSON('/api/record', {
				table: 'v_location_types',
				schema: 'stock',
				fields: ['location_type_id'],
				filter: [
					{ field: 'location_type_value', operand: '=', value: 'Raw Materials' }
				],
				limit: 1
			});

			let source_locations = await Grape.fetches.getJSON('/api/record', {
				table: 'location',
				schema: 'stock',
				//fields: ['location_id'],
				filter: [
					{ field: 'location_type', operand: '=', value: location_type_id.records[0].location_type_id }
				]
			});
			
			if (source_locations)
				this.available_raw_components_locations(source_locations.records);
		} catch (error) {
			console.error('Failed to fetch and populate BOM components data:', error);
		}
	}
}

export default {
	name: 'ko-production-order-items',
	viewModel: ProductionOrderItemsComponentViewModel,
	module_type: 'ko',
	template: template
}