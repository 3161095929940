import template from './edit_location.html';

class EditLocationViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;

		this.title = ko.observable('Edit Location');
		this.location_id = ko.observable();
		this.name = ko.observable();
		this.code = ko.observable();
		this.in_use = ko.observable(true);
		this.locations_display = ko.observableArray([]);
		this.selectedLocation = ko.observableArray();
		this.location_caption = ko.observable('None');
		this.location_tag = ko.observableArray([{ name: undefined }])
		this.location_types = ko.observableArray([]);
		this.selected_type = ko.observable();
		this.shipping_address = ko.observable();
	}	

	addLocationTag () 
	{
		this.location_tag.push({ name: undefined });
	}

	removeLocationTag (v)
	{
		this.location_tag.remove(v);
	}
}

class EditLocationPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditLocationViewModel(this);
		this.viewModel.location_id(bindings.location_id);

		if (bindings.fields)
			this.viewModel.shipping_address(bindings.fields['shipping-address'] || null);

		this.init();
	}

	async init ()
	{
		try {
			let options = {
				table: 'v_location_types',
				schema: 'stock',
				offset: 0,
				limit: 1000,
				sortorder: 'ASC',
				sortfield: 'location_type_id',
				filter_join: 'AND',
				join: 'OR',
				filter: [],
				fields: ['location_type_value']
			}

			let result = await Grape.fetches.getJSON('/api/record', options);
			
			if (result.status != 'ERROR')
			{
				let unique_location_type_values = [...new Set(result.records.map(item => item.location_type_value))];
				this.viewModel.location_types(unique_location_type_values);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		await this.updateData();

		if (!this.bindings.location_id)
			this.viewModel.title('New Location');

		if (this.bindings.location_type)
			this.viewModel.selected_type(this.bindings.location_type);
	}

	async updateData ()
	{
		let location_result = await Grape.cache.fetch('Locations');

		let parent_location_id = null;
		let locations_display = [];
		let selected_parent = {};
		for (let i = 0; i < location_result.length; i++)
		{
			let loc = location_result[i];

			if (loc.location_id == this.viewModel.location_id())
			{
				this.viewModel.name(loc.name);
				this.viewModel.in_use(loc.in_use);
				this.viewModel.code(loc.code);
				this.viewModel.selected_type(loc.location_type);
				if (loc.tags && loc.tags != '')
				{
					let tags = loc.tags.map(name => ({ name }));
					this.viewModel.location_tag(tags);
				}
				parent_location_id = loc.parent_location_id;
			}
			else
				if (loc.in_use)
					locations_display.push(loc);
		}

		for (let i = 0; i < location_result.length; i++)
		{
			let loc = location_result[i];
			if (loc.location_id == parent_location_id)
				selected_parent = loc;
		}
		
		let filtered_locations = locations_display.filter(location => location.location_type == this.viewModel.selected_type());
		this.viewModel.locations_display(filtered_locations);
		this.viewModel.selectedLocation(selected_parent);
	}

	async save_location ()
	{
		if (this.viewModel.selected_type()) 
		{
			let parent_location_id = null;

			let selectedLocation = this.viewModel.selectedLocation();
			if (Array.isArray(selectedLocation) && selectedLocation.length === 1)
				parent_location_id = selectedLocation[0].location_id;

			let tags = this.viewModel.location_tag().map(obj => obj.name);
			let loc = {
				location_id: this.viewModel.location_id(),
				name: this.viewModel.name(),
				parent_location_id: parent_location_id,
				in_use: this.viewModel.in_use(),
				type: this.viewModel.selected_type(),
				code: this.viewModel.code(),
				tags: tags
			};

			try
			{
				if (this.viewModel.shipping_address() && (this.viewModel.selected_type() === 'Internal' || this.viewModel.selected_type() === 'Supplier'))
					await this.save_location_address()

				let response = await fetch('api/stock-management/location', {
					method: 'POST',
					headers: {'content-type': 'application/json' },
					body: JSON.stringify(loc)
				});

				let data = await response.json();

				if (response.ok)
					this.updateData();
				else
					throw new Error(data.message || response.code);
			} catch (error) {
				Grape.alerts.alert({title: 'Error', type: 'error', message: error.message });
				console.error(error);
			} finally {
				Grape.cache.refresh('Locations');
				this.close(true);
			}
		}
		else 
			Grape.alerts.alert({type:'warning', title: 'Warning', message: 'Please select a location type first!'});
	}

	async save_location_address ()
	{
		try 
		{
			let response = await Grape.fetches.postJSON('/api/stock-management/location/fields', {
				field_name: 'shipping-address',
				field_value: this.viewModel.shipping_address(),
				location_id: this.viewModel.location_id(),
				location_type: this.viewModel.selected_type()
			});

			if (response.status == 'OK')
				console.info('Save location address successful!')
		} catch (error) {
			Grape.alerts.alert({title: 'Error', type: 'error', message: error.message});
			console.error(error);
		}
	}

	btn_cancel_click ()
	{
		this.close(false);
	}
}

export default {
	name: 'EditLocation',
	dialog_class: EditLocationPage,
	template: template
}