import template from './future_shortages.html';

class FutureShortagesViewModel
{
	constructor(page)
	{
		this.page = page;
	}
}

class FutureShortagesPage
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new FutureShortagesViewModel(this);
	}

	async init()
	{
		document.title = 'Future Shortages';
	}
}

export default {
	route: '/projections/future_shortages/',
	page_class: FutureShortagesPage,
	template: template
}
