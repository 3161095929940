export default {
	name: 'OrderTypes',
	options: {
		refresh: async () => {
			const t_results = await Grape.fetches.getJSON('/api/record', {
				limit: 1000,
				table: 'v_order_types',
				schema: 'stock'
			});
			const ret = {};
			for (let r of t_results.records || [])
			{
				ret[r.type] = {
					type: r.type,
					batch_created: r.batch_created,
					child_order_types: r.child_order_types,
					order_nr_prefix: r.order_nr_prefix,
					statuses: [],
					fields: r.fields,
					source_location_type: r.source_location_type,
					target_location_type: r.target_location_type
				};
				if (r.send_movement_type)
					ret[r.type].send_movement_type = `${r.send_movement_type}/${r.send_movement_subtype}`;
				if (r.receive_movement_type)
					ret[r.type].receive_movement_type = `${r.receive_movement_type}/${r.receive_movement_subtype}`;
			}
			
			const s_results = await Grape.fetches.getJSON('/api/record', {
				schema: 'stock',
				table: 'v_order_type_statuses',
				limit: 1000
			});
			for (let r of s_results.records || [])
			{
				ret[r.type].statuses.push({
					status: r.status,
					accept_status: r.accept_status,
					reject_status: r.reject_status,
					confirm_sent: r.confirm_sent,
					confirm_received : r.confirm_received,
					other_status: r.other_status,
					allowed_status: [r.accept_status, r.reject_status].concat(r.other_status || []),
					document_name: r.document_name,
					document_template: r.document_template,
					allowed_role: r.allowed_role || [],
					initial: r.initial,
					final: r.final,
					confirmation: r.confirmation,
					cancellation: r.cancellation,
					is_send_complete_transaction: r.is_send_complete_transaction,
					is_receive_complete_transaction: r.is_receive_complete_transaction
				});
			}
			return ret;
		}
	}
};
