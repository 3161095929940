import template from './workstation-list.html';

class ComponentVM
{
	constructor(params)
	{
		this.params = params;
		this.workstations = ko.observableArray([]);
		this.delete_workstation_visible = ko.observable(false);

		this.init();
	}
	
	async init()
	{
		await this.updateData();

		if (Grape.currentSession.roles.includes('stock.super_user'))
			this.delete_workstation_visible(true);
	}

	async updateData ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: []
			});
		
			if (result.status != 'ERROR')
			{
				this.workstations(result.records)
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async delete_workstation_click (workstation)
	{
		if (this.workstations().length < 2)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Cannot delete a workstation if no other workstations exist! Please create a new workstation first.' });
			return;
		}

		let result = await Grape.alerts.confirm({ 
			type: 'danger', 
			title: 'Delete Workstation?', 
			message: 'Are you sure you want to delete this workstation? It will require a replacement workstation for any BOM steps linked to it!' 
		});
		if (!result) return;
		
		let dialog_result = await Grape.dialog.open('DeleteWorkstation', { workstation_id: workstation.workstation_id, workstation_options: this.workstations() });

		if (dialog_result)
		{
			let replacement_workstation_id = dialog_result;

			try {
				let options = { 
					workstation_id: workstation.workstation_id,
					replacement_workstation_id: replacement_workstation_id
				};
				let result = await Grape.fetches.deleteJSON('/api/stock-management/workstation', options);
		
				if (result.status === 'OK')
				{
					Grape.alerts.alert({ type: 'success', title: 'Deleted', message: 'Workstation successfully deleted' });
					Grape.navigate(`/workstation/setup`);
				}
				else
					throw new Error(result.message || result.code);
		
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error('Error deleting workstation:', error);
			}

			return;
		}
		else
			return;
	}

	view_workstation_click (workstation)
	{
		Grape.navigate(`/workstation/setup/${workstation.workstation_id}`);
	}
}

export default {
	name: 'workstation-list',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};