export default {
	name: 'CreateOrderLocations',
	options: {
		refresh: async (cb) => {
			let result = await Grape.fetches.getJSON('/api/stock-management/user_location');
			if (!result.locations)
				result.locations = [];

			let locations = [];
				
			for (let i = 0; i < result.locations.length; i++)
			{
				let loc = result.locations[i];
				if (loc.in_use && loc.permissions.indexOf('CreateOrder') != -1 && loc.type != 'Supplier')
					locations.push({ location_id: loc.location_id, location_name: loc.location_name });
			}

			cb(null, locations);
			//TODO error handle
		}
	}
};
