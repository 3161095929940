import template from './system_attributes.html';

class SystemAttributesViewModel 
{
	constructor (page) 
	{
		this.page = page;
		this.upload_file = ko.observable();
		this.logo_image = ko.observable();
		this.can_edit = ko.observable(false);
	}

	async upload_click () 
	{
		const hiddenFileInput = document.querySelector('.image_upload');

		const changeListener = async () => {
			const selectedFile = hiddenFileInput.files[0];
	
			if (selectedFile)
			{
				await window.Grape.StockUtils.fileupload({
					form_id: document.getElementById('company_logo_upload_form'),
					api: '/api/stock-management/company/logo/upload',
					header_info: {},
					success_message: 'Image successfully uploaded! Reload your browser to load the new logo in the navbar.'
				});

				this.page.load_company_logo();

				hiddenFileInput.removeEventListener('change', changeListener);
			}
		};

		hiddenFileInput.addEventListener('change', changeListener);
		hiddenFileInput.click();
	}
}

class SystemAttributesPage 
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new SystemAttributesViewModel(this);
		document.title = 'System Attributes';
	}

	init ()
	{
		this.viewModel.can_edit(Grape.currentSession.roles.includes('stock.super_user'));
		this.load_company_logo();
	}

	async load_company_logo ()
	{
		try
		{
			let result = await fetch(`/api/stock-management/company/logo/download/`, {
				method: 'GET',
				headers: { 'Content-Type': 'image/png' }
			});

			if (result.ok)
			{
				let blob = await result.blob();
				let img = document.getElementById('logo-preview');
				img.src = URL.createObjectURL(blob);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}
}

export default {                
	route: '[/]stock/admin/system-attributes',
	page_class: SystemAttributesPage,
	template: template              
}
