import template from './production_schedule.html';

class ProductionSchedulePageViewModel
{
	constructor (page)
	{
		this.page = page;
	}
}

class ProductionSchedulePageClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProductionSchedulePageViewModel(this);
	}

	init ()
	{
		document.title = 'Production Schedule'
	}

	updateData ()
	{
	}
}

export default {
	route: '[/]production/schedule',
	page_class: ProductionSchedulePageClass,
	template: template
}