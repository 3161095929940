import template from './redirect_order_page.html';

class RedirectOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.show_loader = ko.observable(false);
		this.order_id = ko.observable();

		this.show_loader.subscribe(show => {
			let loader = document.getElementById('loader-dialog');
			if (show)
				loader.showModal();
			else
				loader.close();
		});
	}
}

class RedirectOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new RedirectOrderViewModel(this);
	}

	async init () 
	{
		document.title = 'Redirecting to order...';
		this.viewModel.show_loader(true);

		if (this.bindings.order_id != undefined && this.bindings.order_id != null)
			this.viewModel.order_id(this.bindings.order_id)
		else
		{
			history.back();
			Grape.alerts.alert({ type: 'danger', title: 'Invalid Order ID', message: `Cannot redirect to an invalid Order ID!` });
			this.viewModel.show_loader(false);
			return;
		}

		let order_data_result = await Grape.fetches.getJSON(`/api/stock-management/order`, { order_id: this.viewModel.order_id() });
		if (order_data_result.status === 'OK' && order_data_result.order != null && this.viewModel.order_id() !== undefined)
		{
			let order_data = order_data_result.order

			let type;
			let order_id = order_data.order_id;

			if (order_data.cancelled || order_data.completed)
				type = 'view';
			else
				type = 'edit';

			if (order_data.order_type === "Production Order")
				Grape.navigate(`/production/order/${type}/${order_id}`)
			else
				Grape.navigate(`/stock/order/${type}/${order_id}`)

			this.viewModel.show_loader(false);
		}
		else
		{
			Grape.alerts.alert({
				type: 'warning', 
				title: 'Order Not Found', 
				message: `Could not find order with id '${this.viewModel.order_id()}'! Please ensure that the order exists.`
			});
			history.back();
			this.viewModel.show_loader(false);
		}
	}
}

export default {
	route: '/order/:order_id',
	page_class: RedirectOrderPage,
	template: template
}
