export default {
	name: 'UserLocations',
	options: {
		refresh: async (cb) => {
			let result = await Grape.fetches.getJSON('/api/stock-management/user_location');
			if (!result.locations)
				result.locations = [];
			cb(null, result);
			//TODO error handle
		}
	}
};
