import template from './landing.html';

class LandingPageViewModel
{
	constructor (page)
	{
		this.page = page;
	}

	nav_btn_click (url)
	{
		if (!url)
			console.error('Invalid URL');

		Grape.navigate(url);
	}

	view_graph_btn_click (graph)
	{
		Grape.dialog.open('ViewStockGraphDialog', { type: graph });
	}
}

class LandingPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new LandingPageViewModel(this);
		this.name = 'LandingPageClass';
	}

	init ()
	{
		document.title = 'Home';
	}
}

export default {
	route: '[/]stock/landing-page',
	page_class: LandingPageClass,
	template: template
}