import template from './create_production_picking_order.html';

class CreateProductionPickingOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(true);
		this.type = ko.observable(this.page.bindings.type != null ? this.page.bindings.type : 'create');
		this.order_items_type = ko.observable(this.page.bindings.type);
		this.order_number = ko.observable();

		this.type.subscribe(() => this.update_order_items_type() );
	}

	update_order_items_type()
	{
		let type = this.type();
		if (type == 'edit' && this.order().confirmed)
			type = 'view';
		this.order_items_type(type);
	}

	btn_back_click () 
	{
		Grape.navigate('/production/orders_and_execution/');
	}
}

class CreateProductionPickingOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new CreateProductionPickingOrderViewModel(this);
	}

	init () 
	{
		document.title = 'Create Production Picking Order';
	}
}

export default {
	route: '/production/picking/order/:type/:order_id',
	page_class: CreateProductionPickingOrderPage,
	template: template
}
