export default {
	name: 'StockItemTags',
	options: {
		refresh: async (cb) => {
			let result = await Grape.fetches.getJSON('/api/stock-management/tag');	
			if (!result.tags)
				result.tags = [];
			cb(null, result);
			//TODO error handle
		}
	}
};
