import template from './view_stock_graph.html';

class ViewStockGraphDialogViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.type_binding = ko.observable();

		this.filter = ko.observable();
		this.title = ko.observable();
		this.order_type = ko.observable();
		this.locations = ko.observableArray();
		this.default = ko.observable();
		this.chart_names = ko.observableArray();
		this.chart_name = ko.observable();
		this.selected_location = ko.observable();        
		this.date_start = ko.observable(new moment().startOf('month').subtract(5,'months').format('YYYY-MM-DD'));
		this.date_end = ko.observable(new moment().startOf('month').add(1, 'months').format('YYYY-MM-DD'));

		this.params = ko.computed(() => {
			if (typeof this.selected_location() != 'undefined')
			{
				let filters = {
					filter: [
						{field: 'date_start', 'operator': '>=', value: this.date_start()},
						{field: 'date_start', 'operator': '<', value: this.date_end()},
						{field: this.default().location_table, 'operator': '=', value:  this.selected_location()?.name}
					],
					filter_join: 'AND'
				};

				if (this.order_type() != "")
					filters.filter.push({field: 'order_type', 'operator': '=', value: this.order_type()});

				this.filter(filters);
				this.chart_name(this.chart_names()[0].name);
			}
		});

		this.locations.subscribe((data) => {
			this.selected_location(data[0]);
		});
	}

	btn_close_click () 
	{
		this.dialog.close();
	}
}

class ViewStockGraphDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.chart_data_loaded = ko.observable(false);
		this.viewModel = new ViewStockGraphDialogViewModel(this);
		this.viewModel.type_binding(bindings.type);
	}

	async init ()
	{
		let type_mappings = {
			sales_orders: {
				title: 'Sales Orders per Location',
				chart_names: [
					{ name: 'SalesOrdersMonth', description: 'Monthly' },
					{ name: 'SalesOrdersWeek', description: 'Weekly' }
				],
				default: { location_table: 'source_location' },
				order_type: 'Sales Order'
			},
			purchase_orders: {
				title: 'Purchase Orders per Location',
				chart_names: [
					{ name: 'ClientOrdersMonth', description: 'Monthly' },
					{ name: 'ClientOrdersWeek', description: 'Weekly' }
				],
				default: { location_table: 'target_location' },
				order_type: 'Purchase Order'
			},
			stock_levels: {
				title: 'Stock Levels per Location',
				chart_names: [
					{ name: 'InventoryLevelsMonth', description: 'Monthly' },
					{ name: 'InventoryLevelsWeek', description: 'Weekly' }
				],
				default: { location_table: 'location' }
			}
		};

		let settings = type_mappings[this.viewModel.type_binding()];

		if (settings)
		{
			this.viewModel.title(settings.title);
			this.viewModel.chart_names(settings.chart_names);
			this.viewModel.default(settings.default);
			this.viewModel.order_type(settings.order_type || '');
		}

		let locations = await Grape.cache.fetch('Locations');
		this.viewModel.locations(locations);
	}
}

export default {
	name: 'ViewStockGraphDialog',
	dialog_class: ViewStockGraphDialog,
	template: template
}