import template from './si_movements_page.html';

class StockItemMovementsPageVM
{
	constructor (page)
	{
		this.page = page;
		this.loaded = ko.observable(false);
		this.stock_item_id = ko.observable();
	}
}

class StockItemMovementsPage
{
	constructor (bindings)
	{
		this.viewModel = new StockItemMovementsPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Item Movements';
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_movements',
	page_id: 'si_movements',
	page_class: StockItemMovementsPage,
	template: template
};