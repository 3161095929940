import template from './locations-treeview.html';

class LocationsTreeviewViewModel
{
	constructor (params)
	{
		this.params = params;
		this.locations = ko_helper.safe_observableArray(params.locations);
	}
}

export default {
	name: 'ko-locations-treeview',
	viewModel: LocationsTreeviewViewModel,
	module_type: 'ko',
	template: template
}