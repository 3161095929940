import template from './stock-graph-panel.html';

/**
* @class StockGraphPanelComponentViewModel
* @component name - stock-graph-panel
* @constructor 
* @param {text} [title] - title of the dropdown
* @param {Array} [chart_names] - list of chart names for dropdown [{name: 'Graph name', description: 'graph type', display_name: ''}],
* @param {Array} [locations] - set location for graph dropdown,
* @param {object} [default] - default values unique to each graph {location_table : 'source_location'}
*/
class StockGraphPanelComponentViewModel
{
	constructor (params)
	{
		this.params = params
		
		this.filter = ko.observable();
		this.title = ko_helper.safe_observable(params.title || '');
		this.order_type = ko_helper.safe_observable(params.order_type || '');
		this.locations = ko_helper.safe_observableArray(params.locations);
		this.default = ko_helper.safe_observable(params.default);
		this.chart_names = ko_helper.safe_observable(params.chart_names);
		this.chart_name = ko.observable();
		this.selected_location = ko.observable();        
		this.date_start = ko.observable(new moment().startOf('month').subtract(5,'months').format('YYYY-MM-DD'));
		this.date_end = ko.observable(new moment().startOf('month').add(1, 'months').format('YYYY-MM-DD'));

		this.params = ko.computed(() => {
			if (this.selected_location() && this.default())
			{
				let filters = {
					filter: [
						{field: 'date_start', 'operator': '>=', value: this.date_start()},
						{field: 'date_start', 'operator': '<', value: this.date_end()},
						{field: this.default().location_table, 'operator': '=', value:  this.selected_location()?.name}
					],
					filter_join: 'AND'
				};

				if (this.order_type() != "")
					filters.filter.push({field: 'order_type', 'operator': '=', value: this.order_type()});

				this.filter(filters);
				this.chart_name(this.chart_names()[0].name);
			}
		});

		this.locations.subscribe((data) => {
			this.selected_location(data[0]);
		});
	}

	view_graph_btn_click (chart_name)
	{
		if (chart_name() === 'ClientOrdersMonth' || chart_name() === 'ClientOrdersWeek')
			Grape.dialog.open('ViewStockGraphDialog', { type: 'purchase_orders' });
		else if (chart_name() === 'SalesOrdersMonth' || chart_name() === 'SalesOrdersWeek')
			Grape.dialog.open('ViewStockGraphDialog', { type: 'sales_orders' });
		else if (chart_name() === 'InventoryLevelsMonth' || chart_name() === 'InventoryLevelsWeek')
			Grape.dialog.open('ViewStockGraphDialog', { type: 'stock_levels' });
	}
}

export default {
	name: 'stock-graph-panel',
	viewModel: StockGraphPanelComponentViewModel,
	module_type: 'ko',
	template: template
}
