import template from './stock_groups.html';

class StockGroupListViewModel
{
	constructor (page)
	{
		this.page = page;
		document.title = 'Stock Groups';
		this.records = ko.observableArray([]);
		this.stock_groups = ko.observableArray([]);
	}

	get_text_color (background_color = 1)
	{
		if (!background_color)
			return "white";

		let color = this.getBrightness(background_color);

		if (color > 128)
			return "black";
		else 
			return "white";
	}

	getBrightness(color) 
	{
		color = color.slice(1);

		let r = parseInt(color.substring(0, 2), 16);
		let g = parseInt(color.substring(2, 4), 16);
		let b = parseInt(color.substring(4, 6), 16);

		return 0.2126 * r + 0.7152 * g + 0.0722 * b;
	}
}

class StockGroupPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.result_found = ko.observable(true);
		this.viewModel = new StockGroupListViewModel(this);
	}

	async updateData ()
	{
		try 
		{
			let result = await Grape.fetches.getJSON('/api/record', { 
				schema: 'stock', 
				table: 'stock_group', 
				sortfield: 'idx' 
			});

			for (let rec of result.records)
				rec.edit = ko.observable(false);

			ko.mapping.fromJS(result, {}, this.viewModel);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	btn_add_group_click ()
	{
		if ( !ko.toJS( this.viewModel.records ) )
			this.viewModel.records( [] );
	  
		this.viewModel.records.unshift({
			description: ko.observable(''),
			idx: null,
			background_colour: ko.observable(null),
			stock_group_id: null,
			edit: ko.observable(true)
		});
	}

	btn_edit_group_click (value)
	{
		value.edit(true);
	}

	async btn_save_group_click (value)
	{
		try
		{
			value.edit(false);

			let nv = value;
			delete nv.edit;

			let result = await Grape.fetches.postJSON('api/stock-management/stock-group', ko.toJSON(nv));

			if (result.status === 'ERROR')
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', message: error.message, type: 'error' })
			console.error(error.message);
		} finally {
			this.updateData();
		}
	}
}

export default {
	route: '[/]stock/admin/stock-groups',
	page_class: StockGroupPage,
	template: template
};