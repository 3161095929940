import template from './create_partial_delivery.html';

class PartialDeliveryDialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.order = ko.observableArray([]);
		this.delivery_data = ko.observable();

		this.actual_delivery_date = ko.observable();
		this.receive = null;
	}

	populate_delivery_data () 
	{
		let items = this.order().items;

		let deliveryItems = items
		.filter(item => item.delivered_qty() !== null && item.delivered_qty() !== undefined)
		.map(item => {
			return {
				stock_item_id: item.stock_item_id,
				qty: item.delivered_qty(),
			};
		});

		let deliveryObj = {
			order_id: this.order().order_id,
			date_effective: this.actual_delivery_date(), 
			items: deliveryItems,
			receive: this.receive
		};
		this.delivery_data(deliveryObj);
	}
	
	handle_arrow_nav (data, event)
	{
		if (![ 38, 40 ].includes(event.keyCode))
			return true;

		let current_elem = event.target;
		let td = current_elem.closest('td');
		let next_td;

		switch (event.keyCode)
		{
			// Up Arrow
			case 38:
				let tr = td.closest('tr');
				let prev_tr = tr.previousElementSibling;
				if (prev_tr)
				{
					let index = Array.from(td.parentNode.children).indexOf(td);
					next_td = prev_tr.children[index];
				}
			break;

			// Down Arrow
			case 40:
				let next_tr = td.closest('tr').nextElementSibling;
				if (next_tr)
				{
					let index = Array.from(td.parentNode.children).indexOf(td);
					next_td = next_tr.children[index];
				}
			break;
		}

		if (next_td)
		{
			let next_input = next_td.querySelector('input');
			if (next_input)
				next_input.focus();
		}

		event.preventDefault();
		return false;
	}

	check_qty_discrepancies ()
	{
		let discrepancies = [];

		let delivered_total = 0;
		this.order().items.forEach(item => {
			let expected_qty = item.qty;
			let received_qty = parseInt(item.qty_received);
			let sent_qty = parseInt(item.qty_sent);
			let delivered_qty = parseInt(item.delivered_qty());

			if (isNaN(delivered_qty))
				delivered_qty = 0;
	
			if (isNaN(received_qty))
				received_qty = 0;

			if (isNaN(sent_qty))
				sent_qty = 0;
	
			let outstanding_qty = 0;

			if (this.receive)
				outstanding_qty = expected_qty - received_qty;
			else
				outstanding_qty = expected_qty - sent_qty;

			if (expected_qty < 0 || delivered_qty < 0)
				throw new Error('Quantities entered should not be negative.');
	
			delivered_total += parseInt(item.delivered_qty());

			if (delivered_qty !== outstanding_qty)
				discrepancies.push({
					description: item.description,
					expected_qty,
					received_qty,
					delivered_qty,
					outstanding_qty
				});
		});
		
		if (delivered_total <= 0)
			throw new Error('No items delivered.');
	
		return discrepancies;
	}
}

class PartialDeliveryDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new PartialDeliveryDialogViewModel(this);
		this.viewModel.order(bindings.order);
		this.viewModel.receive = bindings.receive;

		this.viewModel.order().items.forEach(item => {
			item.delivered_qty = ko.observable();
			item.actual_delivery_date = ko.observable();
		});

		this.viewModel.actual_delivery_date(moment(new Date()).format('YYYY-MM-DD'));
	}

	async btn_create_click () 
	{
		try
		{
			if (!this.viewModel.actual_delivery_date()) {
				Grape.alerts.alert({
					title: 'Error', 
					type: 'error', 
					message: 'Please fill in the delivery date before creating a delivery.'
				});
				return;
			}
			this.viewModel.populate_delivery_data();
			let deliveryData = this.viewModel.delivery_data();
			
			if (!this.viewModel.receive)
			{
				// Changes the reference-based object, will have to repopulate before creating the transaction
				let deliveryItems = deliveryData.items;
				if (!this.viewModel.receive)
					deliveryItems.forEach(item => item.qty = -parseInt(item.qty) );

				let transaction_verified = await window.Grape.StockUtils.verify_loc_stock_levels(this.viewModel.order().source_location, this.viewModel.actual_delivery_date(), deliveryItems);

				if (!transaction_verified)
				{
					let response = await Grape.alerts.confirm({
						type: 'error', 
						message: `This movement will cause ${this.viewModel.order().source_location}'s stock levels drop below 0, are you sure you want to continue?`, 
						title: 'Stock levels below 0'
					})

					if (!response)
						return;
				}
			}

			let discrepancies = this.viewModel.check_qty_discrepancies();
			if (discrepancies.error)
				throw new Error(discrepancies.error);

			if (discrepancies && discrepancies.length > 0)
			{
				let tableHTML = '<table class="ps-table"><thead><tr><th>Description</th><th>Expected</th><th>Received</th><th>Outstanding</th><th>Delivery</th><th>Difference</th></tr></thead><tbody>';
		
				discrepancies.forEach(d => {
					let difference = d.outstanding_qty - d.delivered_qty;
					tableHTML += 
						`<tr>
							<td>${d.description}</td>
							<td>${d.expected_qty}</td>
							<td>${d.received_qty}</td>
							<td>${d.outstanding_qty}</td>
							<td>${d.delivered_qty}</td>
							<td>${difference}</td>
						</tr>`;
				});
		
				tableHTML += '</tbody></table>';
		
				let confirm_message = `<h3 style="margin-bottom: 1em;">There are discrepancies in these items:</h3><br>${tableHTML}`;
				let response = await Grape.dialog.open('DiscrepancyConfirmation', { confirm_message: confirm_message });
		
				if (!response)
					return;
			}
			this.viewModel.populate_delivery_data();

			// Repopulate the delivery_data object
			deliveryData = this.viewModel.delivery_data();
			let result = await Grape.fetches.postJSON('/api/stock-management/order-transaction', deliveryData);

			if (result.status == 'OK') 
			{
				if (result.order_transaction_id) 
				{
					let commit = await Grape.fetches.postJSON('/api/stock-management/order-transaction/commit', { order_transaction_id: result.order_transaction_id } );

					if (commit.status !== 'OK')
						console.error('Error: ', result.message);
				}
				this.viewModel.delivery_data({});
				this.close(true);
			} else 
				throw new Error(result.message);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	btn_back_click () 
	{
		this.close();
	}
}

export default {
	name: 'PartialDeliveryDialog',
	dialog_class: PartialDeliveryDialog,
	template: template
}