import template from './stock-graph-filter.html';

/**
* @class StockGraphFilterComponentViewModel
* @component name - stock-graph-filter
* @constructor 
* @param {object[]} [available_locations=[]]- optional param passed to the constructor to initialize available_locations
* @param {object[]} selected_location- selected location
* @param {bool} [show_hidden_locations=false]- shows active stock items (false) or all stock items (true)
* @param {text} [title] - title of the dropdown
*/
class StockGraphFilterComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		let initial_start = this.params.date_start;
		let initial_end = this.params.date_end;
		this.date_start_input = ko.observable(initial_start());
		this.date_end_input = ko.observable(initial_end());

		// Date Observables
		this.date_start = ko_helper.safe_observable(params.date_start);
		this.date_end = ko_helper.safe_observable(params.date_end);

		// Chart Names and Location Params
		this.chart_names = ko_helper.safe_observableArray(params.chart_names);
		this.chart_name = ko_helper.safe_observable(params.chart_name);
		this.locations = ko_helper.safe_observableArray(params.locations);
		this.selected_location = ko_helper.safe_observable(params.selected_location);

		this.date_start_input.subscribe((newValue) => {
			if (this.is_valid_date(newValue))
				this.date_start(newValue);
		});

		this.date_end_input.subscribe((newValue) => {
			if (this.is_valid_date(newValue))
				this.date_end(newValue);
		});
	}

	is_valid_date (date_string)
	{
		return moment(date_string, 'YYYY-MM-DD', true).isValid();
	}
}

export default {
	name: 'stock-graph-filter',
	viewModel: StockGraphFilterComponentViewModel,
	module_type: 'ko',
	template: template
}