import template from './stock_item_attributes.html';

class StockAttributesViewModel 
{
	constructor (page) 
	{
		this.page = page;
		this.attributes = ko.observableArray([]);
		this.data_types = ko.observableArray(['text', 'number', 'integer', 'boolean', 'currency']);
	}

	btn_add_attribute_click ()
	{
		let new_attribute = {
			lookup_value_id: null,
			name: '',
			description: '',
			idx: '',
			data_type: '',
			data_unit: '',
			is_new: true,
			is_editing: ko.observable(false)
		};
		this.btn_start_editing_click(new_attribute);
		this.attributes.push(new_attribute);
	}

	btn_start_editing_click (attribute)
	{
		attribute.is_editing(true);
	}

	async btn_finish_editing_click (attribute)
	{
		try {
			if (attribute.is_new)
			{
				await this.create_attribute(attribute);
				delete attribute.is_new;
			}
			else
				await this.update_attribute(ko.toJS(attribute));

			attribute.is_editing(false);

			await this.page.updateData();
		} catch (error) {
			console.error('Error updating attribute:', error);
		}
	}

	async btn_delete_attribute_click (attribute)
	{
		let answer = await Grape.alerts.confirm({type: 'danger', title: 'Delete Attribute', message: 'Are you sure you want to delete this attribute?'});
		
		if (!answer)
			return null;
		else 
		{
			let lookup_value_id = attribute.lookup_value_id;
			let response = await Grape.fetches.postJSON('/api/stock-management/stock-item-attribute/remove', {lookup_value_id: lookup_value_id});

			if (response.status === 'OK')
				await this.page.updateData();
			else
				console.error('Error deleting attribute:', response.message);
		}
	}

	async create_attribute (attribute)
	{
		try {
			let new_attribute_data = {
				name: attribute.name,
				description: attribute.description,
				idx: attribute.idx,
				data: JSON.stringify({type: attribute.data_type, unit: attribute.data_unit})
			};

			let response = await Grape.fetches.postJSON('/api/stock-management/stock-item-attribute/create', new_attribute_data);

		} catch (error) {
			console.error('Error creating attribute:', error);
		}
	}

	async update_attribute (attribute)
	{
		try {
			let updated_attribute_data = {
				lookup_value_id: attribute.lookup_value_id,
				name: attribute.name,
				description: attribute.description,
				idx: attribute.idx,
				data: JSON.stringify({type: attribute.data_type, unit: attribute.data_unit})
			};

			let response = await Grape.fetches.postJSON('/api/stock-management/stock-item-attribute/update', updated_attribute_data);

		} catch (error) {
			console.error('Error updating attribute:', error);
		}
	}
}

class StockAttributesPage 
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new StockAttributesViewModel(this);
		document.title = 'Stock Attributes';
	}

	async init ()
	{
		await this.updateData();
	}

	async updateData ()
	{
		try {
			let response = await Grape.fetches.getJSON('/api/stock-management/stock-item-attribute');

			let attributes = response.records.map(record => {
				let data = record.data;
				return {
					lookup_value_id: record.lookup_value_id,
					name: record.name,
					description: record.description,
					idx: record.idx,
					data_type: data.type || '',
					data_unit: data.unit || '',
					is_editing: ko.observable(false)
				}
			});
			this.viewModel.attributes(attributes);

		} catch (error) {
			console.error('Error fetching attributes:', error);
		}
	}

	can_confirm ()
	{
		return Grape.currentSession.roles.includes('stock.manager');
	}
}

export default {                
	route: '[/]stock/admin/item-attributes',
	page_class: StockAttributesPage,
	template: template              
}
