import template from './si-search-dropdown.html';

/**
* @class SearchDropdownComponentViewModel
* @constructor 
* @param {object} selected_item- list of selected stock items
* @param {bool} [show_hidden_items=false] - shows active stock items (false) or all stock items (true)
* @param {text} [title] - title of the dropdown
*/
class SearchDropdownComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_items = ko.observableArray();
		this.filtered_items = ko.observableArray([]);
		this.selected_item = ko_helper.safe_observable(params.selected_item || '');
		this.show_hidden_items = ko.observable(params.show_hidden_items || false);
		this.title = ko.observable(params.title || 'Select item...');
		this.searchText = ko.observable();

		this.searchText.subscribe((newValue) => this.search(newValue));
		this.available_items.subscribe(() => this.search(''));

		this.init();
	}

	async init ()
	{
		let stock_items;

		if (this.show_hidden_items())
			stock_items = await Grape.cache.fetch('StockItems')
		else
			stock_items = await Grape.cache.fetch('ActiveStockItems')

		this.available_items(stock_items)
	}

	search (newValue)
	{	
		let searchTerm = newValue.toLowerCase();
		let items = this.available_items();
		let matchedItems = items.filter(item =>item.description.toLowerCase().includes(searchTerm));

		this.filtered_items(matchedItems);
	}

	item_click (row)
	{
		this.selected_item(row);
	}
}

export default {
	name: 'si-search-dropdown',
	viewModel: SearchDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}