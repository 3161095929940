import template from './si_files_page.html';

class StockItemFilesPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.stock_item_id = ko.observable();
		this.stock_info = ko.observable();
		this.stock_files_location = ko.observable('');
		this.item_files = ko.observableArray([]);
	}

	async btn_upload_doc_click (event)
	{
		let file_input = document.getElementById('ps-file-upload-input');
		let files = file_input.files;

		if (files.length > 0)
		{
			for (let i = 0; i < files.length; i++)
			{
				let file = files[i];
				let full_path = `${this.stock_files_location()}/${file.name}`;

				let response = await fetch(full_path, {
					method: 'PUT',
					headers: {
						'content-type': file.type
					},
					body: file
				});

				if (!response.ok)
					Grape.alerts.alert({type: 'warning', title:'Warning', message: `"File upload failed for", ${file.name}`});
			}

			Grape.alerts.alert({type: 'success', title: 'Success', message: 'Document(s) successfully uploaded!'});
			this.page.updateData();
		} 
		else
			Grape.alerts.alert({type: 'info', title: 'No File', message: 'No files selected!'});
	}

	async btn_delete_file_click (file) 
	{
		let response = await Grape.alerts.confirm({
			message: 'Are you sure you want to delete this file?',
			title: 'Delete File',
			type: 'danger'
		})

		if (response)
		{
			let response = await fetch(file.full_path, {
				method: 'DELETE',
				headers: {
					'content-type': 'application/json'
				},
				body: []
			});

			if (response.ok)
			{
				Grape.alerts.alert({type: 'success', title: 'Success', message: 'Document successfully deleted!'});
				this.page.updateData();
			}
			else
				console.error('File deletion failed', response.status);
		}
	}

	async btn_save_description_click (row)
	{
		let description = row.description();
		let full_path = row.full_path;
	
		let body = `
			<d:propertyupdate xmlns:d='DAV:'>
				<d:set>
					<d:prop>
						<o:description>${description}</o:description>
					</d:prop>
				</d:set>
			</d:propertyupdate>
		`;
	
		let response = await fetch(full_path, {
			method: 'PROPPATCH',
			headers: {
				'Content-Type': 'application/xml'
			},
			body: body
		});
	
		if (response.ok) 
			Grape.alerts.alert({type: 'success', title: 'Success', message: 'Description successfully updated!'});
		else
		{
			Grape.alerts.alert({type: 'warning', title:'Warning', message: 'Failed to update description.'});
			console.error('PROPPATCH failed', response.status);
		}

		row.is_editing_row(false);
	}
}

class StockItemFilesPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new StockItemFilesPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
		this.loading = ko.observable(false);

		this.filter = [{
			field: 'stock_item_id', 
			value: this.viewModel.stock_item_id(), 
			operand: '='
		}];
	}

	async init ()
	{
		document.title = 'Item Files';
	}

	async updateData ()
	{
		this.loading(true);
		this.viewModel.item_files([]);

		// Stock Item Info
		let stock_item_result = await Grape.fetches.getJSON('/api/record', 
		{
			table: 'v_stock_item',
			schema: 'stock',
			sortorder: 'DESC',
			fields: ['stock_item', 'description', 'attributes', 'stock_item_id'],
			filter: this.filter
		});

		if (stock_item_result.status == 'OK')
		{
			this.viewModel.stock_info(stock_item_result.records);
			let files_location = this.viewModel.stock_info()[0].attributes.find(attr => attr['files-location']);

			if (files_location)
				this.viewModel.stock_files_location(files_location['files-location']);
		}

		// Stock Item Files
		let file_result = await Grape.fetches.fetchJSON(this.viewModel.stock_files_location(),
		{
			headers: {'content-type': 'application/json'}, 
			method: 'PROPFIND', 
			body: JSON.stringify([])
		});
		file_result.shift();

		for (let file of file_result)
		{
			let file_info = {
				full_path: file.full_path,
				file_name: '',
				user: '',
				date: '',
				description: ko.observable(''),
				is_editing_row: ko.observable(false)
			};

			for (let prop of file.properties)
				if (prop.name === 'displayname')
					file_info.file_name = prop.value;
				else if (prop.name === 'creator-displayname')
					file_info.user = prop.value;
				else if (prop.name === 'creationdate')
					file_info.date = prop.value;
				else if (prop.name === 'description')
					file_info.description(prop.value);

			this.viewModel.item_files.push(file_info);
		}
		this.loading(false);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_files',
	page_id: 'si_files',
	page_class: StockItemFilesPage,
	template: template
};