import template from './si_orders_page.html';

class StockItemOrdersPageVM
{
	constructor (page)
	{
		this.page = page;
		this.loaded = ko.observable(false);
		this.stock_item_id = ko.observable();
		this.stock_orders = ko.observableArray();
		this.locations = ko.observableArray([]);
		this.selected_from_locations = ko.observableArray([]);
		this.selected_to_locations = ko.observableArray([]);

		this.selected_from_locations.subscribe(() => this.page.updateData() );
		this.selected_to_locations.subscribe(() => this.page.updateData() );
	}
}

class StockItemOrdersPage
{
	constructor (bindings)
	{
		this.viewModel = new StockItemOrdersPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Item Orders';
		let locations = await Grape.cache.fetch('Locations')
		this.viewModel.locations(locations);
	}

	async updateData ()
	{
		let filters = [
			{
				field: 'stock_item_id', 
				value: this.viewModel.stock_item_id(), 
				operand: '='
			}
		];
	
		if (this.viewModel.selected_from_locations().length > 0)
			filters.push({
				field: 'source_location_id',
				value: this.viewModel.selected_from_locations().map(loc => loc.location_id),
				operand: 'IN'
			});
	
		if (this.viewModel.selected_to_locations().length > 0)
			filters.push({
				field: 'target_location_id', 
				value: this.viewModel.selected_to_locations().map(loc => loc.location_id), 
				operand: 'IN'
			});
	
		let result = await Grape.fetches.getJSON('/api/record', 
		{
			table: 'v_order_items', 
			schema: 'stock', 
			sortfield: 'order_nr', 
			sortorder: 'DESC', 
			filter: filters
		});
	
		if (result.status == 'OK')
			this.viewModel.stock_orders(result.records);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_orders',
	page_id: 'si_orders',
	page_class: StockItemOrdersPage,
	template: template
};