import template from './stock_levels.html';

class StockLevelsViewModel
{
	constructor (page)
	{
		this.page = page;
		this.stock_levels = ko.observableArray([]);
		this.locations = ko.observableArray();
		this.selected_location = ko.observableArray();
		this.child_locations = ko.observableArray([]);
		this.search_string = ko.observable('');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.date_effective = ko.observable(new Date().toISOString().slice(0, 10));
		this.stock_tags = ko.observableArray([]);
		this.selected_tags = ko.observableArray([]);
		this.filter_params = ko.observable();
		this.page_initialized = false;
		this.busy_loading = ko.observable(true);

		//sort bindings
		this.sortField = ko.observable('stock_item_description');
		this.sortOrder = ko.observable('ASC');

		this.selected_location.subscribe((selected) => {
			if (selected.length > 0)
				localStorage.setItem('stock_levels_last_location_name', selected[0].name);

			if (this.selected_location().length === 0)
			{
				this.stock_levels([]);
				this.child_locations([]);
			}	
			else
			{
				if (this.page_initialized)
					this.page.updateData();
				if (selected.length > 0)
					this.page.filter_child_locations(selected[0].name);
			}
		});

		this.date_effective.subscribe(() => this.page.updateData() );
		this.selected_tags.subscribe(() => this.page.updateData() );
	}

	btn_page_click (page_number)	
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click () 
	{
		this.page.updateData();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.updateData();
	}

	async download_levels_click ()
	{ 
		let params = this.filter_params();
		let filename = `Stock Levels ${this.date_effective()}`;
		params.options.limit = 100000;
		params.options.offset = 0;

		params.headers = [
			{ field: 'stock_item_description', title: 'Stock Item' },
			{ field: 'stock_item_group', title: 'Stock Group' },
			{ field: 'stock_item_tag', title: 'Stock Tag' },
			{ field: 'qty', title: `Location Level (${this.selected_location().map(loc => loc.name).join('| ')})` },
			{ field: 'stock_item_on_order', title: 'On Order' },
			{ field: 'stock_item_committed', title: 'Committed' },
			{ field: 'total_value', title: 'Current Price' }
		];

		let url = await Grape.fetches.buildURL(`download/levels/${filename}/`, params);
		window.open(url.href);
	}
}

class StockLevelsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new StockLevelsViewModel(this);
		this.viewable_locations = [];

		const searchInput = document.getElementById('searchInput');
		searchInput.addEventListener('keyup', (event) => {
			if (event.key === 'Enter')
				this.updateData();
		});
	}

	async init ()
	{
		document.title = 'Stock Levels';
		let locations = await Grape.cache.fetch('ActiveLocations');
		this.viewable_locations = await window.Grape.StockUtils.get_user_locations('ViewStockLevel');

		let filtered_locations = locations.filter(location => location.location_type == 'Internal' && this.is_viewable_location(location.location_id));
		this.viewModel.locations(filtered_locations);

		let last_location_name = localStorage.getItem('stock_levels_last_location_name');
		let last_location = filtered_locations.filter(location => location.name == `${last_location_name}`);
		this.viewModel.selected_location(last_location);

		let stock_tags = await Grape.cache.fetch('StockItemTags');
		this.viewModel.stock_tags(stock_tags.tags);
		this.viewModel.page_initialized = true;
	}

	is_viewable_location (location_id)
	{
		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || (this.viewable_locations.find(loc => loc.location_id == location_id)))
			return true;

		return false;
	}

	async updateData () 
	{
		if (!this.viewModel.selected_location() || this.viewModel.selected_location().length === 0) 
		{
			this.viewModel.stock_levels([]);
			this.viewModel.busy_loading(false);
			return;
		}

		this.viewModel.busy_loading(true);
	
		let options = {
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			sortorder: this.viewModel.sortOrder(),
			sortfield: this.viewModel.sortField(),
			limit: 10000,
			filter: [
				{
					field: 'stock_item_in_use',
					operand: '=',
					value: true
				}
			]
		}

		if (this.viewModel.selected_location() && this.viewModel.locations().length > 0)
		{
			// LOGIC: Search
			if (this.viewModel.search_string() && this.viewModel.search_string() != '')
				options.filter.push(
					{ 
						field: 'stock_item_description', 
						operand: 'ILIKE', 
						value: `%${this.viewModel.search_string()}%` 
					}
				);

			if (this.viewModel.selected_tags().length > 0)
				options.filter.push(
					{
						field: 'stock_item_tag',
						operand: 'ILIKE',
						value: `%${this.viewModel.selected_tags()}%`
					}
				);

			// LOGIC: Pagination
			if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
			{
				options.limit = this.viewModel.current_page_size();
				options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
			}
	
			let params = {
				options: options,
				location_ids: this.viewModel.selected_location().map(loc => loc.location_id),
				date_effective: this.viewModel.date_effective()
			}

			// for csv download
			this.viewModel.filter_params(params);

			try
			{
				let levels = await Grape.fetches.getJSON('/api/stock-management/stock-level/report', params);

				if (levels.status != 'ERROR')
				{
					this.viewModel.stock_levels(levels.records);
					this.viewModel.page_count(Math.ceil(levels.total/levels.limit));
				}
				else
					throw new Error(levels.message || levels.code);
			} catch (error) {
				Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
				console.error(error)
			}
		}
		
		this.viewModel.busy_loading(false);
	}

	filter_child_locations (selected) 
	{
		if (!selected || !this.viewModel.locations()) return [];

		let child_locs = this.viewModel.locations().filter(({parent_names}) => parent_names?.includes(selected));
		let child_locs_names = child_locs.map(({name}) => name).join(', ');

		this.viewModel.child_locations(child_locs_names);
	}
}

export default {
	route: '/stock/stock_levels/',
	page_class: StockLevelsPage,
	template: template
}