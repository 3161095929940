/**
 * Page with sidebar example
 */
import template from './admin-page.html';

class SystemSetupVM
{
	constructor (page) 
	{
		this.page = page;

		this.tabs = ko.observableArray([
		{
			route: '[/]stock/admin/system-attributes',
			title: 'System Attributes',
			icon: 'fad fa-gear'
		},
		{
			route: '[/]stock/admin/item-attributes',
			title: 'Item Attributes',
			icon: 'fad fa-distribute-spacing-vertical'
		},
		{
			route: '[/]stock/admin/order-types',
			title: 'Order Types',
			icon: 'fad fa-bags-shopping'
		},
		{
			route: '[/]stock/admin/stock-groups',
			title: 'Stock Groups',
			icon: 'fad fa-layer-group'
		},
		{
			route: '[/]stock/admin/location-types',
			title: 'Location Types',
			icon: 'fad fa-location-dot'
		}
		]); 
		this.current_route = ko.observable();
	}
	
	load_tab (route)
	{
		window.localStorage.setItem('last_syssidebar_page', route);
		const element = document.querySelector('.admin_page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class SystemSetupPage 
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new SystemSetupVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'SystemSetup';
		if (window.localStorage.getItem('last_syssidebar_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_syssidebar_page'));
		else
			this.viewModel.load_tab('[/]stock/admin/item-attributes');
	}
}

export default {
	route: '[/]stock/admin/system-setup',
	page_class: SystemSetupPage,
	template: template
}
