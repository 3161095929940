import template from './locations_list.html';

class LocationsViewModel
{
	constructor (page)
	{
		document.title = 'Locations';
		this.page = page;

		// pagination 
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(2000);
		this.page_count = ko.observable(1);
		
		//search 
		this.search_string = ko.observable('');

		//filtering
		this.available_tags = ko.observableArray([]);
		this.selected_tags = ko.observableArray([]);

		this.locations = ko.observableArray([]);
		this.locations_formatted = ko.observableArray([]);
		this.grouped_locations = ko.observableArray([]);
		this.display_inactive = ko.observable(false);

		this.selected_tags.subscribe(() => {
			this.current_page_number(1);
			this.page.updateData();
		});
		this.display_inactive.subscribe(() => this.page.updateData() );
		this.current_page_size.subscribe(() => this.page.onload = true );
	}

	view_location_click (data)
	{
		Grape.navigate(`/admin/location/view/${data.location_id}`);
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	search_click ()
	{
		this.page.updateData();
	}
}

class LocationsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new LocationsViewModel(this);
		this.onload = true;

		const searchInput = document.getElementById("searchInput");
		searchInput.addEventListener("keyup", (event) => {
			if (event.key === "Enter")
				this.updateData();
		});
	}

	async updateData ()
	{
		let options = {
			table: 'v_locations',
			schema: 'stock',
			offset: 0,
			limit: this.viewModel.current_page_size(),
			sortorder: 'ASC',
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		options.filter.push({
			field: 'location_type',
			operand: '!=',
			value: 'Supplier'
		});

		if (this.viewModel.selected_tags().length > 0)
		{
			options.filter.push({
				field: 'tags',
				operand: '@>',
				value: this.viewModel.selected_tags()
			});
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter.push({ 
				field: 'name', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.search_string()}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}
 
		try
		{
			if (!this.viewModel.display_inactive())
			{
				options.filter.push({
					field: 'in_use', 
					operand: '=', 
					value: 'true'
				});
			}

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				this.viewModel.locations(result.records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);

				let locations_formatted = window.Grape.StockUtils.format_locations_list(result.records);
				this.viewModel.locations_formatted(locations_formatted);

				let grouped_locations = [];
				let location_types = [...new Set(locations_formatted.map(loc => loc.location_type))];

				location_types.forEach(type => {
					if (type !== 'Supplier')
					{
						grouped_locations.push({
							location_type: type + ' Locations',
							locations: ko.observableArray(locations_formatted.filter(loc => loc.location_type === type))
						});
					}
				});

				this.viewModel.grouped_locations(grouped_locations);

				if (this.onload)
				{
					let tags = [];

					result.records.forEach((row) =>
					{
						if (row.tags)
						{
							for (let tag of row.tags)
							{
								let tags_found = tags.some((loc_tag) => {
									return loc_tag === tag;
								});

								if (!tags_found)
									tags.push(tag);
							}
						}
					});
					this.viewModel.available_tags(tags);
				}
				this.onload = false;
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async add_location ()
	{
		await Grape.dialog.open('EditLocation', { location_id: null });
		this.updateData();
	}

	async edit_location (value)
	{
		let loc = ko.toJS(value);
		await Grape.dialog.open('EditLocation', { location_id: loc.location_id });
		this.updateData();
	}
}

export default {
	route: '[/]admin/locations/list',
	page_class: LocationsPage,
	template: template
}