// CACHES
import StockItemTags from './stock-item-tags.js';
import StockUsers from './stock-users.js';
import UserLocations from './user-locations.js';
import CreateOrderLocations from './create-order-locations.js';
import Locations from './locations.js';
import OrderTypes from './order-types.js';
import OrderStatuses from './order-statuses.js';
import MovementTypes from './movement-types.js';
import StockItems from './stock-items.js';
import ActiveStockItems from './active-stock-items.js';
import ActiveLocations from './active-locations.js';
import UOM from './uom.js';
import StockGroups from './stock-groups.js';

export default [
	UOM,
	ActiveLocations,
	ActiveStockItems,
	StockItems,
	StockGroups,
	MovementTypes,
	StockItemTags,
	UserLocations,
	CreateOrderLocations,
	Locations,
	OrderTypes,
	OrderStatuses,
	MovementTypes,
	StockUsers
];
