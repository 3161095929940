import template from './batch-import.html';

class BatchImportViewModel
{
	constructor(page)
	{
		this.page = page;
		this.loading = ko.observable(true);

		this.locations = ko.observableArray([]);
		this.selected_location = ko.observable();
		this.selected_from_date = ko.observable();
		this.delete_visible = ko.observable(false);

		//sort bindings
		this.sortField = ko.observable('date_inserted');
		this.sortOrder = ko.observable('DESC');

		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(100);
		this.page_count = ko.observable(1);
		this.pagination_summary = ko.observable('');
		this.number_of_records = ko.observableArray([20, 40, 100, 200, 300]);
		this.selected_number_of_records = ko.observable(100);

		this.sheets = ko.observableArray([]);
		this.creating_batch = ko.observable(false);
		this.workbook_ids = ko.observableArray([]);

		this.selected_from_date.subscribe(() => this.page.updateData() );

		this.selected_number_of_records.subscribe((newVal) => {
			localStorage.setItem('selected_number_of_records_imports', this.selected_number_of_records());
			this.current_page_size(newVal);
			this.page.updateData();
		});
	}

	btnEdit_click ()
	{
		this.delete_visible(!this.delete_visible());
	}
	
	async btnDelete_click (row)
	{
		let confirm = await Grape.alerts.confirm({
			message: 'Are you sure you want to remove this batch import, and cancel all linked sales orders & batched sales orders?',
			title: 'Warning',
			type: 'warning'
		});

		if (confirm)
		{
			let response = await Grape.fetches.postJSON('/api/stock-management/batch-delete', {workbook_id: row.workbook_id});

			if (response.status === 'OK')
			{
				this.page.updateData();
				Grape.alerts.alert({ title: 'Success', type:'success', message: 'Batch import successfully removed!' })
			}
			else
				Grape.alerts.alert({ title: 'Error', type: 'error', message: 'Batch import failed to remove!' });
		}
	}

	async btnUpload_click ()
	{
		let input_element = document.body.getElementsByClassName('file-upload-input')[0];
		if (input_element.files.length > 0 )
		{
			let form_data = new FormData();
			for (let file of input_element.files)
				form_data.append('file', file);
			let response = await fetch('/api/sheets/workbook-upload', {method: 'POST', body: form_data});
			let data = await response.text();

			this.sortField('date_inserted');
			this.sortOrder('DESC');
			this.current_page_number(1);
			this.page.updateData();

			input_element.value = null;
			this.loading(false);
		}
		else
		{
			Grape.alerts.alert({ title: 'Error', type: 'error', message: 'Please select some files for upload' });
		}
	}

	async btnRefresh_click()
	{
		this.loading(true);
		await this.page.updateData();
		this.loading(false);
	}

	async btn_process_batches_click()
	{
		let workbook_ids = this.workbook_ids();
		let location_name = this.selected_location().location_name;

		if (workbook_ids.length == 0)
		{
			Grape.alerts.alert({ title: 'Error', type: 'warning', message: 'Please select some files for upload' });
			return;
		}

		try
		{
			let result = await Grape.fetches.postJSON('/api/stock-management/batch-process', {workbook_ids: workbook_ids, source_location: location_name});
			if (result.status != 'ERROR')
			{
				await Grape.alerts.alert({ title: 'Started', type: 'success', message: 'Batch process started' });
				this.page.updateData();
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			this.workbook_ids([]);
		}
	}

	page_click (page_number)	
	{
		this.loading(true);
		this.current_page_number(page_number);
		this.page.updateData();
		this.loading(false);
	}

	sortClick (field)
	{
		this.loading(true);
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.updateData();
		this.loading(false);
	}
}

class BatchImportPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BatchImportViewModel(this);

		this.refresh_timeout = null;
		this.refresh_timeout_duration = 1000;

		let selected_number_of_records_imports = localStorage.getItem('selected_number_of_records_imports');
		if (selected_number_of_records_imports)
		{
			this.viewModel.selected_number_of_records(selected_number_of_records_imports);
			this.viewModel.current_page_size(selected_number_of_records_imports);
		}
	}

	async init ()
	{
		document.title = 'Import Batches';

		this.updateData();
		this.viewModel.loading(false);

		let locations = await Grape.cache.fetch('CreateOrderLocations');
		this.viewModel.locations(locations);
	}

	async updateData()
	{
		clearTimeout(this.refresh_timeout);

		let options = {
			schema: 'stock',
			table: 'v_batch_imports',
			offset: 0,
			sortorder: this.viewModel.sortOrder(),
			sortfield: this.viewModel.sortField(),
			filter: []
		};

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		// LOGIC: Filter
		if (this.viewModel.selected_from_date())
		options.filter.push(
		{
			field: 'date_inserted',
			operand: '>',
			value: this.viewModel.selected_from_date()
		}, {
			field: 'date_inserted',
            operand: '<',
            value: moment(this.viewModel.selected_from_date()).add(1, 'days').format('YYYY-MM-DD')
		});
 
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				let has_pending = false;
				result.records.forEach((rec) => {
					if (rec.sched_te === null	// Busy with Import of Workbook
						|| rec.recipient_import?.wsi_sched_ts && rec.recipient_import?.wsi_sched_te === null // Busy with recipient_import's worksheet 2 batch
						|| rec.recipient_import?.bp_sched_ts && rec.recipient_import?.bp_sched_te === null // Busy with recipient_import's worksheet's pipeline
						|| rec.product_import?.wsi_sched_ts && rec.product_import?.wsi_sched_te === null // Busy with product_import's worksheet 2 batch
						|| rec.product_import?.bp_sched_ts && rec.product_import?.bp_sched_te === null) // Busy with product_import's worksheet's pipeline
					{
						has_pending = true;
					}
					
					if (rec.sched_te && rec.recipient_import?.wsi_sched_ts === null)
						rec.processable = true;
					else
						rec.processable = false;

					rec.batch_status = rec.sched_status;
					if (rec.sched_te)
					{
						rec.batch_status = rec.batch_status + ' ' + moment(rec.sched_te).format(window.Grape.config.date_time_format);
					}
					else if (rec.sched_ts)
					{
						rec.batch_status = rec.batch_status + ' ' + moment(rec.sched_ts).format(window.Grape.config.date_time_format);
					}

					rec.recipient_status = '';
					rec.recipient_count = '';
					if (rec.recipient_import)
					{
						if (rec.recipient_import.bp_sched_te)
						{
							rec.recipient_status = rec.recipient_import.batch_state + ' ' + moment(rec.recipient_import.bp_sched_te).format(window.Grape.config.date_time_format);
						}
						else if (rec.recipient_import.wsi_sched_ts)
						{
							rec.recipient_status = rec.recipient_import.batch_state + ' ' + moment(rec.recipient_import.wsi_sched_ts).format(window.Grape.config.date_time_format);
						}

						rec.recipient_count = rec.recipient_import.rows - 1;
					}

					rec.product_status = '';
					if (rec.product_import)
					{
						if (rec.product_import.bp_sched_te)
						{
							rec.product_status = rec.product_import.batch_state + ' ' + moment(rec.product_import.bp_sched_te).format(window.Grape.config.date_time_format);
						}
						else if (rec.product_import.wsi_sched_ts)
						{
							rec.product_status = rec.product_import.batch_state + ' ' + moment(rec.product_import.wsi_sched_ts).format(window.Grape.config.date_time_format);
						}
					}
				});

				if (has_pending)
				{
					this.refresh_timeout = setTimeout(async () => {await this.updateData()}, this.refresh_timeout_duration);
				}

				this.viewModel.sheets(result.records);
				this.viewModel.page_count(Math.ceil(result.total/result.limit));
				this.viewModel.pagination_summary(`Showing ${options.offset} - ${this.viewModel.current_page_number() * this.viewModel.current_page_size()} of ${result.total} row(s)`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async teardown()
	{
		clearTimeout(this.refresh_timeout);
	}
}

export default {
	route: '/stock/batch-import/',
	page_class: BatchImportPage,
	template: template
}
