import template from './l_stock_item_page.html';
import LocationsViewAPI from './../location_view_api.js';

class LocationStockItemViewModel
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.location_id = ko.observable(page.bindings.location_id);
		this.location_description = ko.observable(page.bindings.location_description);
		this.location_type = ko.observable(page.bindings.location_type);

		this.stock_item_location_info = ko.observableArray();
		this.current_levels = ko.observableArray([]);
		this.can_edit = ko.observable(false);

		//sort bindings for stock item location info
		this.infoSortField = ko.observable('stock_item');
		this.infoSortOrder = ko.observable('ASC');

		//sort bindings for stock item location levels
		this.lvlSortField = ko.observable('description');
		this.lvlSortOrder = ko.observable('ASC');
	}

	async edit_stock_item_click (row)
	{
		await Grape.dialog.open('EditStockItemLocation', { 
			location: { 
				id: this.location_id(), 
				name: this.location_description()
			}, 
			data: row 
		});
		this.page.updateData();
	}

	lvlSortClick (field)
	{
		this.lvlSortField(field);

		if (this.lvlSortOrder() == 'ASC')
			this.lvlSortOrder('DESC');
		else
			this.lvlSortOrder('ASC');

		this.page.updateLevels();
	}

	infoSortClick (field)
	{
		this.infoSortField(field);

		if (this.infoSortOrder() == 'ASC')
			this.infoSortOrder('DESC');
		else
			this.infoSortOrder('ASC');

		this.page.updateInfo();
	}
}

class LocationStockItemPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new LocationStockItemViewModel(this);
		this.LocationsViewAPI = new LocationsViewAPI(this.viewModel.location_id());
	}

	async init ()
	{
		document.title = 'Sidebar - StockItem';

		let editable_locations = await window.Grape.StockUtils.get_user_locations('EditLocation');
		let editable = false;
		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || editable_locations.find(loc => loc.location_name == this.viewModel.location_description()))
			editable = true;

		this.viewModel.can_edit(editable);
	}

	async updateData ()
	{
		await this.updateInfo();
		await this.updateLevels();
	}

	async updateInfo ()
	{
		//stock item location info
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'stock',
			table: 'v_location_stock_item_info',
			sortfield: this.viewModel.infoSortField(),
			sortorder: this.viewModel.infoSortOrder(),
			filter: [
				{
					field: 'stock_item_in_use',
					operand: '=',
					value: true
				},
				{
					field: 'location_id',
					operand: '=',
					value: this.viewModel.location_id()
				}
			]
		});

		this.viewModel.stock_item_location_info(result.records);
	}

	async updateLevels ()
	{
		//current_levels
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'stock',
			table: 'v_location_current_levels',
			sortfield: this.viewModel.lvlSortField(),
			sortorder: this.viewModel.lvlSortOrder(),
			filter: [
				{
					field: 'in_use',
					operand: '=',
					value: true
				},
				{
					field: 'location_id',
					operand: '=',
					value: this.viewModel.location_id()
				}
			]
		});

		this.viewModel.current_levels(result.records);
	}
}

export default {
	route: '[/location/]l_stock_items',
	page_id: 'l_stock_items',
	page_class: LocationStockItemPage,
	template: template
};