import template from './workstation-dropdown.html';

/**
* @class WorkstationSearchDropdownComponentViewModel
* @constructor 
* @param {object} selected_item- selected work station
* @param {text} [title] - title of the dropdown
*/
class WorkstationDropdownComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_items = ko.observableArray([]);
		this.filtered_items = ko.observableArray([]);
		this.selected_item = ko_helper.safe_observable(params.selected_item || null);

		this.title = ko_helper.safe_observable(params.title || 'Select/Search Workstations...');
		this.search_text = ko.observable('');

		this.filtered_items = ko.computed(() => {
			let low_case_text = this.search_text().toLowerCase();

			if (!this.available_items() || this.available_items().length === 0)
				return [];

			let matched_items = this.available_items().filter(item =>
				item.name.toLowerCase().includes(low_case_text)
			);

			let selected_id = this.selected_item();
			if (selected_id && !matched_items.some(si => si.workstation_id == selected_id))
			{
				let selected_item = this.available_items().find(item => item.workstation_id === selected_id);
				if (selected_item)
					matched_items.push(selected_item);
			}

			return matched_items;
		});

		this.init();
	}

	async init ()
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: []
			});
		
			if (result.status != 'ERROR')
			{
				this.available_items(result.records)

				let selected_id = Number(this.selected_item());
				if (selected_id)
				{
					let selected_item = result.records.find(item => item.workstation_id === selected_id);
					if (selected_item)
						this.title(selected_item.name);
					else
						this.selected_item(null);
				}
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	item_click (row, e)
	{
		e.stopPropagation();
		this.selected_item(row.workstation_id);
		this.title(row.name);
		return true;
	}

	is_selected (item) 
	{
		return this.selected_item() === item.workstation_id;
	}
}

export default {
	name: 'workstation-dropdown',
	viewModel: WorkstationDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}