import template from './stock_take_capture.html';

class StockTakeCaptureViewModel
{
	constructor (page)
	{
		this.page = page;
		this.stock_take_id = ko.observable();
		this.type = ko.observable();

		this.is_editing = ko.observable(Boolean(this.page.bindings.type == 'edit'));
		this.is_viewing = ko.observable(Boolean(this.page.bindings.type == 'view'));
		this.is_confirmed = ko.observable(false);

		this.total_value = ko.observable();
		this.date_inserted = ko.observable();
		this.user_inserted = ko.observable();
		this.date_confirmed = ko.observable();
		this.user_confirmed = ko.observable();

		this.stock_take_date = ko.observable();
		this.locations = ko.observableArray();
		this.all_locations = ko.observableArray();
		this.selected_location = ko.observable();
		this.selected_location_name = ko.observable();
		this.items = ko.observableArray();

		this.selected_location.subscribe((selected_location) => {
			if (selected_location)
				this.selected_location_name(selected_location.name);

			this.page.get_stock_items();
			this.page.update_confirmable();
			this.page.update_editable();
		});

		this.stock_take_date.subscribe(() => {
			this.page.get_stock_items();
			this.page.update_confirmable();
			this.page.update_editable();
		});
		this.can_confirm = ko.observable(false);
		this.can_edit = ko.observable(false);

		this.confirmable_locations = [];
		this.creatable_locations = [];
	}

	btn_edit_click ()
	{
		let stock_take_id = this.stock_take_id();
		Grape.navigate(`/stock/stock_take/edit/${stock_take_id}`);
	}

	async btn_save_click ()
	{
		if (!this.selected_location() || !this.stock_take_date())
		{
			Grape.alerts.alert({
				title: 'Error', 
				type: 'error', 
				message: 'Please select a Location and Stock Take Date before saving.'
			});
			return;
		}
		let items = [];
		this.items().forEach((item) => {
			if (item.qty_captured())
				items.push({
					stock_item_id: item.stock_item_id,
					qty_captured: item.qty_captured(),
					note: item.note()
				});
		});

		if (items.length == 0)
		{
			Grape.alerts.alert({
				title: 'Error', 
				type: 'error', 
				message: 'Please enter some amounts for the stock items before saving.'
			});
			return;
		}

		let stock_take = {
			stock_take_date: this.stock_take_date(),
			location: this.selected_location().name,
			items: items
		};

		if (this.stock_take_id())
			stock_take.stock_take_id = this.stock_take_id();

		try 
		{
			let result = await Grape.fetches.postJSON('/api/stock-management/stock-take', stock_take);

			if (result.status == 'OK')
			{
				let stock_take_id = result.stock_take_id;
				await Grape.alerts.alert({ type: 'success', message: 'Stock take saved', title: 'Success' });
				Grape.navigate(`/stock/stock_take/view/${stock_take_id}`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async btn_confirm_click()
	{
		let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to confirm this stock take?', title: 'Confirm Stock Take', type: 'info' });
		if (confirm)
		{
			try 
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/stock-take/confirm', { stock_take_id: this.stock_take_id() });
				if (result.status == 'OK')
					this.page.updateData();
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error);
			}
		}
	}

	async btn_delete_click ()
	{
		let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to remove this stock take?', title: 'Remove Stock Take', type: 'warning' });

		if (confirm)
		{
			try 
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/stock-take/remove', { stock_take_id: this.stock_take_id() });

				if (result.status == 'OK')
					Grape.navigate('/stock/stock_take/');
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error);
			}
		}
	}

	btn_cancel_click ()
	{
		Grape.navigate('/stock/stock_take/');
	}

	btn_download_variance_click ()
	{
		let stock_take_id = this.stock_take_id();
		window.open(`/api/stock-management/stock-take/variance?stock_take_id=${stock_take_id}`);
	}

	btn_download_summary_click ()
	{
		let stock_take_id = this.stock_take_id();
		window.open(`/api/stock-management/stock-take/summary?stock_take_id=${stock_take_id}`);
	}

	async upload_csv (event)
	{
		if (this.selected_location())
		{
			let file = event.target.files[0];
			if (!file) return;

			let reader = new FileReader();
			reader.onload = async (e) => {
				let has_error = false;
				try 
				{
					let content = e.target.result;

					// Skip the header row
					let rows = content.split('\n').slice(1);

					// Create a map for lookups
					let itemMap = new Map();
					this.items().forEach(item => itemMap.set(item.stock_item_description, item) );

					let unmatchedItems = [];

					for (let i = 0; i < rows.length; i++)
					{
						let row = rows[i];
						if (row.trim() === "") continue;

						let cells = row.split(',');

						let [description, qty, note] = cells;

						// Use the map to update the item
						let item = itemMap.get(description);
						if (item)
						{
							item.qty_captured(qty);
							item.note(note); 
						}
						else
							unmatchedItems.push(i + 2);
					}

					// Show alert if there are unmatched items
					if (unmatchedItems.length > 0)
					{
						Grape.alerts.alert({
							type: 'error', 
							title: 'Error', 
							message: 'File uploaded, but found unmatched stock item found in row(s): ' + unmatchedItems.join(', ')
						});
						has_error = true;
					}
				
				} catch (error) {
					Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
					console.error(error);
					has_error = true;
				}

				if (!has_error)
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Upload complete!' });
			};

			reader.readAsText(file);
		}
		else
			Grape.alerts.alert({ type: 'info', title: 'Info', message: 'First select a location for the stock take' });
	}

	handle_arrow_nav (data, event)
	{
		if (![37, 38, 39, 40].includes(event.keyCode))
			return true;

		let current_elem = event.target;
		let td = current_elem.closest('td');
		let next_td;

		switch (event.keyCode)
		{
			// Left Arrow
			case 37:
				next_td = td;
				while ((next_td = next_td.previousElementSibling))
					if (next_td.querySelector('input'))
						break;
			break;

			// Up Arrow
			case 38:
				let tr = td.closest('tr');
				let prev_tr = tr.previousElementSibling;
				if (prev_tr)
				{
					let index = Array.from(td.parentNode.children).indexOf(td);
					next_td = prev_tr.children[index];
				}
			break;

			// Right Arrow
			case 39:
				next_td = td;
				while ((next_td = next_td.nextElementSibling))
					if (next_td.querySelector('input'))
						break;
			break;

			// Down Arrow
			case 40:
				let next_tr = td.closest('tr').nextElementSibling;
				if (next_tr)
				{
					let index = Array.from(td.parentNode.children).indexOf(td);
					next_td = next_tr.children[index];
				}
			break;
		}

		if (next_td)
		{
			let next_input = next_td.querySelector('input');
			if (next_input)
				next_input.focus();
		}

		event.preventDefault();
		return false;
	}
}

class StockTakeCapturePage
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new StockTakeCaptureViewModel(this);
		this.viewModel.stock_take_id(bindings.stock_take_id);
		this.viewModel.type(bindings.type);
		this.saved_items = [];
		this.initial_load_complete = false;
	}

	async init ()
	{
		document.title = 'Stock Take';

		this.updateData();
		this.viewModel.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmStockTake');
		this.viewModel.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateStockTake');
		let location_cache = await Grape.cache.fetch('ActiveLocations');
		let locations = [];
		location_cache.forEach((loc) => {
			if (loc.location_type == 'Internal'
				&& (
					Grape.currentSession.roles.includes('stock.all-location-permissions')
					|| (this.viewModel.creatable_locations.find(cloc => cloc.location_id == loc.location_id)))
				)
				locations.push(loc);
		});
		this.viewModel.locations(locations);
		this.viewModel.all_locations(location_cache);
	}

	update_confirmable ()
	{
		let confirmable = false;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			confirmable = true;
		else if (this.viewModel.selected_location_name())
		{
			let location_name = this.viewModel.selected_location_name();
			if (this.viewModel.confirmable_locations.find(loc => loc.location_name == location_name))
				confirmable = true;
		}

		this.viewModel.can_confirm(confirmable);
	}

	update_editable ()
	{
		let allowed = false;
		
		if (this.viewModel.selected_location())
		{
			let location_id = this.viewModel.selected_location().location_id;
			if (
				Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_id == location_id))
			)
				allowed = true;
		}

		this.viewModel.can_edit(allowed);
	}

	populate_option_field (options_array, option_value)
	{
		return options_array.find(option => 
			option.type === option_value || 
			option.status === option_value || 
			option.name === option_value
		);
	}

	update_document_title ()
	{
		if (!this.bindings.stock_take_id)
			document.title = 'Stock Take - New';
		else
		{
			let stock_take_id = this.bindings.stock_take_id;
			let action = 'View';
			if (this.viewModel.is_editing())
				action = 'Edit';

			document.title = `Stock Take - ${action} - ${stock_take_id}`;
		}
	}

	async updateData ()
	{
		this.update_document_title();

		if (this.bindings.stock_take_id)
		{
			let stock_take_id = this.viewModel.stock_take_id;
			let result = await Grape.fetches.getJSON('/api/stock-management/stock-take', { stock_take_id: stock_take_id });
			if (result.status == 'OK')
			{
				let stock_take = result.stock_take;
				if (stock_take.date_confirmed)
					this.viewModel.date_confirmed(moment(new Date(stock_take.date_confirmed)).format('YYYY-MM-DD'));
				this.viewModel.user_confirmed(stock_take.user_confirmed);
				this.viewModel.date_inserted(moment(new Date(stock_take.date_inserted)).format('YYYY-MM-DD'));
				this.viewModel.user_inserted(stock_take.user_inserted);
				this.viewModel.total_value(stock_take.total_value);
				this.viewModel.stock_take_date(stock_take.stock_take_date);
				this.viewModel.selected_location(this.populate_option_field(this.viewModel.all_locations(), stock_take.location));
				this.viewModel.selected_location_name(stock_take.location);

				if (stock_take.confirmed)
					this.viewModel.is_confirmed(true);

				this.saved_items = stock_take.items;
			}
			else
				Grape.alerts.alert({ title: 'Error', type: 'error', message: result.message });
		}
	}

	find_saved_item (stock_item_id)
	{
		return this.saved_items.find(option => option.stock_item_id == stock_item_id);
	}

	update_saved_items ()
	{
		if (this.initial_load_complete)
		{
			this.saved_items = [];
			this.viewModel.items().forEach((item) => {
				if (item.qty_captured())
					this.saved_items.push({
						stock_item_id: item.stock_item_id,
						qty_captured: item.qty_captured(),
						qty_expected: item.qty_expected(),
						note: item.note()
					});
			});
		}
	}

	async get_stock_items ()
	{
		if (this.viewModel.selected_location())
		{
			this.update_saved_items();
			let location_id = this.viewModel.selected_location().location_id;

			let options = {
				limit: 10000,
				sortfield: 'stock_item_description',
				filter: []
			}

			if (!this.viewModel.is_confirmed())
				options.filter.push({ field: 'stock_item_in_use', value: true, operand: '=' });

			let requestObj = {
				options: options,
				location_ids: [location_id]
			};

			if (this.viewModel.stock_take_date() && Grape.config.public_settings.stock_take_levels_on_date_effective)
				requestObj.date_effective = this.viewModel.stock_take_date();

			let items = await Grape.fetches.getJSON('/api/stock-management/stock-level/report', requestObj);

			let location_items = [];
			items.records.forEach((item) => {
				item.qty_expected = ko.observable(item.qty);
				item.qty_captured = ko.observable();
				item.current_price = ko.observable(item.current_price);
				item.difference = ko.observable();
				item.difference_value = ko.observable();
				item.value = ko.observable();
				item.note = ko.observable();

				item.qty_captured.subscribe(() => {
					// Calculate difference
					if (item.qty_captured() !== null && item.qty_expected() !== null)
						item.difference(item.qty_captured() - item.qty_expected());
					else
						item.difference(null);

					// Calculate difference value
					if (item.difference() !== null)
						item.difference_value(item.difference() * item.current_price());
					else
						item.difference_value(null);

					// Calculate value
					if (item.qty_captured() !== null)
						item.value(item.qty_captured() * item.current_price());
					else
						item.value(null);
				});

				item.value.subscribe(() => {
					this.update_total_value();
				});

				let saved_item = this.find_saved_item(item.stock_item_id);
				if (saved_item)
				{
					item.qty_captured(saved_item.qty_captured);
					item.note(saved_item.note);
				}

				if (this.viewModel.is_viewing())
				{
					if (saved_item)
					{
						item.qty_expected(saved_item.qty_expected);
						location_items.push(item);
						item.qty_captured(null);
						item.qty_captured(saved_item.qty_captured);
					}
				}
				else
					location_items.push(item);
			});

			this.viewModel.items(location_items);
			this.update_total_value();
			this.initial_load_complete = true;
		}
	}

	update_total_value ()
	{
		let total_value = 0;
		this.viewModel.items().forEach((item) => {
			if (item.value())
				total_value += item.value();
		});

		this.viewModel.total_value(total_value);
	}
}

export default {
	route: '/stock/stock_take/:type/:stock_take_id',
	page_class: StockTakeCapturePage,
	template: template
}
