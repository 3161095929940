import template from './l-search-dropdown.html';

/**
* @class SearchDropdownComponentViewModel
* @component name - l-search-dropdown
* @constructor 
* @param {object[]} [available_locations=[]]- optional param passed to the constructor to initialize available_locations
* @param {object[]} selected_location- selected location
* @param {bool} [show_hidden_locations=false]- shows active stock items (false) or all stock items (true)
* @param {text} [title] - title of the dropdown
*/
class SearchDropdownComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_locations = ko.observableArray();
		this.param_locations = ko_helper.safe_observableArray(params.available_locations);
		this.filtered_locations = ko.observableArray([]);
		this.selected_location = ko_helper.safe_observable(params.selected_location);
		this.show_hidden_locations = ko.observable(params.show_hidden_locations || false);
		this.title = ko.observable(params.title || 'Select location...');
		this.searchText = ko.observable();

		this.searchText.subscribe((newValue) => this.search(newValue) );
		this.available_locations.subscribe(() => this.search('') );
		this.param_locations.subscribe((newValue) => this.available_locations(newValue) );

		this.init();
	}

	async init ()
	{
		let locations;

		if (!this.param_locations() || this.param_locations().length <= 0)
			if (this.show_hidden_locations())
				locations = await Grape.cache.fetch('Locations')
			else
				locations = await Grape.cache.fetch('ActiveLocations')
		else
			locations = this.param_locations();

		this.available_locations(locations)
	}

	search (newValue)
	{	
		let searchTerm = newValue.toLowerCase();
		let locations = this.available_locations();
		let matchedLocations = locations.filter(loc => loc.name.toLowerCase().includes(searchTerm));

		this.filtered_locations(matchedLocations);
	}

	location_click (row)
	{
		this.searchText('');

		if (row === this.selected_location())
			this.selected_location('');
		else
			this.selected_location(row);
	}
}

export default {
	name: 'l-search-dropdown',
	viewModel: SearchDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}