import template from './edit_stock_item_location.html';

class EditStockItemLocationViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.stock_item_location_details = ko.observable(dialog.bindings.data);

		this.title = ko.observable();

		this.idx = ko.observable(dialog.bindings.data.idx);
		this.code = ko.observable(dialog.bindings.data.code);
		this.safety_lvl = ko.observable(dialog.bindings.data.safety_lvl);
		this.buffer_lvl = ko.observable(dialog.bindings.data.buffer_lvl);

		this.prices = ko.observableArray(dialog.bindings.data.latest_price_info || []);
		this.add_prices = ko.observableArray([]);
		this.remove_prices = ko.observableArray([]);
		
		this.date_input = ko.observable();
		this.price_input = ko.observable();
		this.min_order_qty_input = ko.observable();

		this.edit_mode = ko.observable(false);
		this.init();
	}

	init ()
	{
		if (this.dialog.bindings.location.name && this.dialog.bindings.data.stock_item)
			this.title(`${this.dialog.bindings.location.name} (${this.dialog.bindings.data.stock_item})`);
		else 
			this.title('Edit Stock Item Location Information');
	}

	price_edit ()
	{
		this.edit_mode(!this.edit_mode());
		this.clear_inputs()
	}

	price_add ()
	{
		if (!this.price_input() && !this.date_input() && !this.min_order_qty_input())
		{
			Grape.alerts.alert({ title: 'Error', type: 'error', message: 'No new values inserted' });
			return;
		}

		if (isNaN(this.price_input()) || isNaN(this.min_order_qty_input()))
		{
			Grape.alerts.alert({ title: 'Error', type: 'error', message: 'values for minimum order quantity/price should be a number' });
			this.clear_inputs();
			return;
		}

		this.edit_mode(false);
		let textArr = this.prices();
		textArr.push({ price: this.price_input(), min_order_qty: this.min_order_qty_input(), date_effective: this.date_input() });
		this.add_prices().push({ price: this.price_input(), min_order_qty: this.min_order_qty_input(), date_effective: this.date_input() });

		this.prices(textArr);
		this.clear_inputs();
	}

	price_remove (row)
	{
		if (this.add_prices().indexOf(row) == -1)
			this.remove_prices().push(row);
		this.prices.pop(row);
		this.edit_mode(false);
	}

	clear_inputs ()
	{
		this.price_input('');
		this.min_order_qty_input('');
		this.date_input('');
	}
}

class EditStockItemLocationPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditStockItemLocationViewModel(this);
		this.location_id = bindings.location.id;
		this.stock_item_id = bindings.data.stock_item_id;
	}

	async save_stock_item_location ()
	{
		if (this.viewModel.edit_mode() === true)
		{
			let response = await Grape.alerts.confirm({message: 'Are you sure? Edit mode is still active. Be sure to save the pricing first if you want to keep the change!', type: 'warning', title: 'Confirm'});
			if (!response)
				return;
		}

		let loc = {
			location_id: this.location_id,
			stock_item_id: this.stock_item_id,
			idx: this.viewModel.idx(),
			buffer_level: this.viewModel.buffer_lvl(),
			safety_level: this.viewModel.safety_lvl(),
			code: this.viewModel.code()
		};

		if (this.viewModel.add_prices().length > 0 || this.viewModel.remove_prices().length > 0)
			loc.prices = {};

		if (this.viewModel.add_prices().length > 0)
			loc.prices.add = this.viewModel.add_prices();

		if (this.viewModel.remove_prices().length > 0)
			loc.prices.remove = this.viewModel.remove_prices()

		try
		{
			let response = await fetch('/api/stock-management/stock-item-location/location', {
				method: 'POST',
				headers: {'content-type': 'application/json' },
				body: JSON.stringify(loc)
			});

			let data = await response.json();

			if (response.ok)
				Grape.alerts.alert({ title: 'Success', type: 'success', message: `${this.viewModel.title()} successfully updated!` });
			else
				throw new Error(data.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		} finally {
			this.close()
		}
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

export default {
	name: 'EditStockItemLocation',
	dialog_class: EditStockItemLocationPage,
	template: template
}
