import template from './bom-stock-item-dropdown.html';

class BOMStockItemDropdownViewModel
{
	constructor (params)
	{
		this.params = params;

		this.stock_items = ko.observableArray([]);
		this.available_items = ko.observableArray([]);
		this.selected_items = ko_helper.safe_observableArray(params.selected_items || []);
		this.title = ko.observable('Select/Search BOM Item...');
		this.search_text = ko.observable('');

		this.init();

		this.filtered_items = ko.computed(() => {
			let low_case_text = this.search_text().toLowerCase();
		
			// Items that match the search low_text
			let matched_items = this.stock_items().filter(item => 
				item.description.toLowerCase().includes(low_case_text)
			);
		
			this.selected_items().forEach(selected_item => {
				let item = matched_items.find(item => item.bom_id === selected_item.bom_id);
				if (!item)
				{
					let matching_stock_item = this.stock_items().find(stock_item => stock_item.bom_id === selected_item.bom_id);
					if (matching_stock_item)
						matched_items.push(matching_stock_item);
				}
			});
		
			matched_items.forEach(item => {
				let matching_stock_item = this.stock_items().find(stock_item => stock_item.bom_id === item.bom_id);
				if (matching_stock_item)
					item.version_info = `version: ${matching_stock_item.version} | version name: ${matching_stock_item.version_name}`;
				else
					item.version_info = '';
			});
		
			return matched_items;
		});

		this.available_items.subscribe((newItems) => {
			if (params.available_items_changed)
				params.available_items_changed(newItems);
		});
	}

	async init ()
	{
		let stock = await Grape.fetches.getJSON('/api/record', { 
			table: 'v_bom', 
			schema: 'stock', 
			limit: 10000, 
			fields: ['stock_item_id', 'available_versions', 'description', 'other_names', 'code', 'attributes', 'in_use', 'location_ids', 'bom_id', 'version', 'version_name'],
			filter_join: 'AND',
			join: 'OR',
			filter: [
				{
					field: 'in_use',
					operand: '=',
					value: true
				}
			]
		});

		this.stock_items(stock.records);
		this.available_items(stock.records);
	}

	item_click (row, e)
	{
		//to prevent dialog from closing onclick
		e.stopPropagation();

		let items = this.selected_items();
		let index = -1;
		let count = 0;
		items.forEach((item) => {
			if (item.bom_id == row.bom_id)
				index = count;

			count++;
		});

		if (index == -1)
			items.push(row);
		else
			items.splice(index, 1);

		this.selected_items(items);

		return true;
	}

	is_selected (item) 
	{
		return this.selected_items().some(selected_item => selected_item.bom_id === item.bom_id)
	}
}

export default {
	name: 'bom-stock-item-dropdown',
	viewModel: BOMStockItemDropdownViewModel,
	module_type: 'ko',
	template: template
}