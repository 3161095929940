import template from './si_levels_page.html';

class StockItemLevelsPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.stock_item_id = ko.observable();
		this.current_levels = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.selected_locations = ko.observableArray([]);
		this.locations_formatted = ko.observableArray([]);

		this.selected_locations.subscribe(() => this.page.updateData() );
	}
}

class StockItemLevelsPage
{
	constructor (bindings)
	{
		this.viewModel = new StockItemLevelsPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Item Levels';
		let locations = await Grape.cache.fetch('ActiveLocations');
		let filtered_locations = locations.filter(location => location.location_type !== 'Supplier');
		this.viewModel.locations(filtered_locations);
		this.viewModel.selected_locations(locations);

		let locations_formatted = window.Grape.StockUtils.format_locations_list(filtered_locations);
		this.viewModel.locations_formatted(locations_formatted);
	}

	async updateData ()
	{
		let stock_location_result = await Grape.fetches.getJSON('/api/record', 
		{
			table: 'v_location_stock_item_info',
			schema: 'stock',
			fields: [
				'location', 'buffer_lvl', 'safety_lvl', 'latest_price_info', 'current_level', 'on_order', 'location_id'
			],
			filter: [{
					field: 'stock_item_id',
					value: this.viewModel.stock_item_id(),
					operand: '='
				},{
					field: 'location_id',
					value: this.viewModel.selected_locations().map(loc => loc.location_id),
					operand: 'IN'
				}]
		});

		if (stock_location_result.status === 'OK')
		{
			// Flatten the location list
			let flattened_locations = Grape.StockUtils.flatten_locations(this.viewModel.locations_formatted());

			let location_map = flattened_locations.reduce((acc, location) => {
				acc[location.location_id] = location;
				return acc;
			}, {});

			// Map the location_id and add a 'depth' field to each record
			let location_id = stock_location_result.records.map(record => {
				let matching_location = location_map[record.location_id];

				// Gets latest price for dates < TODAY
				let now = new Date();
				let recent_price = record.latest_price_info
				.filter(price_info => new Date(price_info.date_effective) <= now)
				.reduce((max, price_info) => {
					return price_info.date_effective > max.date_effective ? price_info : max;
				}, record.latest_price_info[0]);

				// If a matching location is found, add a 'depth' property to it
				if (matching_location)
					return { ...record, location_id: matching_location.location_id, depth: matching_location.depth, most_recent_price: recent_price || { price: null } };

				return record;
			});

			// Sort based on hierarchy
			let sorted_locations = this.hierarchy_sort(this.viewModel.locations_formatted(), location_id);

			this.viewModel.current_levels(sorted_locations);
		}
	}

	// Sort based on traversal of depth to display hierarchy correctly
	hierarchy_sort (formatted_locations, current_levels)
	{
		let sorted_array = [];

		let location_map = current_levels.reduce((acc, location) => {
			acc[location.location_id] = location;
			return acc;
		}, {});

		let traverse = node => {
			let node_info = location_map[node.location_id];
			if (node_info)
				sorted_array.push(node_info);

			node.children.forEach(child => traverse(child));
		}

		formatted_locations.forEach(root => traverse(root) );

		return sorted_array;
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_levels',
	page_id: 'si_levels',
	page_class: StockItemLevelsPage,
	template: template
};