import template from './orders_and_execution.html';

class OrdersAndExecutionPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.orders = ko.observableArray([]);

		this.sortField = ko.observable('order_date');
		this.sortOrder = ko.observable('DESC');

		this.available_types = ko.observableArray([]);
		this.selected_types = ko.observableArray([]);
		this.available_locations = ko.observableArray([]);
		this.available_suppliers = ko.observableArray([]);
		this.selected_sources = ko.observableArray([]);
		this.selected_destinations = ko.observableArray([]);

		this.all_statuses = ko.observableArray([]);
		this.selected_statuses = ko.observableArray([]);
		this.available_statuses = ko.computed(() => {
			let selected_type = this.selected_types()[0];
			return this.all_statuses().filter(status => status.type === selected_type).map(status => status.status);
		});
		
		this.selected_from_date = ko.observable();
		this.selected_to_date = ko.observable();
		this.selected_tab = ko.observable('prod_orders');

		this.display_completed_options = ['Yes', 'No'];
		this.display_completed_selected = ko.observable('No');
		this.display_cancelled_options = ['Yes', 'No'];
		this.display_cancelled_selected = ko.observable('No');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.pagination_summary = ko.observable('');
		this.number_of_records = ko.observableArray([20, 40, 100]);
		this.selected_number_of_records = ko.observable(20);
		this.search_string = ko.observable('');
		this.currentOptions = ko.observableArray();
		this.creatable_locations = [];

		this.display_completed_selected.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.display_cancelled_selected.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_from_date.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_to_date.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_destinations.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_sources.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_statuses.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_types.subscribe((newVal) => {
			if (this.page.initialized)
				this.page.updateData();
		});

		this.selected_tab.subscribe((newVal) => {
			if (newVal === 'prod_orders')
				this.selected_types(['Production Order']);
			else if (newVal === 'work_orders')
				this.selected_types(['Work Order']);
			else if (newVal === 'prod_picking_orders')
				this.selected_types(['Production Picking Order']);
		});

		// To load the inital statuses for the production orders. TODO change to do this better and use localstorage to remember last nav tab
		this.selected_tab.valueHasMutated();

		this.selected_number_of_records.subscribe((newVal) => {
			localStorage.setItem('selected_number_of_records_production_orders', this.selected_number_of_records());
			this.current_page_size(newVal);
			if (this.page.initialized)
				this.page.updateData();
		});
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#prod-orders-nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});
		
		event.currentTarget.classList.add('active');
		
		this.selected_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	btn_create_order_click ()
	{
		if (this.selected_types()[0] === 'Production Order')
			Grape.navigate('/production/order/', {type: 'create', order_type: 'Production Order'});
		else if (this.selected_types()[0] === 'Work Order')
			Grape.navigate('/work/order/', {type: 'create', order_type: 'Work Order'});
		else if (this.selected_types()[0] === 'Production Picking Order')
			Grape.navigate('/production/picking/order/', {type: 'create', order_type: 'Production Picking Order'});
	}

	async btn_download_orders_click ()
	{
		let options = this.currentOptions();

		let today = moment().format('DD-MM-YYYY');
		let filename = `Production Orders List (${today})`;

		options.offset = 0;
		options.limit = 100000;
		
		let params = { options: options, headers: [
			{ field: 'order_nr', title: 'Order Number' },
			{ field: 'order_date', title: 'Due Date' },
			{ field: 'order_type', title: 'Order Type' },
			{ field: 'linked_orders_count', title: 'Linked Orders' },
			{ field: 'status', title: 'Status' },
			{ field: 'source_location', title: 'Customer' },
			{ field: 'target_location', title: 'Location' },
			{ field: 'stock_item_count', title: 'Item Count' },
			{ field: 'total_qty', title: 'Total Qty' },
			{ field: 'total_qty_received', title: 'Qty Outstanding' },
			{ field: 'total_value', title: 'Total Value' }
		]};

		let url = await Grape.fetches.buildURL(`download/record/${filename}/`, params);		
		window.open(url.href);
	}

	btn_view_order_click (order) 
	{
		Grape.navigate(`/production/order/view/${order.order_id}`);
	}

	btn_edit_order_click (order) 
	{
		if (this.page.can_capture_order(order))
			Grape.navigate(`/production/order/edit/${order.order_id}`);
		else
			Grape.navigate(`/production/order/view/${order.order_id}`);
	}

	page_click (page_number)	
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click() 
	{
		this.page.updateData();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.updateData();
	}
}

class OrdersAndExecutionPageClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new OrdersAndExecutionPageViewModel(this);
		this.initialized = false;

		const searchInput = document.getElementById("searchInput");
		searchInput.addEventListener("keyup", (event) => {
			if (event.key === "Enter")
				this.updateData();
		});

		let selected_number_of_records_orders = localStorage.getItem('selected_number_of_records_production_orders');
		if (selected_number_of_records_orders)
		{
			this.viewModel.selected_number_of_records(selected_number_of_records_orders);
			this.viewModel.current_page_size(selected_number_of_records_orders);
		}
	}

	async init ()
	{
		this.viewModel.loading(true);
		document.title = 'Orders and Execution'

		let [locations, types, statuses] = await Promise.all([
			Grape.cache.fetch('Locations'),
			Grape.cache.fetch('OrderTypes'),
			Grape.cache.fetch('OrderStatuses'),
			Promise.resolve({records: []})
		]);

		let supplier_names = [];
		locations.forEach((loc) => {
			if (loc.location_type == 'Supplier')
				supplier_names.push(loc.name);
		});
		this.viewModel.available_suppliers(supplier_names);

		let location_names = locations.map(record => record.name);
		this.viewModel.available_locations(location_names);
		this.viewModel.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');

		this.viewModel.available_types(Object.keys(types));

		this.viewModel.all_statuses(statuses);
		this.viewModel.loading(false);

		this.initialized = true;
	}

	async updateData ()
	{
		this.viewModel.loading(true);
		let options = {
			table: 'v_orders',
			schema: 'stock',
			offset: 0,
			sortorder: this.viewModel.sortOrder(),
			sortfield: this.viewModel.sortField(),
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		let filter_base = [
			{
				field: 'order_type',
				operand: '=',
				value: 'Production Order'
			}
		];

		// LOGIC: filter 
		if (this.viewModel.selected_types().length > 0)
			filter_base.push({
				field: 'order_type',
				operand: 'IN',
				value: this.viewModel.selected_types()
			});

		if (this.viewModel.selected_statuses().length > 0)
			filter_base.push({
				field: 'status',
				operand: 'IN',
				value: this.viewModel.selected_statuses()
			});

		if (this.viewModel.selected_sources().length > 0)
			filter_base.push({
				field: 'source_location',
				operand: 'IN',
				value: this.viewModel.selected_sources()
			});

		if (this.viewModel.selected_destinations().length > 0)
			filter_base.push({
				field: 'target_location',
				operand: 'IN',
				value: this.viewModel.selected_destinations()
			});

		if (this.viewModel.selected_from_date())
			filter_base.push({
				field: 'order_date',
				operand: '>=',
				value: this.viewModel.selected_from_date()
			});

		if (this.viewModel.selected_to_date())
			filter_base.push({
				field: 'order_date',
				operand: '<=',
				value: this.viewModel.selected_to_date()
			});

		if (this.viewModel.display_completed_selected() == 'No')
			filter_base.push({
				field: 'completed', 
				operand: '=', 
				value: 'false'
			});

		if (this.viewModel.display_cancelled_selected() == 'No')
			filter_base.push({
				field: 'cancelled', 
				operand: '=', 
				value: 'false'
			});

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter_join = "OR";
			options.filter = [
				{
					join: 'AND',
					filter: [
						{ 
							field: 'order_nr', 
							operand: 'ILIKE', 
							value: `%${this.viewModel.search_string()}%` 
						}
					].concat(filter_base)
				},
				{
					join: 'AND',
					filter: [
						{ 
							field: 'reference_numbers', 
							operand: 'ILIKE', 
							value: `%${this.viewModel.search_string()}%` 
						}
					].concat(filter_base)
				}
			]
		}
		else
			options.filter = filter_base;

		this.viewModel.currentOptions(options);

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}
 
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				result.records.forEach(order => {
					let order_nr_txt = order.order_nr;
					if (order.parent_order_id)
						order_nr_txt += ` (${order.parent_order_nr})`;

					order.order_nr_txt = order_nr_txt;
				});

				this.viewModel.orders(result.records);
				this.viewModel.page_count(Math.ceil(result.total/result.limit));
				this.viewModel.pagination_summary(`Showing ${options.offset} - ${this.viewModel.current_page_number() * this.viewModel.current_page_size()} of ${result.total} order(s)`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
		this.viewModel.loading(false);
	}

	can_capture_order (order) 
	{
		let allow = false;

		if (order.source_location && order.target_location)
		{
			if (Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (
					(this.viewModel.creatable_locations.find(loc => loc.location_name == order.source_location))
					&& (this.viewModel.creatable_locations.find(loc => loc.location_name == order.target_location))
			))
				allow = true;
		}
		else if (order.source_location)
		{
			if ( Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_name == order.source_location)))
				allow = true;
		}
		else if (order.target_location)
		{
			if (Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_name == order.target_location)))
				allow = true;
		}

		return allow;
	}
}

export default {
	route: '[/]production/orders_and_execution',
	page_class: OrdersAndExecutionPageClass,
	template: template
}