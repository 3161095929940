import template from './movement_summary.html';

class MovementSummaryViewModel
{
	constructor (page)
	{
		this.page = page;
	}
}

class MovementSummaryPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new MovementSummaryViewModel(this);
	}

	async init ()
	{
		document.title = 'Movement Summary';
	}
}

export default {
	route: '/stock/movement_summary/',
	page_class: MovementSummaryPage,
	template: template
}
