import template from './projected_orders.html';

class ProjectedOrdersViewModel
{
	constructor(page)
	{
		this.page = page;
	}
}

class ProjectedOrdersPage
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectedOrdersViewModel(this);
	}

	async init()
	{
		document.title = 'Projected Orders';
	}
}

export default {
	route: '/projections/projected_orders/',
	page_class: ProjectedOrdersPage,
	template: template
}
