import template from './confirm_discrepancy.html';

class DiscrepancyConfirmationDialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.message = ko.observable();
	}
}

class DiscrepancyConfirmationDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DiscrepancyConfirmationDialogViewModel(this);
		this.viewModel.message(bindings.confirm_message);
	}

	btn_back_click () 
	{
		this.close();
	}

	btn_confirm_click ()
	{
		this.close(true);
	}
}

export default {
	name: 'DiscrepancyConfirmation',
	dialog_class: DiscrepancyConfirmationDialog,
	template: template
}