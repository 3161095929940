import template from './setup.html';

class ProductionSetupPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.resources = ko.observableArray([]);
		this.raw_mat_locations = ko.observableArray([]);
		this.workstation_locations = ko.observableArray([]);
		this.finished_goods_locations = ko.observableArray([]);
	}

	new_workstation_click ()
	{
		Grape.navigate(`/workstation/setup`);
	}

	async new_raw_mat_click ()
	{
		let res = await Grape.dialog.open('EditLocation', { location_id: null, location_type: 'Raw Materials' });

		if (res)
			this.page.updateData();
	}

	async new_fin_goods_click ()
	{
		let res = await Grape.dialog.open('EditLocation', { location_id: null, location_type: 'Finished Goods' });

		if (res)
			this.page.updateData();
	}
}

class ProductionSetupPageClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProductionSetupPageViewModel(this);
	}

	async init ()
	{
		document.title = 'Production Setup'
	}

	async updateData ()
	{
		try {
			let locations = await Grape.fetches.getJSON('/api/record', {
				schema: 'stock',
				table: 'v_locations',
				filter: [],
				sortfield: 'location_id',
				sortorder: 'ASC'
			});

			// Filter locations by type and assign to respective observables
			this.viewModel.raw_mat_locations(locations.records.filter(loc => loc.location_type === 'Raw Materials'));
			this.viewModel.workstation_locations(locations.records.filter(loc => loc.location_type === 'Workstation'));
			this.viewModel.finished_goods_locations(locations.records.filter(loc => loc.location_type === 'Finished Goods'));

		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}
	}
}

export default {
	route: '[/]production/setup',
	page_class: ProductionSetupPageClass,
	template: template
}