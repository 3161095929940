import template from './l_order_page.html';
import LocationsViewAPI from './../location_view_api.js';

class OrdersViewModel
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.location_id = ko.observable(page.bindings.location_id);
		this.location_type = ko.observable(page.bindings.location_type);
		this.location_children = ko.observableArray([]);
		this.outgoing_orders = ko.observableArray([]);
		this.incoming_orders = ko.observableArray([]);
		this.child_incoming_orders = ko.observableArray([]);
		this.child_outgoing_orders = ko.observableArray([]);

		//sort bindings
		this.sortField = ko.observable('order_date');
		this.sortOrder = ko.observable('ASC');
	}

	btn_view_order_click (order) 
	{
		Grape.navigate(`/stock/order/view/${order.order_id}`);
	}

	btn_edit_order_click (order) 
	{
		Grape.navigate(`/stock/order/edit/${order.order_id}`);
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.updateData();
	}
}

class OrdersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new OrdersViewModel(this);
		this.LocationsViewAPI = new LocationsViewAPI(this.viewModel.location_id());
	}

	async init ()
	{
		document.title = 'Sidebar - Orders';
		try
		{
			let result = await Grape.fetches.getJSON('/api/stock-management/location', {'location_id': this.viewModel.location_id()});

			if (result.status != 'ERROR')
			{
				this.viewModel.location_children(result.children);
				if (this.viewModel.location_children() !== null)
					for (let child of this.viewModel.location_children())
						await this.load_child_orders(child.location_id);
			}
			else
				throw new Error(result.message || result.code);

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async updateData ()
	{
		this.load_orders();
	}

	async load_orders()
	{
		try
		{
			let options = {
				table: 'v_orders',
				schema: 'stock',
				sortorder: this.viewModel.sortOrder(),
				sortfield: this.viewModel.sortField(),
				filter: [{
					field: 'source_location_id',
					operand: '=',
					value: this.viewModel.location_id()
				}]
			}

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
				this.viewModel.outgoing_orders(result.records)
			else
				throw new Error(result.message || result.code);

			options = {
				table: 'v_orders',
				schema: 'stock',
				sortorder: this.viewModel.sortOrder(),
				sortfield: this.viewModel.sortField(),
				filter: [{
					field: 'target_location_id',
					operand: '=',
					value: this.viewModel.location_id()
				}]
			}

			result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
				this.viewModel.incoming_orders(result.records)
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async load_child_orders(child_location_id)
	{
		try {
			let options = {
				table: 'v_orders',
				schema: 'stock',
				sortorder: 'ASC',
				filter: [{
					field: 'source_location_id',
					operand: '=',
					value: child_location_id
				}]
			};

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
				this.viewModel.child_outgoing_orders.push(result.records)
			else
				throw new Error(result.message || result.code);
			
			options = {
				table: 'v_orders',
				schema: 'stock',
				sortorder: 'ASC',
				filter: [{
					field: 'target_location_id',
					operand: '=',
					value: child_location_id
				}]
			};

			result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
				this.viewModel.child_incoming_orders.push(result.records)
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}
	}
}

export default {
	route: '[/location/]l_orders',
	page_id: 'l_orders',
	page_class: OrdersPage,
	template: template
};