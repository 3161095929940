//Stock pages
import stock_levels from './stock/stock_levels.js';
import stock_levels_summary from './stock/stock_levels_summary.js';
import stock_take from './stock/stock_take.js';
import stock_take_capture from './stock/stock_take_capture.js';
import movement_transactions from './stock/movements/movement_transactions.js';
import movement_list from './stock/movements/movement_list.js';
import movement_summary from './stock/movements/movement_summary.js';
import create_movement from './stock/movements/create_movement.js';

import batch_import from './batch-import/batch-import.js';

//Order pages
import create_order from './orders/create_order.js';
import inter_branch_transfers from './orders/IBT-list/inter_branch_transfers.js';
import sales_orders from './orders/SO-list/sales_orders.js';
import sales_order_create from './orders/SO-list/sales_order_create.js';
import purchase_orders from './orders/PO-list/purchase_orders.js';
import batched_sales_orders from './orders/BSO-list/batched_sales_orders.js';
import batched_sales_order_create from './orders/BSO-list/create_batched_sales_order.js';
import picking_list_create from './orders/PIL-list/create_picking_list.js';
import usage_orders from './orders/UO-list/usage_orders.js';
import all_orders from './orders/all-orders-list/all_orders.js';
import redirect_order_page from './orders/redirect_order_page.js';

//Stock item pages
import stock_item_view from './stock_item/stock_item.js';
import si_overview_page from './stock_item/si-overview/si_overview_page.js';
import si_levels_page from './stock_item/si-levels/si_levels_page.js';
import si_movements_page from './stock_item/si-movements/si_movements_page.js';
import si_orders_page from './stock_item/si-orders/si_orders_page.js';
import si_pricing_page from './stock_item/si-pricing/si_pricing_page.js';
import si_files_page from './stock_item/si-files/si_files_page.js';
import si_production_page from './stock_item/si-production/si_production_page.js';

import stock_item_bulk_add from './admin/bulk_add/bulk_add.js';
import stock_item_list from './stock_item_list/stock_item_list.js';

//Landing page
import landing_page from './landing_page/landing.js';

//Projections pages
import projected_orders from './projections/projected_orders.js';
import future_shortages from './projections/future_shortages.js';

//Admin pages
import system_setup_page from './admin/admin-page.js';
import stock_item_attributes from './admin/stock_item_attributes/stock_item_attributes.js';
import location_types from './admin/location_types/location_types.js';
import stock_groups from './admin/stock_groups/stock_groups.js';
import user_locations from './admin/user_locations/user_locations.js';
import order_types from './admin/order_types/order_types.js';
import system_attributes from './admin/system_attributes/system_attributes.js';

//Locations list pages
import locations_list from './locations/locations_list.js';
import suppliers_list from './locations/suppliers_list.js';

//Location pages
import location_view from './locations/location_view/location_view.js';
import l_overview_page from './locations/location_view/l_overview/l_overview_page.js';
import l_orders_page from './locations/location_view/l_orders/l_order_page.js';
import l_movement_page from './locations/location_view/l_movements/l_movement_page.js';
import l_stock_item_page from './locations/location_view/l_stock_items/l_stock_item_page.js';
import l_users_page from './locations/location_view/l_users/l_users_page.js';

//Production pages
import production_setup from './production/setup.js';
import workstation from './production/workstation/workstation.js';
import orders_and_execution from './production/orders_and_execution.js';
import production_schedule from './production/production_schedule.js';
import master_planning from './production/master_planning.js';
import create_production_order from './production/create_orders/create_production_order.js';
import create_work_order from './production/create_orders/create_work_order.js';
import create_production_picking_order from './production/create_orders/create_production_picking_order.js';

//Graphs dashboard
import graphs_dashboard from './graphs_dashboard/graphs_dashboard.js';

export default [
	system_setup_page,
	create_movement,
	batch_import,
	stock_levels,
	stock_levels_summary,
	stock_take,
	stock_take_capture,
	movement_transactions,
	movement_list,
	movement_summary,
	create_order,
	inter_branch_transfers,
	sales_orders,
	sales_order_create,
	purchase_orders,
	batched_sales_orders,
	batched_sales_order_create,
	picking_list_create,
	usage_orders,
	all_orders,
	redirect_order_page,
	stock_item_view,
	stock_item_bulk_add,
	stock_item_list,
	landing_page,
	locations_list,
	stock_item_attributes,
	system_attributes,
	location_types,
	stock_groups,
	user_locations,
	projected_orders,
	future_shortages,
	si_overview_page,
	si_levels_page,
	si_movements_page,
	si_orders_page,
	si_pricing_page,
	si_files_page,
	si_production_page,
	order_types,
	location_view, 
	suppliers_list,
	l_overview_page,
	l_movement_page,
	l_stock_item_page,
	l_users_page,
	l_orders_page,
	production_setup,
	workstation,
	orders_and_execution,
	production_schedule,
	master_planning,
	create_production_order,
	create_work_order,
	create_production_picking_order,
	graphs_dashboard
];
