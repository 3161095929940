import template from './workstation.html';

class WorkstationViewModel
{
	constructor (page)
	{
		this.page = page;
		this.workstation_id = ko.observable('');
		this.workstations = ko.observableArray([]);
		this.edit_mode = ko.observable(false);
		this.name = ko.observable();
		this.description = ko.observable();
		this.hours = ko.observableArray([]);
		this.flow = ko.observableArray();
		this.upload_file = ko.observable();
		this.locations = ko.observableArray([]);
		this.selected_destination = ko.observable();
		this.delete_workstation_visible = ko.observable(false);
		this.code = ko.observable();
		this.location_tag = ko.observableArray([{ name: undefined }]);

		// TODO: get these values from view or db, and be able to override them
		this.workstation_default_values = ko.observableArray([
			{ label: "Rate/Hour", value: ko.observable(""), input_type: "number" },
			{ label: "Setup Time", value: ko.observable(""), input_type: "text" },
			{ label: "Cleanup Time", value: ko.observable(""), input_type: "text" },
			{ label: "Cost Per Hour", value: ko.observable(""), input_type: "number" },
			{ label: "Min. Capacity/run", value: ko.observable(""), input_type: "number" },
			{ label: "Max. Capacity/run", value: ko.observable(""), input_type: "number" }
		]);
		this.edit_defaults_mode = ko.observable(false);

		if (Grape.currentSession.roles.includes('stock.super_user'))
			this.delete_workstation_visible(true);

		this.workstation_id.subscribe((newval) => {
			if (newval && (localStorage.getItem('workstation_setup_last_workstation') != newval || this.page.bindings.workstation_id === null))
			{
				localStorage.setItem('workstation_setup_last_workstation', newval);
				Grape.navigate(`/workstation/setup/${newval}`);
			}
		});
	}

	edit_workstation_defaults ()
	{
		this.edit_defaults_mode(true);
	}

	save_workstation_defaults ()
	{
		this.edit_defaults_mode(false);
		let default_values = this.workstation_default_values().map(item => {
			return {
				label: item.label,
				value: item.value()
			};
		});

		this.save_default_workstation_values(default_values);
	}

	save_default_workstation_values (default_values)
	{
		// TODO: save default workstation values
		console.log("Saving defaults: ", default_values);
	}

	addLocationTag () 
	{
		this.location_tag.push({ name: undefined });
	}

	removeLocationTag (v)
	{
		this.location_tag.remove(v);
	}

	async upload_click () 
	{
		const hiddenFileInput = document.querySelector('.workstation_attachment_upload');

		const changeListener = async () => {
			const selectedFile = hiddenFileInput.files[0];
	
			if (selectedFile)
			{
				await window.Grape.StockUtils.fileupload({
					form_id: document.getElementById('workstation_attachment_upload_form'),
					api: '/api/stock-management/workstation/setup/attachment/upload',
					header_info: {},
					success_message: 'Attachment successfully uploaded!'
				});

				this.page.updateData();

				hiddenFileInput.removeEventListener('change', changeListener);
			}
		};

		hiddenFileInput.addEventListener('change', changeListener);
		hiddenFileInput.click();
	}

	async save_workstation_click ()
	{
		if (!this.selected_destination() || !this.name())
		{
			Grape.alerts.alert({ 
				type: 'warning', 
				title: 'Empty Fields', 
				message: 'Please ensure that the name and description fields are populated and that a destination location is selected!' 
			});

			return;
		}

		let options = {
			workstation_id: this.workstation_id() || null,
			name: this.name(),
			description: this.description(),
			code: this.code(),
			location_tag: this.location_tag().map(tag => tag.name),
			destination_location_id: this.selected_destination().location_id
		};

		try {
			let result = await Grape.fetches.postJSON('/api/stock-management/workstation', options);

			if (result.status == 'OK')
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Workstation successfully saved' });
				Grape.navigate(`/workstation/setup/${result.workstation_id}`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		this.edit_mode(false);
	}

	new_workstation_click ()
	{
		Grape.navigate(`/workstation/setup`);
	}

	cancel_edit_workstation_click ()
	{
		this.edit_mode(false);
	}

	edit_workstation_click ()
	{
		this.edit_mode(true);
	}

	async delete_workstation_click ()
	{
		if (this.workstations().length < 2)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Cannot delete a workstation if no other workstations exist! Please create a new workstation first.' });
			return;
		}

		let result = await Grape.alerts.confirm({ 
			type: 'danger', 
			title: 'Delete Workstation?', 
			message: 'Are you sure you want to delete this workstation? It will require a replacement workstation for any BOM steps linked to it!' 
		});
		if (!result) return;
		
		let dialog_result = await Grape.dialog.open('DeleteWorkstation', { workstation_id: this.workstation_id(), workstation_options: this.workstations() });

		if (dialog_result)
		{
			let replacement_workstation_id = dialog_result;

			try {
				let options = { 
					workstation_id: this.workstation_id(),
					replacement_workstation_id: replacement_workstation_id
				};
				let result = await Grape.fetches.deleteJSON('/api/stock-management/workstation', options);
		
				if (result.status === 'OK')
				{
					Grape.alerts.alert({ type: 'success', title: 'Deleted', message: 'Workstation successfully deleted' });
					Grape.navigate(`/workstation/setup`);
				}
				else
					throw new Error(result.message || result.code);
		
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error('Error deleting workstation:', error);
			}

			return;
		}
		else
			return;
	}
}

class WorkstationClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new WorkstationViewModel(this);

		if (this.bindings.workstation_id)
			this.viewModel.workstation_id(this.bindings.workstation_id);
		else
			this.viewModel.edit_mode(true);
	}

	async init ()
	{
		document.title = 'Workstation Setup';
		let locations = await Grape.cache.fetch('ActiveLocations');

		this.viewModel.locations(locations);

		if (!this.bindings.workstation_id)
		{
			let default_loc = locations.find(loc => loc.name === 'Factory Area (Default)');
			if (default_loc)
				this.viewModel.selected_destination(default_loc);
		}

		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: []
			});

			if (result.status === 'OK')
				this.viewModel.workstations(result.records);
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async updateData ()
	{
		if (this.viewModel.workstation_id() === '')
			this.viewModel.edit_mode(true);
		else
		{
			this.viewModel.edit_mode(false);
			this.load_workstation(this.viewModel.workstation_id());
		}
	}

	async load_workstation (workstation_id)
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: [{
					field: 'workstation_id',
					operand: '=',
					value: workstation_id
				}]
			});

			if (result.status === 'OK')
			{
				this.viewModel.workstation_id(result.records[0].workstation_id);
				this.viewModel.name(result.records[0].name);
				this.viewModel.description(result.records[0].description);
				this.viewModel.selected_destination(this.viewModel.locations().find(loc => loc.location_id === result.records[0].destination_location_id));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]workstation/setup/:workstation_id',
	page_class: WorkstationClass,
	template: template
}