import template from './master_planning.html';

class MasterPlanningPageViewModel
{
	constructor (page)
	{
		this.page = page;
	}
}

class MasterPlanningPageClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new MasterPlanningPageViewModel(this);
	}

	init ()
	{
		document.title = 'Master Planning'
	}

	updateData ()
	{
	}
}

export default {
	route: '[/]production/master_planning',
	page_class: MasterPlanningPageClass,
	template: template
}