import StockUtils from '../../../lib/StockUtils';
import template from './create_movement.html';

/**
 * @kind page
 * @class CreateMovementViewModel 
 * @description This is the page to create a movement transaction
 */
class CreateMovementViewModel
{
	constructor (page)
	{
		this.page = page;

		//draft_movements
		this.draft_movement_id = ko.observable(null);
		this.draft_movements = ko.observableArray([]);
		this.draft_movements_caption = ko.observable('Select Draft Movement');
		this.selected_draft_movement = ko.observable('');

		this.selected_items = ko.observableArray([]);
		this.available_items = ko.observableArray([]);
		this.initial_movements = [];

		this.date_effective = ko.observable('');
		this.movement_transaction_id = ko.observable();
		this.edit_transaction = ko.observable(false);
		
		this.all_movements_selected = ko.observable(false);
		
		this.movements = ko.observableArray([]);
		this.movement_note = ko.observable('');
		this.movement_types = ko.observableArray([]);
		this.movement_subtypes = ko.observableArray([]);
		this.transaction_types = ko.observableArray(['Incoming', 'Outgoing']);
		this.movement_types_obj = ko.observableArray([]);
		this.movements_to_remove = ko.observableArray([]);
		this.movements_created = 0;
		this.removed_items = ko.observableArray([]);

		this.selected_movement_type = ko.observable('');
		this.selected_movement_subtype = ko.observable('');

		this.filtered_locations = ko.observableArray([]);
		this.selected_location = ko.observable('');
		this.selected_location_type = ko.observable('Internal');

		this.locations = ko.observableArray([]); 

		//Adds all movements to movements_to_remove when using select all movements checkbox
		this.all_movements_selected.subscribe((newVal) => {
			if (newVal)
				this.movements_to_remove(this.movements().slice());
			else 
				this.movements_to_remove([]);
		});

		//Shows movement subtype options based on the movement type selection
		this.selected_movement_type.subscribe(async (newType) => {
			if (newType) 
			{
				let result = await this.get_movement_sub_types(newType.movement_type_id);
				this.movement_subtypes(result);
			} 
			else
				this.movement_subtypes([]);
		});

		this.selected_items.subscribe((new_selected_items) => {
			// Add selected items not in movements
			for (let selected_item of new_selected_items)
			{
				let has_movement = this.movements().find((item) => {
					return item.stock_item === selected_item.description;
				});

				if (!has_movement)
					this.movements.push({ stock_item: selected_item.description, stock_item_id: selected_item.stock_item_id, qty: 0 });
			}

			// Remove items in movements but not selected
			let movements = this.movements();
			let new_movements = [];
			for (let movement of movements)
			{
				let is_selected = new_selected_items.find((item) => {
					return item.description == movement.stock_item;
				});

				if (is_selected)
					new_movements.push(movement);
			}
			this.movements(new_movements);
		});

		this.selected_location_type.subscribe((newValue) => this.populate_filtered_locations(newValue));
	}

	populate_filtered_locations (location_type)
	{
		if (location_type)
			this.filtered_locations(this.locations().filter(loc => loc.location_type === location_type));
		else
			this.filtered_locations(this.locations());

		if (this.selected_location_type().location_type !== location_type)
			this.selected_location('');
	}
	draft_string (data) 
	{
		return `${data.username} - ${data.date_inserted.slice(0, 10)}`;
	}

	async load_draft_click (draft)
	{
		let draft_location = this.locations().find(value => value.name == draft.movements.location.name);

		this.movements(draft.movements.movements);
		this.date_effective(draft.movements.date_effective);

		this.selected_location_type(draft_location.location_type || 'Internal');
		this.selected_location(draft_location || null);

		if (draft.movements.movement_type)
		{
			this.selected_movement_type(this.movement_types().find(value => value.movement_type == draft.movements.movement_type.movement_type) || null)
			let result = await this.get_movement_sub_types(this.selected_movement_type().movement_type_id || null);
			this.movement_subtypes(result || []);
			this.selected_movement_subtype(this.movement_subtypes().find(value => value.subtype == draft.movements.movement_subtype.subtype));
		}
		this.movement_note(draft.movements.note);
		this.draft_movement_id(draft.draft_movement_id || null);

		this.update_selected_stock_items();
	}

	async save_draft_click ()
	{
		await this.page.upsert_draft_movement_transaction('POST');
	}

	async get_movement_sub_types (move_type_id) 
	{
		if (!this.movement_types_obj())
			await this.page.load_movement_types();
	
		let movement_subtypes = this.movement_types_obj().find((value) => { 
			return value.movement_type_id == move_type_id 
		});

		return movement_subtypes.subtypes || [];
	}

	//Select all movements in the table when the checkbox is clicked
	select_all_movements (data, event) 
	{
		if (event.target.checked)
			this.all_movements_selected(true);
		else 
			this.all_movements_selected(false);

		return true;
	}

	remove_movement_click (stockItem) 
	{
		this.movements.remove(stockItem);
		this.update_selected_stock_items();
	}

	remove_selected_movements () 
	{
		for (let item of this.movements_to_remove())
			this.remove_movement_click(item);

		this.movements_to_remove([]);
		this.all_movements_selected(false);
	}

	async save_movement_click () 
	{
		let dialog;
		let response = true;
		try 
		{
			if (!this.selected_location()) 
				throw new Error('Please select a warehouse for the movement transaction.');

			if (!this.selected_movement_type())
				throw new Error('Please select a movement type for the movement transaction.');

			if (!this.date_effective())
				throw new Error('Please select a date effective for the movement transaction.');

			// Update Removed Items
			let removed_items = [];
			this.initial_movements.forEach((init_movement) => {
				let found = this.movements().find((new_movement) => {
					return new_movement.stock_item == init_movement.stock_item
				});

				if (!found)
					removed_items.push(init_movement);
			});
			this.removed_items(removed_items);

			let invalid_movements = [];
			this.movements().forEach(movement => {
				if (movement.qty == 0 || (typeof movement.qty === 'string' && movement.qty.trim() === '')) 
					invalid_movements.push(movement)
			});
			
			dialog = await Grape.dialog.open('MovementTransactionConfirm', { 
				movements: this.movements().filter(item => !invalid_movements.includes(item)),
				movement_note: this.movement_note(),
				location: this.selected_location(),
				movement_type: this.selected_movement_type(),
				movement_subtype: this.selected_movement_subtype(),
				movement_transaction_id: this.movement_transaction_id(),
				date_effective: this.date_effective(),
				removed_items: this.removed_items(),
				edit_transaction: this.edit_transaction(),
				invalid_movements: invalid_movements
			});

			if (dialog && JSON.stringify(dialog) != '{}')
			{
				let transaction_verified = await window.Grape.StockUtils.verify_loc_stock_levels(this.selected_location().location_id, this.date_effective(), this.movements());

				if (!transaction_verified)
					response = await Grape.alerts.confirm({ 
						type: 'error', 
						message: `This movement will cause ${this.selected_location().name}'s stock levels drop below 0`, 
						title: 'Stock levels below 0'
					})

				if (!response)
					return;

				if (dialog.action === 'Save')
					this.page.save_transaction(dialog.return_object);
				else if (dialog.action === 'Commit') 
					await this.page.commit_transaction(dialog.return_object);
				else if (dialog.action === 'Update')
				{
					this.page.update_movement_transaction(dialog.return_object);
	
					if (this.draft_movement_id())
						await this.page.upsert_draft_movement_transaction('DELETE', this.draft_movement_id());
				}

				if (this.draft_movement_id() && (dialog.action === 'Save' || dialog.action === 'Commit'))
					await this.page.upsert_draft_movement_transaction('DELETE', this.draft_movement_id());
			}
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			if (dialog && response && JSON.stringify(dialog) != '{}')
				Grape.navigate('/stock/movement/list');
		}
	}

	async upload_csv (event) 
	{
		let has_error = false;
		let file = event.target.files[0];

		if (!file)
			return;

		let reader = new FileReader();
		reader.onload = async (e) => 
		{
			try
			{
				let content = e.target.result;

				//Skip the header row
				let rows = content.split('\n').slice(1); 

				this.movements.removeAll();
				for (let i = 0; i < rows.length; i++) 
				{
					let row = rows[i];
					if (row.trim() === "") 
						continue;

					let cells = row.split(',');
					//Extract values from the cells
					let [description, qty] = cells;

					let result = qty.match(/-?\d+/);

					if (result)
						qty = result[0];
					else 
						throw new Error("No numeric value found in the string");

					//Check for missing fields
					if (!description || !qty) 
						throw new Error(`Row ${i+2}: Please fill in all the fields.`);

					//Check for invalid descriptions
					//Extract name and stock code from the item description
					let stockItem = this.available_items().find(item => {
						return item.description === description;
					});

					if (stockItem) 
						description = stockItem.description;
					else 
						throw new Error(`Row ${i+2}: Please provide a valid description.`);


					this.movements.push({ stock_item: description, stock_item_id: stockItem.stock_item_id , qty: qty });
				}

				this.update_selected_stock_items();
			} catch (error) {
				Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
				console.error(error);
				has_error = true;
			}

			if (!has_error)
				Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Upload complete!' });
		};

		reader.onerror = (err) => {
			console.error(err.message || 'Failed to read the CSV file.');
			Grape.alerts.alert({ title: 'Error', type: 'error', message: err.message || 'Failed to read the CSV file.' });
		};

		reader.readAsText(file);
	}

	update_selected_stock_items()
	{
		let selected_items = [];
		for (let a_item of this.available_items())
		{
			let movement = this.movements().find((item) => {
				return item.stock_item == a_item.description;
			});

			if (movement)
				selected_items.push(a_item);
		}
		this.selected_items(selected_items);
	}

	//Return to the Movements page
	close_capture_page () 
	{
		history.back();
	}
}

class CreateMovementPage
{
	constructor(bindings)
	{
		document.title = 'Create Stock Movements';
		this.viewModel = new CreateMovementViewModel(this);
		this.bindings = bindings;

		this.viewModel.movement_transaction_id(bindings.movement_transaction_id || null);

		if (this.viewModel.movement_transaction_id() != null)
			this.viewModel.edit_transaction(true);
	}

	async init ()
	{
		try 
		{
			let locations = await Grape.cache.fetch('Locations');
			this.viewModel.locations(locations);
			await this.load_movement_types();
			await this.load_stock_items();
			await this.get_draft_movement_transaction();
			this.viewModel.selected_location_type('Internal');
			this.viewModel.populate_filtered_locations(this.viewModel.selected_location_type());
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			if (this.viewModel.movement_transaction_id() != null)
				this.get_movement_transaction();
		}
	}

	async upsert_draft_movement_transaction (method, draft_movement_id)
	{
		let filter = [];

		if (draft_movement_id)
			filter = [{
				field: 'draft_movement_id',
				operand: '=',
				value: draft_movement_id
			}];

		try 
		{
			let date = new Date().toISOString().slice(0, 10);

			let options = {
				schema: 'stock',
				table: 'draft_movement',
				filter: filter,
				returning: '*',
				values: {
					date_inserted: date,
					username: Grape.currentSession.user.username,
					movements: {
						date_effective: this.viewModel.date_effective(),
						movement_type: this.viewModel.selected_movement_type(),
						movement_subtype: this.viewModel.selected_movement_subtype(),
						location: this.viewModel.selected_location(),
						note: this.viewModel.movement_note(),
						movements: this.viewModel.movements()
					}
				}
			}

			let response = await fetch('api/record', {
				method: method,
				headers: { 'content-type': 'application/json' },
				body: JSON.stringify(options)
			});

			let data = await response.json()
			if (data.status == 'OK' && method != 'DELETE')
				Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Draft movement saved!' });
			else if (data.status != 'OK')
				throw new Error(data.message || data.code);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			await this.get_draft_movement_transaction();
		}
	}

	async get_draft_movement_transaction ()
	{	
		try 
		{
			let response = await Grape.fetches.getJSON('api/record', { schema: 'stock', table: 'draft_movement' });

			if (response.status == 'OK')
				this.viewModel.draft_movements(response.records);
			else 
				throw new Error(data.message || data.code);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}

	async get_movement_transaction ()
	{
		try 
		{
			let response = await Grape.fetches.getJSON('api/record', { 
				table: 'v_all_movement_transactions',
				schema: 'stock',
				filter: [{
					field: 'movement_transaction_id',
					operand: '=',
					value: this.viewModel.movement_transaction_id()
				}],
				fields: ['date_effective', 'location_name', 'movement_subtype', 'movement_type', 'note', 'items', 'committed']
			});

			if (response.status == 'OK')
				this.load_movement_transaction(response.records[0]);
			else 
				throw new Error(response.message || response.code);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}

	async load_movement_types ()
	{
		let response = await Grape.cache.fetch('MovementTypes');

		let type = [];
		for (let movement of response)
			type.push({ movement_type: movement.movement_type, movement_type_id: movement.movement_type_id });
		
		this.viewModel.movement_types(type);
		this.viewModel.movement_types_obj(response);
	}

	async load_stock_items ()
	{
		let response = await Grape.cache.fetch('StockItems');

		this.viewModel.available_items(response);
	}

	async commit_transaction (transaction_obj)
	{
		let movement_transaction_id = await this.save_transaction(transaction_obj);
		this.viewModel.movement_transaction_id(movement_transaction_id);

		try
		{
			let response = await Grape.fetches.postJSON('/api/stock-management/movement/commit', { movement_transaction_id: movement_transaction_id });

			if (response.status == 'OK')
			{
				Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Movement transaction successfuly committed!' });
				Grape.navigate('/stock/movement/list');
			}
			else
				throw new Error(response.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	async save_transaction (transaction_obj)
	{
		try
		{
			let response = await Grape.fetches.postJSON('/api/stock-management/movement', transaction_obj);

			if (response.status == 'OK')
				return response.movement_transaction_id;
			else
				throw new Error(response.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	async load_movement_transaction (mt)
	{
		let draft_location = this.viewModel.locations().find(value => value.name == mt.location_name);

		this.viewModel.initial_movements = mt.items;
		this.viewModel.movements(mt.items);
		this.viewModel.date_effective(mt.date_effective);

		this.viewModel.selected_location_type(draft_location.location_type || null);
		this.viewModel.selected_location(draft_location || null);

		this.viewModel.selected_movement_type(this.viewModel.movement_types().find(value => value.movement_type == mt.movement_type) || null)
		// Populate movement subtypes
		let result = await this.viewModel.get_movement_sub_types(this.viewModel.selected_movement_type().movement_type_id || null);
		this.viewModel.movement_subtypes(result);
		this.viewModel.selected_movement_subtype(this.viewModel.movement_subtypes().find(value => value.subtype == mt.movement_subtype) || null);
		this.viewModel.movement_note(mt.note);

		this.viewModel.update_selected_stock_items();
	}

	async update_movement_transaction (transaction_obj) 
	{
		try
		{
			let response = await Grape.fetches.postJSON('/api/stock-management/movement', transaction_obj);

			if (response.status == 'OK')
				Grape.navigate('/stock/movement/list');
			else
				throw new Error(response.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]movement/transaction/add/:movement_transaction_id',
	page_class: CreateMovementPage,
	template: template
};
