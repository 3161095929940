import template from './stock_take.html';

class StockTakeViewModel
{
	constructor (page)
	{
		this.page = page;

		this.selected_locations = ko.observableArray([]);
		this.stock_takes = ko.observableArray([]);

		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);
		this.sortfield = ko.observable('stock_take_id');
		this.sortorder = ko.observable('DESC');

		this.locations = ko.observableArray([]);
		this.selected_print_location = ko.observable();

		this.selected_locations.subscribe(() => this.page.updateData() );

		this.filter_options = {};
		this.confirmable_locations = [];
		this.creatable_locations = [];
	}

	on_sort (sortfield)
	{
		if (sortfield == this.sortfield())
			if (this.sortorder() == 'ASC')
				this.sortorder('DESC');
			else
				this.sortorder('ASC');
		else
		{
			this.sortorder('ASC');
			this.sortfield(sortfield);
		}

		this.page.updateData();
	}

	btn_capture_click ()
	{
		Grape.navigate('/stock/stock_take/edit');
	}

	async btn_report_click ()
	{
		let options = this.filter_options;
		options.limit = 10000;
		let filename = 'stock_take_report';
		let params = { options: options, headers: [
			{ field: 'stock_take_id', title: 'Stock Take ID' },
			{ field: 'confirmed', title: 'Confirmed' },
			{ field: 'date_inserted', title: 'Date Inserted' },
			{ field: 'user_inserted', title: 'User Inserted' },
			{ field: 'user_confirmed', title: 'User Confirmed' },
			{ field: 'total_value', title: 'Total Value' }
		]};
		let url = await Grape.fetches.buildURL(`/download/record/${filename}/`, params);

		window.open(url.href);
	}

	btn_template_click ()
	{
		if (this.selected_print_location())
		{
			let location_id = this.selected_print_location().location_id;
			window.open(`/api/stock-management/stock-take/template?location_id=${location_id}`);
		}
		else
			Grape.alerts.alert({ type: 'warning', title: 'Location', message: 'Please select a location' });
	}

	btn_edit_click (stock_take)
	{
		let stock_take_id = stock_take.stock_take_id;
		Grape.navigate(`/stock/stock_take/edit/${stock_take_id}`);
	}

	btn_view_click (stock_take)
	{
		let stock_take_id = stock_take.stock_take_id;
		Grape.navigate(`/stock/stock_take/view/${stock_take_id}`);
	}

	btn_download_summary_click (stock_take)
	{
		let stock_take_id = stock_take.stock_take_id;
		window.open(`/api/stock-management/stock-take/summary?stock_take_id=${stock_take_id}`);
	}

	btn_download_variance_report_click (stock_take)
	{
		let stock_take_id = stock_take.stock_take_id;
		window.open(`/api/stock-management/stock-take/variance?stock_take_id=${stock_take_id}`);
	}

	async btn_confirm_click (stock_take)
	{
		let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to confirm this stock take?', title: 'Confirm Stock Take', type: 'info' });

		if (confirm)
		{
			try 
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/stock-take/confirm', { stock_take_id: stock_take.stock_take_id });

				if (result.status == 'OK')
					this.page.updateData();
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}
		}
	}

	async btn_delete_click(stock_take)
	{
		let confirm = await Grape.alerts.confirm({message: 'Are you sure you want to remove this stock take?', title: 'Remove Stock Take', type: 'warning'});

		if (confirm)
		{
			try 
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/stock-take/remove', { stock_take_id: stock_take.stock_take_id });

				if (result.status == 'OK')
					this.page.updateData();
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}
		}
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}
}

class StockTakePage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new StockTakeViewModel(this);
	}

	async init ()
	{
		document.title = 'Stock Take';
		let location_cache = await Grape.cache.fetch('Locations');
		let locations = [];

		location_cache.forEach((loc) => {
			if (loc.location_type == 'Internal')
				locations.push(loc);
		});

		this.viewModel.locations(locations);
		this.viewModel.selected_locations(locations);

		this.viewModel.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmStockTake');
		this.viewModel.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateStockTake');

		this.updateData();
	}

	is_confirmable (location_name)
	{
		let confirmable = false;
		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || (this.viewModel.confirmable_locations.find(loc => loc.location_name == location_name)))
			confirmable = true;

		return confirmable;
	}

	is_editable (location_name)
	{
		let editable = false;
		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || (this.viewModel.creatable_locations.find(loc => loc.location_name == location_name)))
			editable = true;

		return editable;
	}

	async updateData ()
	{
		let options = {
			table: 'v_stock_takes',
			schema: 'stock',
			sortorder: this.viewModel.sortorder(),
			sortfield: this.viewModel.sortfield(),
			filter: []
		};

		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		if (this.viewModel.selected_locations().length > 0)
			options.filter.push({
				field: 'location',
				operand: 'IN',
				value: this.viewModel.selected_locations().map(loc => loc.name)
			});

		this.viewModel.filter_options = options;
		let stock_takes = await Grape.fetches.getJSON('/api/record', options);
		this.viewModel.page_count(Math.ceil(stock_takes.total/stock_takes.limit));

		stock_takes.records.forEach(take => {
			take.date_inserted = moment(take.date_inserted).format('YYYY-MM-DD');
			if (take.date_confirmed)
				take.date_confirmed = moment(take.date_confirmed).format('YYYY-MM-DD');
			take.can_confirm = this.is_confirmable(take.location);
			take.can_edit = this.is_editable(take.location);
		});

		this.viewModel.stock_takes(stock_takes.records);
	}
}

export default {
	route: '/stock/stock_take/',
	page_class: StockTakePage,
	template: template
}