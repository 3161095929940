import template from './sales_order_create.html';

class CreateSalesOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.type = ko.observable();
		this.order_pricing = ko.observableArray([]);
		this.order_fields = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.stock_items = ko.observableArray([]);
		this.stock_order_items = ko.observableArray([]);
		this.order_date = ko.observable();
		this.delivery_date = ko.observable();

		this.selectedSource = ko.observable();
		this.order_note = ko.observable();
		this.edited_tax = ko.observableArray([]);

		this.client_name = ko.observable();
		this.client_number = ko.observable();
		this.client_email = ko.observable();
		this.client_address = ko.observableArray();
		this.billing_address = ko.observableArray();

		this.selectedSource.subscribe(() => this.page.order_item_pricing() );
	}

	btn_back_click () 
	{
		Grape.navigate('/stock/order/sales_orders');
	}

	btn_create_click () 
	{
		this.save_order();
	}

	async save_order () 
	{
		if (!this.selectedSource() || !(this.stock_order_items() && this.stock_order_items().length)) 
		{
			Grape.alerts.alert({
				title: 'Error', 
				type: 'error', 
				message: 'Please fill in all the fields & add at least one stock item to the table before saving.'
			});

			return;
		}

		let order = {
			'order_date': this.order_date(),
			'delivery_date': this.delivery_date(),
			'type': 'Sales Order',
			'status': 'SO_CREATED',
			'source_location': this.selectedSource().name,
			'note': this.order_note(),
			'taxes': this.edited_tax(),
			'items': this.stock_order_items().map(item => {
				let stock_item_id = this.find_stock_item_id(item.description);
				let total_qty = item.expected_receive_dates().reduce((sum, delivery) => sum + parseInt(delivery.qty(), 10), 0);
				return {
					'description': item.description,
					'stock_item_id': stock_item_id,
					'qty': total_qty,
					'ppu': item.ppu(),
					'expected_receive_dates': item.expected_receive_dates().map(delivery => {
						return {
							'date': delivery.date(),
							'qty': delivery.qty()
						};
					})
				};
			})
		};

		let fields = {};

		await this.build_sales_order_fields();

		for (let item of this.order_fields())
			fields[item.fieldName] = item.data;
		order.fields = fields;

		try
		{
			let response = await Grape.dialog.open('SalesOrderConfirm', { order: order });

			if (response)
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/order', order);

				if (result.status == 'OK') 
					Grape.navigate(`stock/order/edit/${result.order_id}`);
				else 
					throw new Error(result.message || result.code);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('Error:', error);
		}
	}

	build_sales_order_fields ()
	{
		this.order_fields.push(
			{
				fieldName: 'Recipient Name',
				data: this.client_name(),
				editing: ko.observable(false)
			},
			{
				fieldName: 'Recipient Email',
				data: this.client_email(),
				editing: ko.observable(false)
			},
			{
				fieldName: 'Recipient Contact Number',
				data: this.client_number(),
				editing: ko.observable(false)
			},
			{
				fieldName: 'Billing Address',
				data: this.billing_address(),
				editing: ko.observable(false)
			},
			{
				fieldName: 'Shipping Address',
				data: this.client_address(),
				editing: ko.observable(false)
			}
		);
	}

	find_stock_item_id (description) 
	{
		let foundItem = this.stock_items().find(item => item.description === description);

		return foundItem ? foundItem.stock_item_id : null;
	}
}

class CreateSalesOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.stock_items = bindings.stock_items || [];
		this.viewModel = new CreateSalesOrderViewModel(this);
		this.viewModel.type(this.bindings.type);
		this.confirmable_locations = [];
		this.creatable_locations = [];
		this.is_type_from_list = ko.observable(false);
	}

	async init () 
	{
		document.title = 'Create Sales Order';
		this.viewModel.order_date(moment().format('YYYY-MM-DD'));
		this.viewModel.delivery_date(moment().format('YYYY-MM-DD'));
	
		try 
		{
			let [locations, stock] = await Promise.all([
				Grape.cache.fetch('Locations'),
				Grape.fetches.getJSON('/api/record', { 
					table: 'v_stock_item', 
					schema: 'stock', 
					fields: ['stock_item_id', 'description', 'attributes'],
					limit: 10000
				})
			]);

			this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');
			let filtered_locations = [];
			locations.forEach((loc) => {
				if (Grape.currentSession.roles.includes('stock.all-location-permissions') || this.creatable_locations.find(cloc => cloc.location_id == loc.location_id))
					filtered_locations.push(loc);
			});
			this.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmOrder');
	
			this.viewModel.locations(filtered_locations.filter(location => location.location_type === 'Internal'));
			this.viewModel.stock_items(stock.records);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error fetching data:', error);
		}
	}
	
	// Get current_price for item at selected source location
	async order_item_pricing () 
	{
		if (this.viewModel.selectedSource())
		{
			let pricing = await Grape.fetches.getJSON('/api/record', {
				table: 'v_location_stock_item_info',
				schema: 'stock',
				fields: [ 'location_id', 'stock_item_id', 'current_price'],
				filter: [{
					field: 'location_id',
					value: this.viewModel.selectedSource().location_id,
					operand: '='
				}]
			});

			let map = {};
			pricing.records.forEach(item => map[item.stock_item_id] = item.current_price );
		
			this.viewModel.stock_items().forEach(item => {
				let price = map[item.stock_item_id];
				if (price !== undefined)
					item.current_price = price;
			});
		}
	}
}

export default {
	route: '/sales/order/create',
	page_class: CreateSalesOrderPage,
	template: template
}
