import template from './movement-transaction.html';

/**
 * @kind component
 * @class TransactionComponentViewModel
 * @description This is the table component for the movement transaction table
 */
class TransactionComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.movement_transaction = ko.observableArray([]);

		//sort bindings
		this.sortField = ko.observable('date_effective');
		this.sortOrder = ko.observable('DESC');

		this.options = ko_helper.safe_observable(params.options || null);
		this.location_id = ko_helper.safe_observable(params.location_id || null);
		this.location_visible = ko.observable(false);
		this.actions_visible = ko.observable(false);

		if (Grape.currentSession.roles.includes('stock.super_user'))
			this.actions_visible(true);

		if (this.options() != null)
			this.location_visible(true);

		this.options.subscribe(() => {
			this.updateData();
		});

		// pagination 
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);

		if (this.location_id())
			this.updateData();

		this.confirmable_locations = [];
		this.update_confirmable_locations();
	}

	async update_confirmable_locations ()
	{
		this.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmStockMovement');
	}

	async updateData ()
	{
		//movement_transactions
		let options = [];

		if (this.options() != null)
			options = this.options();
		else if (this.location_id() != null)
		{
			options = {
				table: 'v_all_movement_transactions',
				schema: 'stock',
				filter: [{
					field: 'location_id',
					operand: '=',
					value: this.location_id()
				}]
			}
		} 
		else 
		{
			options = {
				table: 'v_all_movement_transactions',
				schema: 'stock',
				filter: []
			}
		}

		//set sorting fields
		options.sortfield = this.sortField();
		options.sortorder = this.sortOrder();

		options.limit = 0;
		options.offset = this.current_page_size();

		// LOGIC: Pagination
		if (this.current_page_number() && this.current_page_size())
		{
			options.limit = this.current_page_size();
			options.offset = (this.current_page_number()-1) * this.current_page_size();
		}

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				this.page_count(Math.ceil(result.total/result.limit));

				result.records.forEach(record => {
					let can_commit = false;
					if (Grape.currentSession.roles.includes('stock.all-location-permissions') || this.confirmable_locations.find(cloc => cloc.location_id == record.location_id))
						can_commit = true;

					record.can_commit = can_commit;
				});

				this.movement_transaction([]);
				this.movement_transaction(result.records);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async delete_movement_transaction (movement_transaction) 
	{
		let options = Grape.fetches.params2String({
			movement_transaction_id: movement_transaction.movement_transaction_id
		});

		try 
		{
			let result = await fetch(`/api/stock-management/movement?${options}`, {
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json' }
			});
			
			let data = await result.json();
			if (data.status == 'OK')
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Movement Successfully Deleted' });
			else 
				throw new Error(result.message || result.code || 'Error')
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		} finally {
			await this.updateData();
		}
	}

	visit_location_click (location_id)
	{
		Grape.navigate(`admin/location/view/${location_id}`);
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.updateData();
	}

	async commit_transaction_click (movement_transaction_id, committed, can_commit)
	{
		let result = false;
		if (!committed && can_commit)
			result = await Grape.dialog.open('TransactionCommit', { movement_transaction_id: movement_transaction_id });

		if (result)
		{
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Movement Transcation committed!' });
			this.updateData();
		}
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.updateData();
	}

	async delete_movement_click (row)
	{
		let result = await Grape.alerts.confirm(
			{
				type: 'warning',
				message: 'Are you sure you want to delete this movement transaction?',
				title: 'Delete Movement Transaction', 
			});

		if (result)
			this.delete_movement_transaction(row);
	}

	async edit_movement_click (row)
	{
		//navigate to movement create page
		Grape.navigate(`movement/transaction/add/${row.movement_transaction_id}`);
	}
}

export default {
	name: 'movement-transaction-component',
	viewModel: TransactionComponentViewModel,
	module_type: 'ko',
	template: template
}
