import template from './ko-schedule-details.html';

class ScheduleDetailsComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.available_bom_items = ko_helper.safe_observableArray(params.available_items || []);
		this.selected_bom_item = ko.observable();
		
		this.table_records = ko.observableArray([]);
		this.total_duration = ko.observable();

		this.selected_bom_item.subscribe((newVal) => {
			//TODO
		});
	}
}

export default {
	name: 'ko-schedule-details',
	viewModel: ScheduleDetailsComponentViewModel,
	module_type: 'ko',
	template: template
}