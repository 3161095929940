import template from './si_overview_page.html';

class StockItemOverviewPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.stock_item_id = ko.observable();
		this.type = ko.observable();
		this.stock_info = ko.observable();
		this.stock_image = ko.observable();
		this.stock_item_attributes = ko.observableArray([]);
		this.recent_orders = ko.observableArray([]);
	}

	async btn_edit_info_click () 
	{
		try
		{
			let obj = await Grape.dialog.open('StockItemEdit', { stock_info: this.stock_info(), type: 'stock_item' });

			if (!obj)
				return;

			let result = await Grape.fetches.postJSON('/api/stock-management/stock-item', obj);

			if (result.status != 'ERROR')
				Grape.alerts.alert({
					type: 'success', 
					message: 'Product ' + obj.description + ' saved', 
					title: 'Product Saved', 
				});
			else
				throw new Error(result.message);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error });
			console.error(error);
		} finally {
			await this.page.updateData();
		}
	}
}

class StockItemOverviewPage
{
	constructor (bindings)
	{
		this.viewModel = new StockItemOverviewPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.viewModel.type(bindings.type || 'stock_item');
		this.timer = null;

		this.filter = [{
			field: 'stock_item_id', 
			value: this.viewModel.stock_item_id(), 
			operand: '='
		}];
	}

	async init ()
	{
		document.title = 'Item Overview';
	}

	async updateData ()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_order_items', 
			schema: 'stock', 
			sortfield: 'date_inserted', 
			sortorder: 'DESC', 
			limit: 3,
			filter: [{
				field: 'stock_item_id', 
				value: this.viewModel.stock_item_id(), 
				operand: '='
			}]
		});
	
		if (result.status == 'OK')
			this.viewModel.recent_orders(result.records);

		let stock_item_result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_stock_item', 
			schema: 'stock', 
			sortorder: 'DESC', 
			filter: this.filter
		});

		if (stock_item_result.status == 'OK') 
			if (stock_item_result.records) 
			{
				let record_one = stock_item_result.records[0];
				record_one.supplier_code = record_one.supplier_code ?? '(none)';
				record_one.stock_tag = record_one.stock_tag?.length ? record_one.stock_tag : '(none)';
				record_one.other_names = record_one.other_names?.length ? record_one.other_names : ['(none)'];
				record_one.attributes = record_one.attributes?.length ? record_one.attributes : ['(none)'];
				record_one.uom = '(none)';

				if (record_one.uom_id)
					record_one.uom = record_one.uom_qty + ' ' + record_one.uom_symbol;

				this.viewModel.stock_info(stock_item_result.records[0]);
			}
	}

	can_edit_attr () 
	{
		return Grape.currentSession.roles.includes('stock.manager');
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_overview',
	page_id: 'si_overview',
	page_class: StockItemOverviewPage,
	template: template
};