/**
* @class LocationSelectTreeviewVM
* @component name - ko-location-select-treeview
* @constructor 
* @param {object[]} available_locations - the list of locations to display
* @param {text} [select_type='Single'] - does it need to be multi-select or only one selected location
* @param {object[]} selected_locations - selectedLocation - where to use the selected location(s) 
* @param {text} [title="Select Location..."] - 'Select Parent location'- title of the dropdown
*/
import template from './location-select-treeview.html';

class LocationSelectTreeviewVM
{
	constructor (params)
	{
		this.params = params;

		this.available_locations = ko_helper.safe_observableArray(params.available_locations);
		this.selected_locations = ko_helper.safe_observableArray(params.selected_locations);
		this.title = ko_helper.safe_observable(params.title || 'Select Location...');
		this.select_type = ko.observable(params.select_type || 'Single');
		this.show_label_count = ko.observable(2); 

		this.search_text = ko.observable('');
		this.includeChildLocations = ko.observable(false);
		this.formatted_locations = ko.observableArray([]);
		this.locations_treeview = ko.observableArray([]);
		this.all_locations_treeview = ko.observableArray([]);

		this.display_title_attribute = ko.computed(() => {
			if (this.select_type() === 'Multiple')
				return this.selected_locations().map(loc => loc.name).join(', ');
			else if (this.select_type() === 'Single' && this.selected_locations().length > 0)
				return this.selected_locations()[0].name;
			else if (this.select_type() === 'Single' && !this.selected_locations().length > 0 && this.selected_locations() != undefined && this.selected_locations() != null)
				return this.selected_locations().name;
			else
				return '';
		});

		this.selected_objects_name_str = ko.computed(() => {
			if (this.selected_locations().length > 0)
				return this.selected_locations().map(loc => loc.name).join(', ');
			else if (this.selected_locations() != undefined && this.selected_locations() != null)
				return [this.selected_locations()].map(loc => loc.name).join(', ');
			else
				return '';
		});

		this.available_locations.subscribe(() => this.updateData() );
		this.search_text.subscribe((newValue) => this.search(newValue) );
	}

	async init ()
	{
		await this.updateData();
	}

	async updateData ()
	{
		let locations_formatted = window.Grape.StockUtils.format_locations_list(this.available_locations());
		this.formatted_locations(locations_formatted);
		let locations_flattened = window.Grape.StockUtils.flatten_locations(locations_formatted);

		// Make sure depth property is included
		locations_flattened = locations_flattened.map(location => {
			let depth = location.depth || 0; 
			return {...location, depth};
		});

		this.locations_treeview(locations_flattened);
		this.all_locations_treeview(locations_flattened);
	}

	search (newVal)
	{
		let low_case_text = newVal.toLowerCase();

		// Items that match the search low_text
		let matched_items = this.all_locations_treeview().filter(item =>
			item.name.toLowerCase().includes(low_case_text)
		);

		if (this.selected_locations().length > 0)
			this.selected_locations().forEach(item => {
				if (!matched_items.some(si => si.name == item.name))
					matched_items.push(item);
			});

		this.locations_treeview(matched_items);
	}

	item_click (row, e, includeChildren)
	{
		// Prevent dialog from closing onclick
		if (this.select_type() == "Single")
			e.stopPropagation();

		let items = [];
		if (Array.isArray(this.selected_locations())) 
			items = [...this.selected_locations()];
		else 
			items = [this.selected_locations()];

		let index_found = items.findIndex(item => item.name === row.name);

		// If item is found, remove it from array
		if (index_found > -1 && !includeChildren)
			items.splice(index_found, 1);
		else if (includeChildren || this.select_type() === 'Single')
		{
			// If the select_type is 'Single' empty the array first
			if (this.select_type() === 'Single')
				items = [];
			else if (includeChildren)
			{
				let child_locations = [];
				if (row.children.length > 0)
				{
					child_locations = this.addChildLocations(child_locations, row.children);
					let addChild_ctr = 0;

					for (let loc of child_locations)
						if (!items.includes(loc))
						{
							addChild_ctr++;
							items.push(loc);
						}

					if (addChild_ctr <= 0)
					{
						items.splice(index_found, 1);
						for (let loc of child_locations)
							items.splice(items.indexOf(loc), 1);
					}
				}
			}
		}

		if (index_found == -1)
			items.push(row);

		this.selected_locations(items);

		if (this.search_text() !== '')
			this.search_text('');

		return true;
	}

	is_selected (item)
	{
		// Display selected items
		if (Array.isArray(this.selected_locations()))
			return this.selected_locations().some(selected_item => selected_item.name === item.name);
		else
			return (item.name === this.selected_locations().name)
	}

	includeSliderChecked (e)
	{
		e.stopPropagation();
		this.includeChildLocations(!this.includeChildLocations());

		return true;
	}

	addChildLocations (selected_locations = [], locations)
	{
		for (let loc of locations)
		{
			if (loc.children.length > 0)
			{
				if (!selected_locations.includes(loc))
					selected_locations.push(loc);
				selected_locations = this.addChildLocations(selected_locations, loc.children);	
			}
			else
				if (!selected_locations.includes(loc))
					selected_locations.push(loc);
		}

		return selected_locations;
	}
}

export default {
	name: 'ko-location-select-treeview',
	viewModel: LocationSelectTreeviewVM,
	module_type: 'ko',
	template: template
}