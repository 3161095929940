import template from './sales_order_confirm.html';

class SalesOrderConfirmViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.order = ko.observableArray([]);
	}

	btn_back_click ()
	{
		this.dialog.close();
	}

	save_order_click ()
	{
		this.dialog.close(true);
	};
}

class SalesOrderConfirmDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SalesOrderConfirmViewModel(this);

		this.viewModel.order(this.bindings.order);
	}
}

export default {
	name: 'SalesOrderConfirm',
	dialog_class: SalesOrderConfirmDialog,
	template: template
}
