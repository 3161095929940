class LocationsViewAPI
{
	constructor (location_id) {
		this.LocationsViewAPI = LocationsViewAPI;
		this.location_id = location_id;
	}	

	async getViewRecords (tableName, sortOrder, sortField)
	{
		let tableOptions;

		if (tableName !== undefined && tableName !== '')
		{
			tableOptions = {
				table: tableName,
				schema: 'stock',
				filter: [
					{
						field: 'location_id',
						operand: '=',
						value: this.location_id
					},
				]
			}
		}
		else
			throw new Error('No tableName provided')

		if (sortField !== undefined && sortField !== '')
			tableOptions.sortfield = sortField;

		if (sortOrder !== undefined && sortOrder !== '' && (sortOrder == 'ASC' || sortOrder == 'DESC'))
			tableOptions.sortorder = sortOrder;

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', tableOptions);

			if (result.status != 'ERROR')
				return result;
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}
}

export default LocationsViewAPI;
