import template from './user_locations.html';

class UserLocationsViewModel
{
	constructor (page)
	{
		document.title = 'Location Permissions';

		this.users = ko.observableArray([]);
		this.page = page;
	}
}

class UserLocationsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.result_found = ko.observable(true);
		this.viewModel = new UserLocationsViewModel(this);
	}

	async updateData ()
	{
		let result = await Grape.fetches.getJSON('/user', {});
		ko.mapping.fromJS(result, {}, this.viewModel);
	}

	btn_edit_user_click (value, event)
	{
		let user = ko.toJS(value);
		Grape.dialog.open('EditUserLocations', {user_uuid: user.user_uuid});
	}
}

export default {
	route: '[/]admin/user_locations',
	page_class: UserLocationsPage,
	template: template
};
