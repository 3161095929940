import template from './edit_supplier.html';

class EditSupplierViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;

		this.title = ko.observable('Edit Supplier');
		this.name = ko.observable();
		this.code = ko.observable();
		this.in_use = ko.observable(true);

		this.suppliers_display = ko.observableArray([]);
		this.selectedSupplier = ko.observableArray();
		this.supplier_caption = ko.observable('None');
		this.supplier_tag = ko.observableArray([{name: undefined}])
	}	

	addSupplierTag () 
	{
		this.supplier_tag.push({ name: undefined });
	}

	removeSupplierTag (v)
	{
		this.supplier_tag.remove(v);
	}
}

class EditSupplierPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditSupplierViewModel(this);
		this.supplier_id = bindings.location_id;

		this.init();
	}

	init ()
	{
		this.updateData();
		if (!this.supplier_id)
			this.viewModel.title('New Supplier');
	}

	async updateData ()
	{
		let supplier_result = await Grape.cache.fetch('Locations');

		let suppliers_display = [];
		for (let i = 0; i < supplier_result.length; i++)
		{
			let loc = supplier_result[i];

			if (loc.location_id == this.supplier_id)
			{
				this.viewModel.name(loc.name);
				this.viewModel.in_use(loc.in_use);
				this.viewModel.code(loc.code);
				if (loc.tags && loc.tags != '')
				{
					let tags = loc.tags.map(name => ({ name }));
					this.viewModel.supplier_tag(tags);
				}
			}
			else
				if (loc.in_use)
					suppliers_display.push(loc);
		}

		this.viewModel.suppliers_display(suppliers_display);
	}

	async save_supplier ()
	{
		let tags = this.viewModel.supplier_tag().map(obj => obj.name);
		let loc = {
			location_id: this.supplier_id,
			name: this.viewModel.name(),
			in_use: this.viewModel.in_use(),
			type: 'Supplier',
			code: this.viewModel.code(),
			tags: tags
		};

		try
		{
			let response = await fetch('api/stock-management/location', {
				method: 'POST',
				headers: {'content-type': 'application/json'},
				body: JSON.stringify(loc)
			});

			let data = await response.json();

			if (response.ok)
				this.updateData();
			else
				throw new Error(data.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		} finally {
			Grape.cache.refresh('Locations');
			this.close()
		}
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

export default {
name: 'EditSupplier',
dialog_class: EditSupplierPage,
template: template
}
