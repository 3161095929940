import template from './create_picking_list.html';

class CreatePickingListViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(true);
		this.orders_loading = ko.observable(true);
		this.items_loading = ko.observable(true);
		this.order = ko.observableArray([]);
		this.type = ko.observable(this.page.bindings.type);
		this.order_id = ko.observable(this.page.bindings.order_id);
		this.order_fields = ko.observableArray([]);
		this.order_audit_log = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.delivery_docs = ko.observableArray([]);
		this.delivery_doc = ko.observable();

		this.order_types = ko.observableArray([]);
		this.order_statuses = ko.observableArray([]);

		this.orders = ko.observableArray([]);
		// sort observables for the orders
		this.sortField = ko.observable('order_nr');
		this.sortOrder = ko.observable('ASC');

		// order pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);

		this.items = ko.observableArray([]);
		// sort observables for the stock items
		this.items_sortField = ko.observable('stock_item_id');
		this.items_sortOrder = ko.observable('ASC');
		this.item_display_mode = ko.observable('summary');

		this.is_type_from_list = ko.observable(false);

		this.selectedType = ko.observable();
		this.selectedStatus = ko.observable();
		this.selectedSource = ko.observable();
		this.selectedDestination = ko.observable();
		this.delivery_date = ko.observable();
		this.order_date = ko.observable();
		this.order_number = ko.observable();
		this.order_note = ko.observable();
		this.order_documents = ko.observableArray([]);
		this.edited_tax = ko.observableArray([]);

		this.filteredSourceLocations = ko.observableArray([]);
		this.filteredDestinationLocations = ko.observableArray([]);

		this.editing_batch = ko.observable(false);

		this.selectedType.subscribe((type) => {
			if (type) 
			{
				this.filteredSourceLocations(this.locations().filter(location => location.location_type === type.source_location_type));
				this.filteredDestinationLocations(this.locations().filter(location => location.location_type === type.target_location_type));
			}

			this.selectedSource('')

			this.selectedStatus(null);
			let initialStatus;
			
			if (type && !this.order_id() && !this.selectedStatus())
				initialStatus = type.statuses.find((x) => x.initial);
		
			let filtered_statuses;
			
			if (initialStatus)
				filtered_statuses = [initialStatus];
			else if (type)
			{
				let selectedStatus = this.order().order_status;
				let current_status = type.statuses.find(status => status.status === selectedStatus);
				filtered_statuses = type.statuses.filter((x) => {
					return x.status === selectedStatus || (current_status && current_status.allowed_status.includes(x.status));
				});
			}
			
			this.order_statuses(filtered_statuses);
			if (initialStatus) 
				this.selectedStatus(initialStatus);
		});
		
		this.show_order_nr_on_create = ko.computed(() => {
			if (Grape.config.public_settings.display_order_nr_on_create && this.type() == 'create')
				return true;
			else 
				return false;
		})
	}

	btn_download_grn_click (item) 
	{
		window.open(`/api/stock-management/GRN/generate?order_id=${this.order_id()}&order_transaction_id=${item.order_transaction_id}`);
	}

	// Event handlers

	async btn_reject_status_click (status) 
	{
		let result = await Grape.alerts.confirm(
		{
			message: 'Are you sure you want to set the order status to cancelled? This cannot be undone.',
			title: 'Cancel Order', 
			type: 'warning'
		});

		if (result) 
		{
			let selectedStatus = status;
			let save_status = this.selectedType().statuses.find(status => status.status === selectedStatus);
			this.selectedStatus(save_status);
			this.page.save_order();
		}
	}

	btn_accept_status_click (status) 
	{
		let selectedStatus = status;
		let save_status = this.selectedType().statuses.find(status => status.status === selectedStatus);
		this.selectedStatus(save_status);
		this.page.save_order();
	}

	btn_edit_field_click (item) 
	{
		item.editing(true);
	}

	btn_back_click () 
	{
		Grape.navigate('/stock/order/all_orders/');
	}

	btn_save_field_click (item) 
	{
		item.editing(false);
		this.page.save_order();
	}

	btn_save_click () 
	{
		this.page.save_order();
	}

	async btn_complete_pick_click ()
	{
		try {
			let result = await Grape.fetches.postJSON(`/api/stock-management/order/complete`, {order_id: this.order_id()});

			if (result.status === 'OK') 
			{
				this.page.update_data();
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
		}
	}

	btn_create_click () 
	{
		this.page.save_order();
	}

	btn_download_document_click (item) 
	{
		if (navigator.userAgent.indexOf("Chrome") !== -1)
			this.open_pdf_in_new_window(item);
		else 
		{
			let document = window.open(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
			document.onload = (() => { this.page.update_data(); });
		}
	}

	async open_pdf_in_new_window(item) 
	{
		try 
		{
			const response = await fetch(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
		
			if (!response.ok)
				throw new Error('Network response was not ok');
		
			const blob = await response.blob();
			const blob_url = window.URL.createObjectURL(blob);
			const new_window = window.open('', '_blank');

			if (new_window) 
			{
				new_window.document.write(`<iframe width='100%' height='100%' src='${blob_url}'></iframe>`);
				new_window.document.title = 'Document';
				new_window.focus();
			} else
				throw new Error('Unable to open new window. Please check your pop-up blocker settings.');
		} catch (error) {
			console.error('Error:', error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			this.page.update_data();
		}
	}
	
	current_status_document (item) 
	{
		if (this.selectedStatus() && this.selectedStatus().document_name)
			return this.selectedStatus().document_name === item.name;

		return false;
	}

	async delete_delivery_doc_click (doc)
	{
		try 
		{
			let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to delete this document?', title: 'Warning', type: 'warning' });

			if (!confirm)
				return;

			let response = await fetch(`/api/order-delivery/doc?order_delivery_document_id=${doc.order_delivery_document_id}`, {
				headers: {'Content-Type': 'application/json'},
				method: 'DELETE'
			});

			let data = await response.json();
			if (data.status == 'OK') 
				Grape.alerts.alert({ type: 'success', title: 'Delete delivery document', message: 'Delivery document was deleted successfully'})
			else
				throw new Error(data.message || 'Error deleting delivery document');
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			this.page.update_data();
		}
	}

	async upload_delivery_doc_click () 
	{
		document.querySelector('.picking_list_document_upload').click();
	}

	download_delivery_doc_click (document)
	{
		window.open(`/delivery/document/download/${document.order_delivery_document_id}`);
	}

	btn_view_order_click (order) 
	{
		if (order.order_type == 'Picking List')
		{
			Grape.navigate(`/picking-list/order/view/${order.order_id}`);
		}
		else if (order.order_type == 'Batched Sales Order')
		{
			Grape.navigate(`/batched-sales/order/view/${order.order_id}`);
		}
		else
		{
			Grape.navigate(`/stock/order/view/${order.order_id}`);
		}
	}

	btn_edit_order_click (order) 
	{
		if (order.order_type == 'Picking List')
			Grape.navigate(`/picking-list/order/edit/${order.order_id}`);
		else if (order.order_type == 'Batched Sales Order')
			Grape.navigate(`/batched-sales/order/edit/${order.order_id}`);
		else
			Grape.navigate(`/stock/order/edit/${order.order_id}`);
	}

	page_click (page_number)	
	{
		this.current_page_number(page_number);
		this.page.update_orders();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.update_orders();
	}

	items_sortClick (field)
	{
		this.items_sortField(field);

		if (this.items_sortOrder() == 'ASC')
			this.items_sortOrder('DESC');
		else
			this.items_sortOrder('ASC');

		this.page.update_items();
	}

	async edit_item_click (item)
	{
		if (item.is_editing())
		{
			try {
				let order_note = {
					order_id: this.order_id(),
					stock_item_id: item.stock_item_id,
					note: item.note()
				};
				let result = await Grape.fetches.postJSON('/api/stock-management/order-item/note', order_note);

				if (result.status == 'OK')
				{
					this.page.update_items();
				}
				else 
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error);
			}
		}
		else
		{
			item.is_editing(true);
		}
	}
}

class CreatePickingListPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new CreatePickingListViewModel(this);
		this.confirmable_locations = [];
		this.creatable_locations = [];
	}

	async init () 
	{
		document.title = 'Create Order';
		this.viewModel.order_date(moment().format('YYYY-MM-DD'));
		this.viewModel.delivery_date(moment().add(7, 'days').format('YYYY-MM-DD'));

		let locationCacheName;

		if (this.viewModel.type() == 'create')
			locationCacheName = 'ActiveLocations';
		else 
			locationCacheName = 'Locations';
	
		try 
		{
			let [locations, type] = await Promise.all([
				Grape.cache.fetch(locationCacheName),
				Grape.cache.fetch('OrderTypes'),
				Grape.fetches.getJSON('/api/record', { 
					table: 'v_stock_item', 
					schema: 'stock', 
					limit: 10000, 
					fields: ['stock_item_id', 'description', 'attributes', 'in_use', 'location_ids'],
					filter: []
				})
			]);

			this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');
			let filtered_locations = [];
			locations.forEach((loc) => {
				if (
					Grape.currentSession.roles.includes('stock.all-location-permissions')
					|| (this.creatable_locations.find(cloc => cloc.location_id == loc.location_id))
					|| this.viewModel.type() == 'view'
				)
					filtered_locations.push(loc);
			});
			this.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmOrder');
	
			this.viewModel.locations(filtered_locations);
			
			let order_types = Object.values(type).filter((o_type) => o_type.batch_created);
			this.viewModel.order_types(order_types);

			if (this.bindings.order_type) 
			{
				let matching_type = this.viewModel.order_types().find(order_type => order_type.type === this.bindings.order_type);
				if (matching_type) 
				{
					this.viewModel.is_type_from_list(true);
					this.viewModel.selectedType(matching_type);
				}
			}
	
			if (this.bindings.type == 'edit' || this.bindings.type == 'view')
				await this.update_data();
			else if (this.bindings.supplier_name !== undefined)
				this.viewModel.selectedSource(this.populate_option_field(this.viewModel.locations(), this.bindings.supplier_name) || '')
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error fetching data:', error);
		}

		// Register file upload event listener
		const hiddenFileInput = document.querySelector('.picking_list_document_upload');
		hiddenFileInput.addEventListener('change', async () => {
			const selectedFile = hiddenFileInput.files[0]; // Get the selected file

			if (selectedFile)
			{
				let header_info = { 
					order_id: this.viewModel.order_id(),
					"X-SessionID": localStorage.getItem('session_id'),
					"Accept": "application/json"
				};

				//SERVER: Upload file(s)
				try
				{
					let response = await fetch('/api/order-delivery/doc/upload', {
						method: 'POST',
						body: new FormData(document.getElementById('delivery_upload_doc')),
						headers: header_info
					});

					let data = await response.json();
					if (response.ok) 
						Grape.alerts.alert({ type: 'success', title: 'Success', message: 'File Successfully Uploaded'});
					else 
						throw new Error(data.message);
				} catch (exception) {
					Grape.alerts.alert({ type:'warning', message: exception, title:`File Upload Error` });
					console.error(exception);
				} finally {
					this.update_data();
				}
			}
		});

		this.update_orders();
		this.update_items();
	}
	
	async update_data () 
	{
		try 
		{
			let audit;
			if (this.viewModel.type() == 'edit' || this.viewModel.type() == 'view')
			{
				audit = await Grape.fetches.getJSON('/api/record', {
					table: 'v_order_audit_log',
					schema: 'stock',
					filter: [{
						field: 'order_id',
						operand: '=' ,
						value: this.viewModel.order_id()
					}],
					sortorder: 'DESC',
					sortfield: 'date_inserted'
				})

				if (audit.status == 'OK')
				{
					audit.records.forEach(record => {
						record.date_inserted = moment(record.date_inserted).format('YYYY-MM-DD, HH:mm');
					});
					this.viewModel.order_audit_log(audit.records);
				} else 
					throw new Error(audit.message || audit.code);
			}

			let deliveryDocs = await Grape.fetches.getJSON(`/api/order-delivery/doc?order_id=${this.viewModel.order_id()}`);

			if (deliveryDocs.status == 'OK')
				this.viewModel.delivery_docs(deliveryDocs.documents || []);
			else
				throw new Error(deliveryDocs.message || deliveryDoc.code || 'Error fetching delivery docs');

			let orderData = await Grape.fetches.getJSON(`/api/stock-management/order`, {
				order_id: this.viewModel.order_id()
			});

			if (orderData.status === 'OK') 
			{
				let order = orderData.order;
				this.viewModel.order(orderData.order);
				this.viewModel.selectedType(this.populate_option_field(this.viewModel.order_types(), order.order_type));
				this.viewModel.selectedStatus(this.populate_option_field(this.viewModel.order_statuses(), order.order_status));

				if (this.viewModel.selectedStatus().final === true) 
					if (this.viewModel.type() == 'edit')
						Grape.navigate(`/picking-list/order/view/${this.viewModel.order_id()}`);

				this.viewModel.selectedSource(this.populate_option_field(this.viewModel.locations(), order.source_location));
				this.viewModel.order_date(order.order_date);
				this.viewModel.delivery_date(order.delivery_date);
				this.viewModel.order_number(order.order_nr);
				this.viewModel.order_note(order.note);

				if ((order.target_location != null) || (order.target_location != undefined))
					this.viewModel.selectedDestination(this.populate_option_field(this.viewModel.locations(), order.target_location));

				if (order.documents != undefined) 
				{
					let order_documents = [];
					order.documents.forEach(document => {
						order_documents.push({
							order_document_id: document.order_document_id,
							path_basename: document.path_basename,
							name: document.name,
							user_id: document.username,
							date_inserted: new Date(document.date_inserted).toISOString().split('T')[0]
						});
					});
					this.viewModel.order_documents(order_documents);
				}

				if (order.fields != undefined) 
				{
					let order_fields = [];
					Object.entries(order.fields).forEach(([key, value]) => {
						order_fields.push({
							fieldName: key,
							data: value,
							editing: ko.observable(false)
						});
					});
					this.viewModel.order_fields(order_fields);
				}
				document.title = this.viewModel.order_number();
				this.viewModel.loading(false);
			}
			else
				throw new Error(orderData.message || orderData.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
		}
	}

	async update_orders()
	{
		this.viewModel.orders_loading(true);

		let options = {
				table: 'v_orders',
				schema: 'stock',
				sortorder: this.viewModel.sortOrder(),
				sortfield: this.viewModel.sortField(),
				limit: this.viewModel.current_page_size(),
				offset: (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size(),
				filter: [{
					field: 'parent_order_id',
					operand: '=',
					value: this.viewModel.order_id()
				}]
		};

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{

				this.viewModel.page_count(Math.ceil(result.total/result.limit));
				this.viewModel.orders(result.records);
			}
			else
				throw new Error(result.message || result.code);
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		this.viewModel.orders_loading(false);
	}

	async update_items()
	{
		this.viewModel.items_loading(true);

		try {
			let itemData = await Grape.fetches.getJSON(`/api/stock-management/order/child-items`, {
				order_id: this.viewModel.order_id(),
				sortfield: this.viewModel.items_sortField(),
				sortorder: this.viewModel.items_sortOrder()
			});

			if (itemData.status === 'OK') 
			{
				itemData.order_items.forEach((item) => {
					item.is_editing = ko.observable(false);
					item.note = ko.observable(item.note);

					item.related_orders = '';
					item.order_details.forEach((o_detail) => {
						if (item.related_orders.length != 0)
							item.related_orders = item.related_orders + '; ';
						item.related_orders = item.related_orders + o_detail.order_nr + ' [' + o_detail.qty + ']';
					});
				});
				this.viewModel.items(itemData.order_items);
			}
			else
				throw new Error(itemData.message || itemData.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
		}

		this.viewModel.items_loading(false);
	}

	populate_option_field (optionsArray, optionValue)
	{
		if (optionValue === undefined)
			return undefined;

		return optionsArray.find(option => 
			option.type === optionValue || 
			option.status === optionValue || 
			option.name === optionValue
		);
	}

	async save_order ()
	{
		let order = {
			delivery_date: this.viewModel.delivery_date(),
			order_date: this.viewModel.order_date(),
			type: this.viewModel.selectedType().type,
			status: this.viewModel.selectedStatus().status,
			source_location: this.viewModel.selectedSource().name,
			target_location: this.viewModel.selectedDestination() ? this.viewModel.selectedDestination().name : null,
			order_nr: this.viewModel.order_number(),
			note: this.viewModel.order_note(),
			taxes: this.viewModel.edited_tax()
		};

		if (this.viewModel.type() == 'edit' || this.viewModel.type() == 'view')
			order.order_id = this.viewModel.order_id();

		let fields = {};
		for (let item of this.viewModel.order_fields())
			fields[item.fieldName] = item.data;
		order.fields = fields;

		try {
			let result = await Grape.fetches.postJSON('/api/stock-management/order', order);

			if (result.status == 'OK')
			{
				if (this.viewModel.type() == 'create')
					Grape.navigate(`/picking-list/order/edit/${result.order_id}`);
				else
					this.update_data();
			}
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	// Get current_price for item at selected source location
	can_edit_tax () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			let selected_source = this.viewModel.selectedSource();
			if (selected_source)
				if (!this.creatable_locations.find(cloc => cloc.location_id == selected_source.location_id))
					allowed = false;

			if (allowed)
			{
				let selected_destination = this.viewModel.selectedDestination();
				if (selected_destination)
					if (!this.creatable_locations.find(cloc => cloc.location_id == selected_destination.location_id))
						allowed = false;
			}
		}
		return allowed;
	}

	can_progress_order () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			let selected_source = this.viewModel.selectedSource();
			if (selected_source)
				if (!this.confirmable_locations.find(cloc => cloc.location_id == selected_source.location_id))
					allowed = false;

			if (allowed)
			{
				let selected_destination = this.viewModel.selectedDestination();

				if (selected_destination)
					if (!this.confirmable_locations.find(cloc => cloc.location_id == selected_destination.location_id))
						allowed = false;
			}
		}
		return allowed;
	}

	can_capture_order (order) 
	{
		let allow = false;

		if (order.source_location && order.target_location)
		{
			if (
				Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (
					(this.viewModel.creatable_locations.find(loc => loc.location_name == order.source_location))
					&& (this.viewModel.creatable_locations.find(loc => loc.location_name == order.target_location))
				)
			) {
				allow = true;
			}
		}
		else if (order.source_location)
		{
			if (
				Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_name == order.source_location))
			) {
				allow = true;
			}
		}
		else if (order.target_location)
		{
			if (
				Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_name == order.target_location))) {
				allow = true;
			}
		}

		return allow;
	}
}

export default {
	route: '/picking-list/order/:type/:order_id',
	page_class: CreatePickingListPage,
	template: template
}