import template from './edit_contact_details.html';

class EditContactViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.contact_details = ko.observable(dialog.bindings.data);

		this.title = ko.observable('Edit Contact Information');
		this.location_contact_id = ko.observable(this.contact_details().location_contact_id || null);
		this.person = ko.observable(this.contact_details().person || null);
		this.email = ko.observable(this.contact_details().email || null);
		this.contact_number = ko.observable(this.contact_details().contact_number || null);
		this.responsibility = ko.observable(this.contact_details().responsibility || null);
	}	
}

class EditContactPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditContactViewModel(this);
		this.location_id = bindings.location_id;
		this.viewModel.contact_details(bindings.data)
	}

	async save_contact ()
	{
		let loc = {
			contact: {
				add: [{
					person: this.viewModel.person(),
					email: this.viewModel.email(),
					contact_number: this.viewModel.contact_number(),
					responsibility: this.viewModel.responsibility(),
					location_contact_id: this.viewModel.location_contact_id()
				}]
			},
			location_id: this.location_id
		};

		try
		{
			let response = await fetch('api/stock-management/location', {
				method: 'POST',
				headers: {'content-type': 'application/json' },
				body: JSON.stringify(loc)
			});

			let data = await response.json();

			if (response.ok)
				this.close();
			else
				throw new Error(data.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		} finally {
			this.close()
		}
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

export default {
	name: 'EditContact',
	dialog_class: EditContactPage,
	template: template
}
