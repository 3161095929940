export default {
	name: 'ActiveLocations',
	options: {
		table: 'v_locations',
		limit: 10000,
		schema: 'stock',
        filter: [
            {
                field: 'in_use',
                operand: '=',
                value: true
            }
        ]
	}
}