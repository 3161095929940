import template from './movement_note_edit.html';

class MovementNoteEditViewModel
{
	constructor (page)
	{
		this.page = page;
		this.movement = ko.observable();
		this.movement_note = ko.observable();
	}
}

class MovementNoteEditDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new MovementNoteEditViewModel(this);
		this.viewModel.movement(bindings.movement);
		this.viewModel.movement_note(bindings.value);
		this.parentViewModel = bindings.page;
	}
	
	async btn_back_click ()
	{
		this.close();
	}

	async confirm_movement_note_click ()
	{
		await this.updateNote();

		this.close();
		
		Grape.alerts.alert({
			message: 'Stock movement note updated',
			type: 'success',
			title: 'Stock Movement Note',
		});
	}

	async updateNote ()
	{
		let bodyObj = {
			table: 'transaction_info',
			schema: 'stock',
			returning: '*'
		};

		let method = 'PATCH';

		bodyObj.filter = [
			{
				field: "movement_transaction_id",
				operand: '=',
				value: this.viewModel.movement().movement_transaction_id
			}
		];

		bodyObj.values = {
			note: this.viewModel.movement_note()
		};

		try
		{
			let response = await fetch('/api/record/', {
				method: method,
				body: JSON.stringify(bodyObj),
				headers: { 'content-type': 'application/json' }
			});

			let data = await response.json();

			if (data.status == 'OK')
				console.info('Note updated successfully!');
			else
				throw new Error(data.message);
		} catch (err) {
			console.error(err);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: err.message });
		};
	}
}

export default {
	name: 'MovementNoteEdit',
	dialog_class: MovementNoteEditDialog,
	template: template
}
