import template from './movement_transaction_commit.html';

class MovementTransactionCommitViewModel
{
	constructor(page)
	{
		this.page = page;
		this.movement_transaction_id = ko.observable();
	}

	async commit_transaction_click()
	{
		await this.commit_transaction(this.movement_transaction_id());

		this.page.close(true);
	}

	async commit_transaction (movement_transaction_id)
	{	
		try
		{
			let response = await Grape.fetches.postJSON('/api/stock-management/movement/commit', { movement_transaction_id: movement_transaction_id });

			if (response.status == 'OK')
				console.info('Movement transaction committed!');
			else
				throw new Error(response.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	btn_back_click()
	{
		this.page.close(false);
	}
}

class MovementTransactionCommitDialog
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new MovementTransactionCommitViewModel(this);
		this.viewModel.movement_transaction_id(bindings.movement_transaction_id);
		this.parentViewModel = bindings.page;
	}
}

export default {
	name: 'TransactionCommit',
	dialog_class: MovementTransactionCommitDialog,
	template: template
}
