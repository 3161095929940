import template from './movement-summary.html';

/**
 * @kind component
 * @class MovementSummaryComponentViewModel
 * @description Sample Component that shows the name value
 * @param {Number} location_id - Optional param
 * @param {Number} stock_item_id - Optional param
 */
class MovementSummaryComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.stock_item_id = ko.observable();
		this.location_id = ko.observable();
		this.location_id_param = ko_helper.safe_observable(params.location_id);
		this.stock_item_id_param = ko_helper.safe_observable(params.stock_item_id);
		this.date_start = ko.observable();
		this.date_end = ko.observable();

		this.movement_types = ko.observableArray([]);
		this.months = ko.observableArray([]);
		this.all_locations = ko.observableArray([]);
		this.all_stock_items = ko.observableArray([]);

		this.qtys = ko.observable({});
		this.balances = {};

		this.selected_location = ko.observable();
		this.selected_stock_item = ko.observable();

		this.summary_type = ko.observable(params.type || 'All');

		this.selected_stock_item.subscribe((stock_item) => {
			this.stock_item_id(stock_item.stock_item_id);
			localStorage.setItem('movement_summary_last_stock_item', stock_item.stock_item_id);
		});

		this.selected_location.subscribe((location) => {
			this.location_id(location.location_id);
			localStorage.setItem('movement_summary_last_location', location.location_id);
		});

		this.init();
	}

	async init ()
	{
		try
		{
			let qtys = {};
			let movement_types = await Grape.cache.get('MovementTypes');

			for (let mt of movement_types)
			{
				qtys[mt.movement_type_id] = {};
				for (let st of mt.subtypes)
					qtys[mt.movement_type_id][st.subtype_id] = { qty_in: null, qty_out: null };
			}

			this.qtys(qtys);
			this.movement_types(movement_types);

			let all_locations = await Grape.cache.get('Locations');
			this.all_locations(all_locations);

			let all_stock_items = await Grape.cache.get('StockItems');
			this.all_stock_items(all_stock_items);

			let last_location = localStorage.getItem('movement_summary_last_location');
			let last_stock_item = localStorage.getItem('movement_summary_last_stock_item');

			if (this.location_id_param())
				last_location = this.location_id_param();
			else if (!this.location_id_param() && (!last_location || last_location == undefined))
				last_location = 1;

			let selected_location = all_locations.find(value => value.location_id == last_location);
			if (selected_location)
			{
				this.selected_location(selected_location);
				this.location_id(selected_location.location_id);
			}

			if (this.stock_item_id_param())
				last_stock_item = this.stock_item_id_param();
			else if (!this.stock_item_id_param() && (!last_stock_item || last_stock_item == undefined))
				last_stock_item = 1;

			let selected_stock_item = all_stock_items.find(value => value.stock_item_id == last_stock_item);
			if (selected_stock_item)
			{
				this.selected_stock_item(selected_stock_item);
				this.stock_item_id(selected_stock_item.stock_item_id);
			}
			else if (all_stock_items.length > 0 && !this.selected_stock_item()) 
			{
				this.selected_stock_item(all_stock_items[0]);
				this.stock_item_id(all_stock_items[0].stock_item_id);
				localStorage.setItem('movement_summary_last_stock_item', all_stock_items[0].stock_item_id);
			}
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		} finally {
			await this.update();
		}
	}

	async update ()
	{
		try
		{
			this.months([]);

			// user have to select location first
			if (!this.location_id())
				return;
			
			if (!this.date_start() && !this.date_end())
			{
				let d = new Date();
				this.date_end(d.toISOString().substring(0,10));
				d.setMonth(d.getMonth()-6);
				d.setDate(1);
				this.date_start(d.toISOString().substring(0,10));
			}
			else if (!this.date_start())
			{
				let d = new Date(this.date_end());
				d.setMonth(d.getMonth()-6);
				this.date_start(d.toISOString().substring(0,10));
			}
			else if (!this.date_end())
			{
				let d = new Date(this.date_start());
				d.setMonth(d.getMonth()+6);
				this.date_end(d.toISOString().substring(0,10));
			}

			let ds = new Date(this.date_start());
			let de = new Date(this.date_end());

			if (ds > de)
				throw new Error("Start date must be before end date");
			
			//check if dates are within range
			let maxDate = new Date('2030-01-01');
			let minDate = new Date('2023-01-01');

			if (ds < minDate)
			{
				this.date_start('2023-01-01');
				ds= new Date(this.date_start())
				console.error('Cannot have a start date before 2023-01-01')
			}

			if (de > maxDate)
			{
				this.date_end('2029-12-31');
				de = new Date(this.date_end())
				console.error('Cannot have a end date after 2030-01-01')
			}

			ds.setDate(1);

			let months = [];
			while (ds <= de)
			{
				const m = ds.toISOString().substring(0,10);
				months.push(m);
				this.balances[m] = { start: null, end: null };
				ds.setMonth(ds.getMonth() + 1);
			}

			await Promise.all([
				this.update_movement_summaries(),
				this.update_balances()
			]);

			this.months(months);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', message: error, title: 'Error' })
			console.error(error)
		}
	}

	view_cell_click (cell, value, month, qty) 
	{
		if (qty.qty_out || qty.qty_in)
		{
			let lastDayOfMonth = moment(month).endOf('month');
			
			let from_loc, to_loc;
			if (qty.qty_out > 0)
				from_loc = this.selected_location();
			
			if (qty.qty_in > 0)
				to_loc = this.selected_location();

			let movement_obj = {
				start_date: month,
				end_date: lastDayOfMonth.format('YYYY-MM-DD'),
				from_locations: from_loc,
				to_locations: to_loc,
				stock_items: this.selected_stock_item(),
				movement_types: value.movement_type,
				movement_subtypes: cell.subtype
			}

			Grape.navigate(`/stock/movement/list`, { summary_filter: movement_obj });
		}
	}

	async update_movement_summaries ()
	{
		let filter = [
			{
				field: 'date_start', 
				operand: '>=', 
				value: this.date_start()
			},
			{
				field: 'date_start', 
				operand: '<=', 
				value: this.date_end()
			},
			{
				field: 'stock_item_id', 
				operand: '=', 
				value: this.stock_item_id()
			},
			{
				field: 'location_id', 
				operand: '=', 
				value: this.location_id()
			}
		];

		let qtys = this.qtys();
		let r = await Grape.fetches.getJSON('/api/record', 
		{
			schema: 'stock',
			table: 'v_movement_summary_monthly',
			filter: { AND: filter },
			limit: 10000
		});

		if (!r.records || r.records.length <= 0)
			return;

		for (let rec of r.records)
		{
			if (!qtys[rec.date_start])
				qtys[rec.date_start] = {};
			if (!qtys[rec.date_start][rec.movement_type_id])
				qtys[rec.date_start][rec.movement_type_id] = {};
			if (!qtys[rec.date_start][rec.movement_type_id][rec.movement_subtype_id])
				qtys[rec.date_start][rec.movement_type_id][rec.movement_subtype_id] = null;
			qtys[rec.date_start][rec.movement_type_id][rec.movement_subtype_id] = {qty_in: rec.qty_in, qty_out: rec.qty_out};
		}
		this.qtys(qtys);
	}

	async update_balances()
	{
		let filter = [
			{
				field: 'date_start', 
				operand: '>=', 
				value: this.date_start()
			},
			{
				field: 'date_start', 
				operand: '<=', 
				value: this.date_end()
			},
			{
				field: 'stock_item_id', 
				operand: '=', 
				value: this.stock_item_id()
			},
			{
				field: 'location_id', 
				operand: '=', 
				value: this.location_id()
			}
		];

		let r = await Grape.fetches.getJSON('/api/record', 
		{
			schema: 'stock',
			table: 'v_levels_monthly',
			fields: ['date_start', 'level_end', 'level_start', 'stock_item', 'date_end'],
			filter: { AND: filter },
			limit: 200
		});

		if (!r.records || r.records.length <= 0)
			return;

		for (let rec of r.records)
		{
			this.balances[rec.date_start] = {
				stock_item: rec.stock_item,
				start: rec.level_start,
				end: rec.level_end
			};
		}
	}

	location_click (location)
	{
		this.location_id(location.location_id);
		this.selected_location(location);

		localStorage.setItem('movement_summary_last_location', location.location_id);
	}

	stock_item_click (stock_item)
	{
		this.stock_item_id(stock_item.stock_item_id);
		this.selected_stock_item(stock_item);

		localStorage.setItem('movement_summary_last_stock_item', stock_item.stock_item_id);
	}

	download_summary_csv ()
	{
		let csvContent = "Month,Starting Balance";

		this.movement_types().forEach(movement_type => {
			movement_type.subtypes.forEach(subtype => {
				csvContent += `,${movement_type.movement_type} - ${subtype.subtype}`;
			});
		});

		csvContent += ",Ending Balance\n";

		for (let month of this.months()) 
		{
			let row = [];

			row.push(month);
			row.push(this.balances[month].start);

			for (let movement_type of this.movement_types())
				for (let subtype of movement_type.subtypes) 
				{
					let qty_in = this.qtys()[month][movement_type.movement_type_id][subtype.subtype_id].qty_in || null;
					let qty_out = this.qtys()[month][movement_type.movement_type_id][subtype.subtype_id].qty_out || null;

					if (qty_in && qty_out)
						row.push(qty_in - qty_out);
					else if (qty_out)
						row.push('-'+qty_out);
					else
						row.push(qty_in || null);
				}

			row.push(this.balances[month].end);
			csvContent += row.join(",") + "\n";
		}

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement("a");
		link.setAttribute("href", URL.createObjectURL(blob));
		link.setAttribute("download", 'MovementSummary' + ".csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

export default {
	name: 'ko-stock-movement-summary',
	viewModel: MovementSummaryComponentViewModel,
	module_type: 'ko',
	template: template
}