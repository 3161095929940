import template from './graphs_dashboard.html';

class GraphDashboardPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.locations = ko.observableArray();
		this.stock_level_default = ko.observable({location_table : 'location'});
		this.sales_order_default = ko.observable({location_table : 'source_location'});
		this.purchase_order_default = ko.observable({location_table : 'target_location'});
	}
}

class GraphDashboardPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new GraphDashboardPageViewModel(this);
		this.name = 'GraphDashboardPageClass';
	}

	async init ()
	{
		document.title = 'Dashboard';
		let locations = await Grape.cache.fetch('Locations');
		this.viewModel.locations(locations);
	}
}

export default {
	route: '[/]dashboard',
	page_class: GraphDashboardPageClass,
	template: template
}