import template from './movement_transaction_confirm.html';

/**
 * @kind dialog
 * @class MovementTransactionConfirmViewModel
 * @description This is the dialog to confirm a movement transaction
 */
class MovementTransactionConfirmViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit_transaction = ko.observable(false);

		this.movements = ko.observableArray();
		this.movement_note = ko.observable('');
		this.movement_type = ko.observable('');
		this.movement_subtype = ko.observable('');
		this.location = ko.observable('');
		this.date_effective = ko.observable('');
		this.movement_transaction_id = ko.observable(null);
		this.can_confirm = ko.observable(false);
		this.can_create = ko.observable(false);
		this.removed_items = ko.observableArray([]);
		this.invalid_movements = ko.observableArray([]);
	}

	async check_valid_movements ()
	{
		if ((!this.movements().length > 0 && !this.removed_items().length > 0) && this.invalid_movements().length > 0)
		{
			let response = await Grape.alerts.confirm({ type: 'warning', title: 'Warning', message: 'This will not result in any movements, as there are no valid movements! Do you want to go back to edit it?' });

			if (response)
				this.dialog.close();
			else
				return false;
		}
		else
			return true;
	}

	async save_transaction_click ()
	{
		let res = await this.check_valid_movements();
		if (!res) return

		this.dialog.close({ action: 'Save', return_object: this.get_return_obj() });
	}

	async update_transaction_click ()
	{
		let res = await this.check_valid_movements();
		if (!res) return

		this.dialog.close({ action: 'Update', return_object: this.get_return_obj() });
	}
	
	btn_back_click()
	{
		this.dialog.close({});
	}

	get_return_obj ()
	{
		let return_obj =  {
				date_effective: this.date_effective(),
				location: this.location().name,
				movement_type_id: this.movement_type().movement_type_id,
				movement_subtype_id: this.movement_subtype().subtype_id,
				note: this.movement_note(),
				items: this.movements(),
				movement_transaction_id: this.movement_transaction_id(),
				note: this.movement_note()
			}

		if (this.removed_items().length > 0)
			return_obj.items_remove = this.removed_items();

		return return_obj;
	}

	async commit_transaction_click ()
	{
		let res = await this.check_valid_movements();
		if (!res) return

		this.dialog.close({ action: 'Commit', return_object: this.get_return_obj() });
	}
}

class MovementTransactionConfirmDialog
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new MovementTransactionConfirmViewModel(this);

		this.viewModel.movements(bindings.movements);
		this.viewModel.location(bindings.location);
		this.viewModel.movement_type(bindings.movement_type);
		this.viewModel.date_effective(bindings.date_effective);
		this.viewModel.movement_subtype(bindings.movement_subtype);
		this.viewModel.movement_note(bindings.movement_note);
		this.viewModel.movement_transaction_id(bindings.movement_transaction_id || null);
		this.viewModel.removed_items(bindings.removed_items || []);
		this.viewModel.invalid_movements(bindings.invalid_movements || []);
		this.viewModel.edit_transaction(bindings.edit_transaction || false)

		this.update_confirm();
	}

	async update_confirm() 
	{
		let confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmStockMovement');
		let allow = false;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || confirmable_locations.find(loc => loc.location_name == this.viewModel.location().name))
			allow = true;

		this.viewModel.can_confirm(allow);
		confirmable_locations = await window.Grape.StockUtils.get_user_locations('CreateStockMovement');
		allow = false;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || confirmable_locations.find(loc => loc.location_name == this.viewModel.location().name))
			allow = true;

		this.viewModel.can_create(allow);
	}
}

export default {
	name: 'MovementTransactionConfirm',
	dialog_class: MovementTransactionConfirmDialog,
	template: template
}