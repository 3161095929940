import template from './DeleteWorkstation.html';

class DeleteWorkstationViewModel 
{
	constructor (dialog) 
	{ 
		this.dialog = dialog;
		this.workstation_options = ko.observableArray([]);
		this.workstation_id = ko.observable('');
		this.current_workstation = ko.observable('');
		this.replacement_workstation_id = ko.observable('');
		this.replacement_workstation_name = ko.observable('');
	}
}

class DeleteWorkstationDialog
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new DeleteWorkstationViewModel(this);
	}
	
	init ()
	{
		this.viewModel.workstation_id(this.bindings.workstation_id);
		this.viewModel.workstation_options(this.bindings.workstation_options.filter(workstation => workstation.workstation_id != this.viewModel.workstation_id()));
		this.viewModel.current_workstation(this.bindings.workstation_options.find(workstation => workstation.workstation_id === this.viewModel.workstation_id()));
	}

	btn_cancel_click ()
	{
		this.close();
	}

	btn_confirm_click ()
	{
		this.close(this.viewModel.replacement_workstation_id());
	}
}

export default {
	name: 'DeleteWorkstation',
	dialog_class: DeleteWorkstationDialog,
	template: template
}