import template from './edit_billing_details.html';

class EditBillingViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.billing_details = ko.observable(dialog.bindings.data);

		this.title = ko.observable('Edit Billing Information');
		this.vat_number = ko.observable(this.billing_details().vat_number || null);
		this.contact_person = ko.observable(this.billing_details().contact_person || null);
		this.contact_number = ko.observable(this.billing_details().contact_number || null);
		this.email = ko.observable(this.billing_details().email || null);
		this.address = ko.observable(this.billing_details().address || []);
		this.date_effective = ko.observable(this.billing_details().date_effective || null);
		this.location_billing_detail_id = ko.observable(this.billing_details().location_billing_detail_id || null);
	}	
}

class EditBillingPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditBillingViewModel(this);
		this.location_id = bindings.location_id;
		this.viewModel.billing_details(bindings.data)
		
		this.init();
	}

	init ()
	{
		let address_arr = this.viewModel.address();
		this.viewModel.address(address_arr.join("\n"));
	}

	async save_billing ()
	{
		let address_arr = this.viewModel.address().trim();
		address_arr = address_arr.split("\n");

		let loc = {
			billing_detail: {
				vat_number: this.viewModel.vat_number(),
				contact_person: this.viewModel.contact_person(),
				email: this.viewModel.email(),
				contact_number: this.viewModel.contact_number(),
				address: address_arr,
				date_effective: this.viewModel.date_effective(),
				location_billing_detail_id: this.viewModel.location_billing_detail_id()
			},
			location_id: this.location_id,
		};

		try
		{
			let response = await fetch('api/stock-management/location', {
				method: 'POST',
				headers: {'content-type': 'application/json' },
				body: JSON.stringify(loc)
			});

			let data = await response.json();

			if (response.ok)
				this.close();
			else
				throw new Error(data.message || response.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		} finally {
			this.close()
		}
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

export default {
	name: 'EditBilling',
	dialog_class: EditBillingPage,
	template: template
}
