import template from './create_batched_sales_order.html';

class CreateOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(true);
		this.orders_loading = ko.observable(true);
		this.type = ko.observable();
		this.order = ko.observableArray([]);
		this.order_pricing = ko.observableArray([]);
		this.order_fields = ko.observableArray([]);
		this.order_audit_log = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.stock_items = ko.observableArray([]);
		this.stock_order_items = ko.observableArray([]);
		this.reference_numbers = ko.observableArray([]);
		this.new_reference_number = ko.observable();

		this.order_types = ko.observableArray([]);
		this.order_statuses = ko.observableArray([]);
		this.acceptStatus = ko.observable();
		this.rejectStatus = ko.observable();

		this.orders = ko.observableArray([]);
		// sort observables
		this.sortField = ko.observable('order_nr');
		this.sortOrder = ko.observable('ASC');

		// order pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);

		this.is_editing = ko.observable(Boolean(this.page.bindings.type == 'edit'));
		this.is_viewing = ko.observable(Boolean(this.page.bindings.type == 'view'));
		this.is_creating = ko.observable(Boolean(this.page.bindings.type == 'create'));

		this.inputs_disabled = ko.computed(() => {
			return this.is_viewing();
		});

		this.order_id = ko.observable();
		this.parent_id = ko.observable();
		this.selectedType = ko.observable();
		this.selectedStatus = ko.observable();
		this.selectedSource = ko.observable();
		this.selectedDestination = ko.observable();
		this.delivery_date = ko.observable();
		this.order_date = ko.observable();
		this.order_number = ko.observable();
		this.order_note = ko.observable();
		this.order_documents = ko.observableArray([]);
		this.edited_tax = ko.observableArray([]);

		this.filteredSourceLocations = ko.observableArray([]);
		this.filteredDestinationLocations = ko.observableArray([]);

		this.selectedType.subscribe((type) => {
			if (type) 
			{
				this.filteredSourceLocations(this.locations().filter(location => location.location_type === type.source_location_type));
				this.filteredDestinationLocations(this.locations().filter(location => location.location_type === type.target_location_type));
			}

			this.selectedStatus(null);
			let initialStatus;
			
			if (!this.order_id() && !this.selectedStatus())
				initialStatus = this.selectedType().statuses.find((x) => x.initial);
		
			let filtered_statuses;
			
			if (initialStatus)
				filtered_statuses = [initialStatus];
			else 
			{
				let selectedStatus = this.order().order_status;
				let current_status = this.selectedType().statuses.find(status => status.status === selectedStatus);
				filtered_statuses = this.selectedType().statuses.filter((x) => {
					return x.status === selectedStatus || (current_status && current_status.allowed_status.includes(x.status));
				});
			}
			
			this.order_statuses(filtered_statuses);
			if (initialStatus) 
				this.selectedStatus(initialStatus);
		});
		
		this.selectedSource.subscribe(() => {
			this.page.order_item_pricing();
		});

		// To disable order item edit if order has been confirmed
		this.is_order_confirmed = ko.computed(() => {
			let audit_log = this.order_audit_log();
			let is_confirmed = audit_log.some(log => log.log === 'Confirmed order');
			let is_completed = audit_log.some(log => log.log === 'Completed order');
			if (is_confirmed || is_completed)
				this.type('view');
		});
	}

	// Event handlers
	btn_toggle_edit_click (row) 
	{
		if (row.isEditing()) 
		{
			let taxes = {};
			
			for (let i = 0; i < row.taxes.length; i++)
				row.taxes[i].type = 'tax_perc';
			taxes = row.taxes;

			this.edited_tax(taxes);
			this.save_order(false);
		}

		row.isEditing(!row.isEditing());
		let iconElement = event.target;
		iconElement.classList.toggle('fa-edit');
		iconElement.classList.toggle('fa-check');

		if (iconElement.classList.contains('fa-check'))
			iconElement.style.color = '#21CA59';
		else 
			iconElement.style.color = '';
	}

	async btn_reject_status_click (status) 
	{
		let result = await Grape.alerts.confirm(
		{
			message: 'Are you sure you want to set the order status to cancelled? This cannot be undone.',
			title: 'Cancel Order', 
			type: 'warning'
		});

		if (result) 
		{
			let selectedStatus = status;
			let save_status = this.selectedType().statuses.find(status => status.status === selectedStatus);
			this.selectedStatus(save_status);
			this.save_order(false);
		}
	}

	btn_accept_status_click (status) 
	{
		let selectedStatus = status;
		let save_status = this.selectedType().statuses.find(status => status.status === selectedStatus);
		this.selectedStatus(save_status);
		this.save_order(false);
	}

	btn_edit_field_click (item) 
	{
		item.editing(true);
	}

	btn_back_click () 
	{
		Grape.navigate('/stock/order/all_orders/');
	}

	btn_save_field_click (item) 
	{
		item.editing(false);
		this.save_order(false);
	}

	btn_save_click () 
	{
		this.save_order(false);
	}

	btn_create_click () 
	{
		this.save_order(true);
	}

	btn_download_document_click (item) 
	{
		window.open(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
	}

	add_reference_number (value, event)
	{
		if (event.keyCode !== 13 && (event.type !== 'blur'))
			return true;

		if (this.new_reference_number())
		{
			if (!this.reference_numbers())
				this.reference_numbers([]);
			this.reference_numbers.push(this.new_reference_number());
			this.new_reference_number("");
		}

		return true;
	}

	remove_reference_number (value)
	{
		this.reference_numbers.splice(this.reference_numbers.indexOf(value), 1);
	}

	async save_order (navigate_after = true) 
	{
		if (!this.selectedType() || 
			!this.selectedStatus() ||
			!this.selectedSource())
		{
			Grape.alerts.alert({
				title: 'Error', 
				type: 'error', 
				message: 'Please fill in all the fields'
			});

			return;
		}

		let order = {
			'delivery_date': this.delivery_date(),
			'reference_numbers': this.reference_numbers() || [],
			'order_date': this.order_date(),
			'type': this.selectedType().type,
			'status': this.selectedStatus().status,
			'source_location': this.selectedSource().name,
			'target_location': this.selectedDestination() ? this.selectedDestination().name : null,
			'order_nr': this.order_number(),
			'note': this.order_note(),
			'taxes': this.edited_tax(),
			'items': this.stock_order_items().map(item => {
				let stock_item_id = this.find_stock_item_id(item.description());
				let total_qty = item.expected_receive_dates().reduce((sum, delivery) => sum + parseInt(delivery.qty(), 10), 0);
				return {
					'stock_item_id': stock_item_id,
					'qty': total_qty,
					'ppu': item.ppu(),
					'expected_receive_dates': item.expected_receive_dates().map(delivery => {
						return {
							'date': delivery.date(),
							'qty': delivery.qty()
						};
					})
				};
			})
		};

		if (this.is_editing() || this.is_viewing())
			order.order_id = this.order_id();

		let fields = {};

		for (let item of this.order_fields())
			fields[item.fieldName] = item.data;
		order.fields = fields;

		try {
			let result = await Grape.fetches.postJSON('/api/stock-management/order', order);

			if (result.status == 'OK')
			{
				if (navigate_after)
					Grape.navigate('/stock/order/all_orders/');
				else 
					document.location.reload();
			}
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	find_stock_item_id (description) 
	{
		let foundItem = this.stock_items().find(item => item.description === description);

		return foundItem ? foundItem.stock_item_id : null;
	}
	
	current_status_document (item) 
	{
		if (this.selectedStatus() && this.selectedStatus().document_name)
			return this.selectedStatus().document_name === item.name;

		return false;
	}

	page_click (page_number)	
	{
		this.current_page_number(page_number);
		this.page.update_orders();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.update_orders();
	}
}

class CreateOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.order_id = ko.observable(bindings.order_id);
		this.stock_items = bindings.stock_items || [];
		this.viewModel = new CreateOrderViewModel(this);
		this.viewModel.type(this.bindings.type);
		this.confirmable_locations = [];
		this.creatable_locations = [];
		this.is_type_from_list = ko.observable(false);
	}

	async init () 
	{
		document.title = 'Create Order';
		this.viewModel.order_date(moment().format('YYYY-MM-DD'));
		this.viewModel.delivery_date(moment().add(7, 'days').format('YYYY-MM-DD'));

		let locationCacheName;

		if (this.viewModel.is_creating())
			locationCacheName = 'ActiveLocations';
		else 
			locationCacheName = 'Locations';
	
		try 
		{
			let [locations, type, stock, audit] = await Promise.all([
				Grape.cache.fetch(locationCacheName),
				Grape.cache.fetch('OrderTypes'),
				Grape.fetches.getJSON('/api/record', { 
					table: 'v_stock_item', 
					schema: 'stock', 
					limit: 10000, 
					fields: ['stock_item_id', 'description', 'attributes', 'in_use'],
					filter: []
				}),
				this.viewModel.is_editing() || this.viewModel.is_viewing() ? 
					Grape.fetches.getJSON('/api/record', {
						table: 'v_order_audit_log',
						schema: 'stock',
						filter: [{
							field: 'order_id',
							operand: '=' ,
							value: this.order_id()
						}],
						sortorder: 'DESC',
						sortfield: 'date_inserted'
					}) : Promise.resolve({ records: [] })
			]);

			this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');
			let filtered_locations = [];
			locations.forEach((loc) => {
				if (
					Grape.currentSession.roles.includes('stock.all-location-permissions')
					|| (this.creatable_locations.find(cloc => cloc.location_id == loc.location_id))
					|| this.viewModel.is_viewing()
				)
					filtered_locations.push(loc);
			});
			this.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmOrder');
	
			this.viewModel.locations(filtered_locations);
			this.viewModel.order_types(Object.values(type));

			if (this.bindings.order_type) 
			{
				let matching_type = this.viewModel.order_types().find(order_type => order_type.type === this.bindings.order_type);
				if (matching_type) 
				{
					this.is_type_from_list(true);
					this.viewModel.selectedType(matching_type);
				}
			}

			this.viewModel.stock_items(stock.records);
	
			audit.records.forEach(record => {
				record.date_inserted = moment(record.date_inserted).format('YYYY-MM-DD, HH:mm');
			});
			this.viewModel.order_audit_log(audit.records);

			if (this.bindings.type == 'edit' || this.bindings.type == 'view')
				await this.populate_fields(this.bindings.order_id);
			else
				this.viewModel.selectedSource(this.populate_option_field(this.viewModel.locations(), this.bindings.supplier_name) || '')
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error fetching data:', error);
		}
	}
	
	async update_orders()
	{
		this.viewModel.orders_loading(true);

		let options = {
				table: 'v_sales_orders',
				schema: 'stock',
				sortorder: this.viewModel.sortOrder(),
				sortfield: this.viewModel.sortField(),
				limit: this.viewModel.current_page_size(),
				offset: (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size(),
				filter: [{
					field: 'parent_order_id',
					operand: '=',
					value: this.viewModel.order_id()
				}]
		};

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{

				this.viewModel.page_count(Math.ceil(result.total/result.limit));
				this.viewModel.orders(result.records);
			}
			else
				throw new Error(result.message || result.code);
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		this.viewModel.orders_loading(false);
	}

	async populate_fields (order_id) 
	{
		try 
		{
			let orderData = await Grape.fetches.getJSON(`/api/stock-management/order`, {
				order_id: order_id
			});
			this.viewModel.order_id(order_id);

			if (orderData.status === 'OK') 
			{
				let order = orderData.order;
				this.viewModel.order(orderData.order);
				this.viewModel.selectedType(this.populate_option_field(this.viewModel.order_types(), order.order_type));
				this.viewModel.selectedStatus(this.populate_option_field(this.viewModel.order_statuses(), order.order_status));

				if (this.viewModel.selectedStatus().final === true) 
					if (this.viewModel.is_editing())
						Grape.navigate(`/batched-sales/order/view/${this.order_id()}`);

				this.viewModel.acceptStatus(this.viewModel.selectedStatus().accept_status);
				this.viewModel.rejectStatus(this.viewModel.selectedStatus().reject_status);
				this.viewModel.selectedSource(this.populate_option_field(this.viewModel.locations(), order.source_location));
				this.viewModel.order_date(order.order_date);
				this.viewModel.delivery_date(order.delivery_date);
				this.viewModel.reference_numbers(order.reference_numbers);
				this.viewModel.order_number(order.order_nr);
				this.viewModel.order_note(order.note);

				if ((order.target_location != null) || (order.target_location != undefined))
					this.viewModel.selectedDestination(this.populate_option_field(this.viewModel.locations(), order.target_location));

				if (order.items != undefined)
				{
					order.items.forEach(item => {
						this.viewModel.stock_order_items.push({
							order_item_id: item.order_item_id,
							description: ko.observable(item.description),
							ppu: ko.observable(item.ppu),
							qty: ko.observable(item.qty),
							qty_received: ko.observable(item.qty_received),
							delivery_date: ko.observable(item.delivery_date),
							expected_receive_dates: ko.observableArray(item.expected_receive_dates.map(date => ({
								date: ko.observable(date.date),
								qty: ko.observable(parseInt(date.qty))
							})))
						});
					});
				}

				if (order.documents != undefined) 
				{
					order.documents.forEach(document => {
						this.viewModel.order_documents.push({
							order_document_id: document.order_document_id,
							path_basename: document.path_basename,
							name: document.name,
							user_id: document.username,
							date_inserted: new Date(document.date_inserted).toISOString().split('T')[0]
						});
					});
				}

				if (order.pricing) 
				{
					this.viewModel.order_pricing.push({
						total_value: order.pricing.total_value,
						total_after_order_taxes: order.pricing.total_after_order_taxes,
						taxes: order.pricing.taxes.map(tax => ({name: tax.name, percent: tax.percent})),
						price_adjustments: order.pricing.price_adjustments,
						isEditing: ko.observable(false)
					});
				}

				if (order.fields != undefined) 
				{
					Object.entries(order.fields).forEach(([key, value]) => {
						this.viewModel.order_fields.push({
							fieldName: key,
							data: value,
							editing: ko.observable(false)
						});
					});
				}
				document.title = this.viewModel.order_number();
				this.viewModel.loading(false);
			}
			else
				throw new Error(orderData.message || orderData.code);

			this.update_orders();
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
		}
	}

	populate_option_field (optionsArray, optionValue)
	{
		return optionsArray.find(option => 
			option.type === optionValue || 
			option.status === optionValue || 
			option.name === optionValue
		);
	}

	// Get current_price for item at selected source location
	async order_item_pricing () 
	{
		if (this.viewModel.selectedSource())
		{
			let pricing = await Grape.fetches.getJSON('/api/record', {
				table: 'v_location_stock_item_info',
				schema: 'stock',
				fields: [ 'location_id', 'stock_item_id', 'current_price' ],
				filter: [{
					field: 'location_id',
					value: this.viewModel.selectedSource().location_id,
					operand: '='
				}]
			});

			let map = {};
			pricing.records.forEach(item => {
				map[item.stock_item_id] = item.current_price;
			});
		
			this.viewModel.stock_items().forEach(item => {
				let price = map[item.stock_item_id];
				if (price !== undefined)
					item.current_price = price;
			});
		}
	}

	can_edit_tax () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			let selected_source = this.viewModel.selectedSource();
			if (selected_source)
			{
				if (!this.creatable_locations.find(cloc => cloc.location_id == selected_source.location_id))
					allowed = false;
			}

			if (allowed)
			{
				let selected_destination = this.viewModel.selectedDestination();
				if (selected_destination)
					if (!this.creatable_locations.find(cloc => cloc.location_id == selected_destination.location_id))
						allowed = false;
			}
		}
		return allowed;
	}

	can_progress_order () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			let selected_source = this.viewModel.selectedSource();
			if (selected_source)
				if (!this.confirmable_locations.find(cloc => cloc.location_id == selected_source.location_id))
					allowed = false;

			if (allowed)
			{
				let selected_destination = this.viewModel.selectedDestination();

				if (selected_destination)
					if (!this.confirmable_locations.find(cloc => cloc.location_id == selected_destination.location_id))
						allowed = false;
			}
		}
		return allowed;
	}
}

export default {
	route: '/batched-sales/order/:type/:order_id',
	page_class: CreateOrderPage,
	template: template
}
