import template from './bulk_add.html';

class BulkAddStockItemsViewModel
{
	constructor (page)
	{
		this.page = page;
		this.stock_items = ko.observableArray([]);
		this.stock_item_id = ko.observable();
		this.idx = ko.observable();
		this.stock_code = ko.observable();
		this.description = ko.observable();
		this.stock_group_id = ko.observable();
		this.stock_group = ko.observableArray([]);
		this.stock_tag = ko.observableArray([{name: undefined}]);
		this.other_names = ko.observableArray([{name: undefined}]);
	}

	btn_close_page_click ()
	{
		Grape.navigate('/stock_item/list/');
	}

	btn_remove_item_click (item)
	{
		this.stock_items.remove(item);
	}

	parse_csv (text)
	{
		//Splitting the input text into lines by newline characters.
		let lines = text.split(/\r\n|\r|\n/g);

		//Parsing each line into an array of columns.
		let parsed_data = lines.map(line => {
			let is_inside_quote = false;
			let column_value = '';
			let columns = [];

			//Iterate over each character in the line.
			for (let character of line)
			{
				//Toggle is_inside_quote if we encounter a quote character.
				if (character === '"')
					is_inside_quote = !is_inside_quote;

				//If we encounter a comma outside of quotes, it indicates the end of a column value.
				else if (character === ',' && !is_inside_quote)
				{
					columns.push(column_value);
					column_value = '';
				} 
				//If character is not a comma or a quote, it's part of the column value.
				else
					column_value += character;
			}
			columns.push(column_value);
			return columns;
		});
		return parsed_data;
	}

	get_stock_group_id (name)
	{
		let stock_group = this.stock_group().find(group => group.description === name);
		return stock_group ? stock_group.stock_group_id : null;
	}

	upload_csv (event)
	{
		let file = event.target.files[0];
		let reader = new FileReader();
		reader.onload = () => {
			let lines = this.parse_csv(reader.result);
			for (let i=1; i < lines.length; i++)
			{
				let data = lines[i];
				if (data.length > 1 && data[0] !== '')
				{
					//Convert and validate data
					let idx = parseInt(data[0]);
					let stock_group_id = this.get_stock_group_id(data[3]);

					let errors = [];

					if (!Number.isInteger(idx) || idx < 1 || idx > 999)
					{
						errors.push(`Invalid index value in row ${i + 1}`);
						this.stock_items([]);
					}

					if (stock_group_id === null)
					{
						errors.push(`Invalid stock group value in row ${i + 1}`);
						this.stock_items([]);
					}

					if (errors.length > 0)
					{
						Grape.alerts.alert({
							title: 'Error',
							type: 'error',
							message: errors.join('\n'),
						});
						return;
					}

					let item = {
						idx: idx,
						stock_code: data[1],
						description: data[2],
						stock_group_id: stock_group_id,
						stock_tag: data[4].split(', '),
						other_names: data[5].split(', ')
					};
					this.stock_items.push(item);
				}
			}
			Grape.alerts.alert({title: 'Success', type: 'success', message: 'Upload complete!'});
		}
		reader.readAsText(file);
	}
}

class BulkAddStockItems
{
	constructor (bindings)
	{
		document.title = 'Upload Stock Items';
		this.viewModel = new BulkAddStockItemsViewModel(this);
		this.bindings = bindings;
	}

	async init ()
	{
		try {
			let groups_result = await Grape.fetches.getJSON('/api/record/', {table: 'stock_group', schema: 'stock'})

			if (groups_result.status == 'OK')
				this.viewModel.stock_group(groups_result.records);

		} catch (error) {
			Grape.alerts.alert({title: 'Error', type: 'error', message: 'Product not found'});
			console.error(error);
		}
	}

	validate_inputs ()
	{
		for (let i = 0; i < this.viewModel.stock_items().length; i++)
		{
			let item = this.viewModel.stock_items()[i];
			if (!item.idx || !item.stock_code || !item.description || !item.stock_group_id) 
			{
				Grape.alerts.alert({
					title: 'Error',
					type: 'error',
					message: `Please fill in all columns for the item in row ${i + 1} before saving.`
				});
				return false;
			}
		}
		return true;
	}

	async btn_save_product_click () 
	{
		if (this.validate_inputs())
		{
			for (let i = 0; i < this.viewModel.stock_items().length; i++)
			{
				let item = this.viewModel.stock_items()[i];

				let obj = {
					in_use: true, 
					idx: item.idx, 
					stock_code: item.stock_code, 
					description: item.description, 
					stock_item_id: item.stock_item_id, 
					stock_group_id: item.stock_group_id,
					other_names: item.other_names,
					stock_tag: item.stock_tag
				};

				let url = '/api/stock-management/stock-item';

				try
				{
					await Grape.fetches.postJSON(url, obj); 
				}
				catch (error) {
					console.error(error);
				}
			}
			Grape.alerts.alert({
				type: 'success', 
				message: 'Products Saved', 
				title: 'Product Saved', 
			});
			this.viewModel.btn_close_page_click(); 
		}
	}
}

export default {
	route: '[/]stock_item/bulk_add/',
	page_class: BulkAddStockItems,
	template: template
}
